import React from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Build as ServicesIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../components/data-table";
import ConfirmModalContent from "../../../components/confirm-modal-content";
import FormModalContent from "./form";
import SubService from "../sub-service";
import serviceService from "../../../services/service.service";

const headerCells = [
  {
    field: "title",
    title: "عنوان",
    mainField: true,
  },
];

const defaultKeys = [
  {
    title: "عنوان",
    field: "title",
    active: true,
  },
];

export default function ServicesPage() {
  const visibilityIcon = (item) => {
    return item.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />;
  };
  const toggleHide = async (item, refresh) => {
    const { error, data } = await serviceService.toggleHide(item.id);
    if (error) return;
    item.hidden = data;
    refresh();
  };
  const deleteItem = (item) => {
    return serviceService.deleteService(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        backend={serviceService.getServicesUrl}
        headCells={headerCells}
        rowActions={[
          {
            icon: <ServicesIcon />,
            modalProps: { fullScreen: true },
            refreshOnClose: true,
            modalContent: (props) => <SubService {...props.data} />,
          },
          {
            icon: <EditIcon />,
            modalProps: { maxWidth: "sm" },
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            icon: <DeleteIcon />,
            modalProps: { maxWidth: "sm" },
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.title}
                close={props.close}
              />
            ),
          },
          {
            icon: visibilityIcon,
            onClick: toggleHide,
          },
        ]}
        actions={[
          {
            icon: <AddIcon />,
            tooltip: "ایجاد خدمت",
            modalContent: (props) => <FormModalContent {...props} />,
            modalProps: { maxWidth: "sm" },
          },
        ]}
        searchKeys={defaultKeys}
        title="خدمات"
      />
    </div>
  );
}
