import React, { useState } from "react";
import DialogTitle from "../../../components/dialog-title";
import Loading from "../../../components/loading";
import moment from "moment-jalaali";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { Status, StatusMap } from "../../../constants/enums";
import requestService from "../../../services/request.service";
import SelectBranchAppointment from "../../../components/select-branch-appointment";
import { Link, useHistory } from "react-router-dom";
import { useErrorHandler } from "../../../hooks";
import CoordinatorSection from "../../_shared/request-info/coordinator";
import CustomerMessageSection from "../../_shared/request-info/customer-message";
import BranchDescriptionSection from "../../_shared/request-info/branch-description";
import BranchDoneListSection from "../../_shared/request-info/branch-done-list";

function GridItemText({ value, label, valueClassName }) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography>
        {label}: <span className={valueClassName}> {value} </span>
      </Typography>
    </Grid>
  );
}
export default function FormModalContent({ close, data, extra }) {
  const [waiting, setWaiting] = useState();
  const history = useHistory();
  const handleError = useErrorHandler();

  const submitTitle = React.useMemo(() => {
    switch (data.status) {
      case Status.SetAppointment:
        return "انتخاب شعبه و زمان";
      case Status.Payment:
        return "پرداخت";
      default:
        return "تایید";
    }
  }, [data.status]);
  const submit = async () => {
    if (data.status === Status.Payment) {
      setWaiting(true);
      const { error, data: resData } = await requestService.payRequest(data.id);
      if (error) {
        setWaiting(false);
        handleError(error, [
          { case: "RequestNotFound", message: "درخواست مورد نظر یافت نشد." },
          {
            case: "RequestNotPayable",
            message:
              "درخواست قابل پرداخت نیست. ممکن است پیش از این پرداخت کرده باشید.",
          },
        ]);
        return;
      }
      if (typeof resData === "string") {
        window.location.href = resData;
      } else {
        history.push("/panel/requests");
      }

      return;
    }
    close();
  };
  return (
    <Loading isLoading={waiting} overlay>
      <DialogTitle onClose={close}>درخواست</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <GridItemText label="کد" value={data.code} valueClassName="en" />
          <GridItemText
            label="تاریخ"
            value={moment(data.date).format("jYYYY/jMM/jDD HH:mm")}
          />
          <GridItemText label="خودرو" value={extra.cars[data.car]} />
          <GridItemText label="وضعیت" value={StatusMap[data.status]} />
          <GridItemText label="خدمت" value={extra.services[data.service]} />
          {data.subServices?.length ? (
            <GridItemText
              label="زیر خدمت ها"
              value={data.subServices
                .map((s) => extra.subServices[s])
                .join(", ")}
            />
          ) : null}

          <CustomerMessageSection customer={data.customer} title="پیام شما" />
          <CoordinatorSection
            title="پیام ارمغان"
            coordinator={data.coordinator}
          />
          <BranchDescriptionSection branch={data.branch} />
          <BranchDoneListSection branch={data.branch} />
        </Grid>
      </DialogContent>
      <DialogActions>
        {data.invoice.transaction ? (
          <Button
            component={Link}
            to={"/invoice/request/" + data.code}
            target="_blank"
          >
            مشاهده فاکتور پیش پرداخت
          </Button>
        ) : null}
        {data.status === Status.SetAppointment ? (
          <SelectBranchAppointment
            dialog
            buttonProps={{ variant: "contained" }}
            serviceId={data.service}
            requestId={data.id}
          />
        ) : (
          <Button variant="contained" color="primary" onClick={submit}>
            {submitTitle}
          </Button>
        )}
      </DialogActions>
    </Loading>
  );
}
