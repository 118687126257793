import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  CircularProgress,
  createStyles,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import httpService from "../../services/http.service";
import CaptchaInput from "../captcha-input";

const useStyles = makeStyles((theme) =>
  createStyles({
    imageWrapper: {
      width: 150,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& svg": {
        filter: "brightness(0) invert(1)",
      },
    },
  })
);
const Captcha = forwardRef(
  (
    {
      error,
      helperText,
      className,
      onChange,
      value = { text: "", token: "" },
      ...others
    },
    ref
  ) => {
    const [svg, setSvg] = useState();
    const [loading, setLoading] = useState();
    const [_value, _setValue] = useState(value);
    const classes = useStyles();
    useImperativeHandle(ref, () => ({ refresh }));

    useEffect(() => {
      refresh();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refresh = async () => {
      setLoading(true);
      const { error, data } = await httpService.get("/captcha");
      setLoading(false);
      if (error) {
        //   TODO: handle error
        return;
      }
      const newValue = { ..._value, token: data.token };
      _setValue(newValue);
      if (onChange) onChange(newValue);
      setSvg(data.svg);
    };
    const textChange = (e) => {
      const newValue = { ..._value, text: e?.target?.value || "" };
      _setValue(newValue);
      if (onChange) onChange(newValue);
    };

    return (
      <div className={className}>
        <FormControl variant="outlined" fullWidth error={error}>
          <InputLabel>کپچا</InputLabel>
          <OutlinedInput
            value={value.text || ""}
            onChange={textChange}
            inputComponent={CaptchaInput}
            {...others}
            endAdornment={
              <InputAdornment position="end">
                {loading ? (
                  <div className={classes.imageWrapper}>
                    <CircularProgress />
                  </div>
                ) : svg ? (
                  <div
                    className={classes.imageWrapper}
                    dangerouslySetInnerHTML={{ __html: svg }}
                  />
                ) : (
                  <div className={classes.imageWrapper}>دوباره تلاش کنید</div>
                )}
                <IconButton
                  //   size="small"
                  onClick={refresh}
                >
                  <RefreshIcon />
                </IconButton>
              </InputAdornment>
            }
            labelWidth={30}
          />
        </FormControl>
        {error && <FormHelperText error>{helperText}</FormHelperText>}
      </div>
    );
  }
);

export default Captcha;
