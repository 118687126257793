import { useState, useCallback, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";

export function useForceUpdate() {
  const [, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}
/**
 * @callback useDebouncedEffectCallback
 */

/**
 * @param {useDebouncedEffectCallback} effect
 * @param {number} delay
 * @param {string[]} deps
 */
export const useDebouncedEffect = (effect, delay, deps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};
export const useErrorHandler = () => {
  const { enqueueSnackbar } = useSnackbar();
  return (
    error,
    cases = [],
    def = { message: "اتفاقی افتاده، لطفا بعدا تلاش کنید." }
  ) => {
    let message = def.message;
    let variant = def.variant || "error";
    let callback;
    for (const {
      case: _c,
      message: _m,
      variant: _v = "error",
      callback: _cb,
    } of cases) {
      if (_c === error) {
        message = _m;
        variant = _v;
        callback = _cb;
        break;
      }
    }
    enqueueSnackbar(message, { variant });
    if (callback) callback();
  };
};

/**
 * @param {useDidUpdateEffectCallback} effect
 * @param {string[]} deps
 */
export const useDidUpdateEffect = (effect, deps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) callback();
    else didMountRef.current = true;
  }, [callback]);
};
