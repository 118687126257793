import React from "react";
import { Container, Grid, Card, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LOGO from "../../assets/images/armaghan-logo.png";
import BACKGROUND from "../../assets/images/login-background.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${BACKGROUND})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  cardContainer: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
  },
  card: {
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    margin: `${theme.spacing(3)}px 0`,
    width: "100%",
    backgroundColor: "#0006",
    backdropFilter: "blur(12px)",
  },
  logoContainer: {
    textAlign: "center",
    marginBottom: theme.spacing(5),
    "& img": {
      width: 280,
      maxWidth: "100%",
    },
  },
  alert: {
    marginTop: -theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
}));
export default function AuthContainer({ children, error }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Container>
        <Grid container justify="center">
          <Grid
            className={classes.cardContainer}
            item
            xs={12}
            sm={8}
            md={6}
            lg={4}
          >
            <Card className={classes.card}>
              <div className={classes.logoContainer}>
                <img src={LOGO} alt="logo" />
              </div>
              {!!error && (
                <Alert
                  className={classes.alert}
                  variant="outlined"
                  severity="error"
                >
                  {error}
                </Alert>
              )}
              {children}
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
