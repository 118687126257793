import React from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../components/data-table";
import FormModalContent from "./form";
import blogService from "../../../services/blog.service";
import ConfirmModalContent from "../../../components/confirm-modal-content";

const headerCells = [
  {
    field: "title",
    title: "عنوان",
    mainField: true,
  },
  {
    field: "category",
    title: "دسته بندی",
  },
  {
    field: "date",
    title: "تاریخ ایجاد",
    date: true,
  },
  {
    field: "tags",
    title: "تگ ها",
  },
  {
    field: "author",
    title: "نویسنده",
  },
];
const defaultKeys = [
  {
    field: "title",
    title: "عنوان",
    active: true,
  },
  {
    field: "category",
    title: "دسته بندی",
    active: true,
  },
  {
    field: "author",
    title: "نویسنده",
    active: true,
  },
];

export default function BlogsPage() {
  const visibilityIcon = (item) => {
    return item.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />;
  };
  const toggleHide = async (item, refresh) => {
    const { error, data } = await blogService.toggleHide(item.id);
    if (error) return;
    item.hidden = data;
    refresh();
  };
  const deleteItem = (item) => {
    return blogService.deleteBlog(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        backend={blogService.getBlogsUrl}
        rowActions={[
          {
            icon: <EditIcon />,
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            icon: <DeleteIcon />,
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.title}
                close={props.close}
              />
            ),
          },
          {
            icon: visibilityIcon,
            onClick: toggleHide,
          },
        ]}
        actions={[
          {
            icon: <AddIcon />,
            tooltip: "ایجاد شعبه",
            modalContent: (props) => <FormModalContent {...props} />,
          },
        ]}
        searchKeys={defaultKeys}
        title="شعب"
      />
    </div>
  );
}
