import React from "react";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../../components/data-table";
import FormModalContent from "./form";
import carService from "../../../../services/car.service";
import ConfirmModalContent from "../../../../components/confirm-modal-content";

const headerCells = [
  {
    field: "name",
    title: "نام",
    mainField: true,
  },
];
const defaultKeys = [
  {
    title: "نام",
    field: "name",
    active: true,
  },
];

export default function BrandsPage() {
  const deleteItem = (item) => {
    return carService.deleteBrand(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        backend={carService.getBrandsUrl}
        rowActions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <EditIcon />,
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            modalProps: { maxWidth: "sm" },
            icon: <DeleteIcon />,
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.name}
                close={props.close}
              />
            ),
          },
        ]}
        actions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <AddIcon />,
            tooltip: "ایجاد برند",
            modalContent: (props) => <FormModalContent {...props} />,
          },
        ]}
        searchKeys={defaultKeys}
        title="برندها"
      />
    </div>
  );
}
