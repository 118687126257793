import React, { useMemo } from "react";
import {
  Box,
  LinearProgress,
  Typography,
  withStyles,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import moment from "moment-jalaali";

function Labels({ min, current, max, suffix = "", percentage }) {
  return (
    <Box
      className="mt-1"
      position="relative"
      display="flex"
      justifyContent="space-between"
    >
      <Typography variant="body2">{min + suffix}</Typography>
      <Typography variant="body2" dir="ltr">
        {current + suffix + (percentage ? ` (%${Math.round(percentage)})` : "")}
      </Typography>
      <Typography variant="body2">{max + suffix}</Typography>
    </Box>
  );
}

const Progress = withStyles((theme) => ({
  root: { height: theme.spacing(2) },
  colorPrimary: { backgroundColor: "#26272b" },
  bar: {
    backgroundColor: ({ value }) =>
      value < 60 ? "#58d549" : value < 80 ? "#eea520" : "#df0b0b",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      padding: `${theme.spacing(2.5)}px ${theme.spacing(1)}px 0`,
      position: "relative",
      marginTop: theme.spacing(3),
    },
    title: {
      position: "absolute",
      top: 0,
      transform: "translateY(-50%)",
      backgroundColor: theme.palette.background.paper,
      padding: `0 ${theme.spacing(1)}px`,
    },
  })
);

export default function ReminderProgress({
  date,
  mileage,
  initialMileage,
  initialDate,
  service,
  subService,
  description,
  currentMileage,
}) {
  const classes = useStyles();

  const datePercentage = useMemo(() => {
    if (!date) return null;
    const _initial = new Date(initialDate).getTime();
    const total = new Date(date).getTime() - _initial;
    const past = new Date().getTime() - _initial;
    return (past * 100) / total;
  }, [date, initialDate]);

  const mileagePercentage = useMemo(() => {
    if (!mileage) return null;
    const total = mileage - initialMileage;
    const past = currentMileage - initialMileage;
    return (past * 100) / total;
  }, [mileage, initialMileage, currentMileage]);

  const title = useMemo(() => {
    let subTitle = "";
    if (subService) {
      const subObj = service.subServices.find((s) => s.id === subService);
      subTitle = subObj.title;
    }
    return service.title + (subTitle ? ` (${subTitle})` : "");
  }, [service, subService]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>

      <div className={classes.content}>
        <Labels min="آخرین مراجعه" current="الان" max="مراجعه بعدی" />
        {datePercentage !== null && (
          <div className="my-2">
            <Progress
              variant="determinate"
              value={datePercentage < 100 ? datePercentage : 100}
            />
            <Labels
              min={moment(initialDate).format("jYYYY/jMM/jDD")}
              current={moment().format("jYYYY/jMM/jDD")}
              max={moment(date).format("jYYYY/jMM/jDD")}
              percentage={datePercentage}
            />
          </div>
        )}
        {mileagePercentage !== null && (
          <div className="my-2">
            <Progress
              variant="determinate"
              value={mileagePercentage < 100 ? mileagePercentage : 100}
            />
            <Labels
              min={initialMileage}
              current={currentMileage}
              max={mileage}
              suffix=" km"
              percentage={mileagePercentage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
