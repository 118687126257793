import React, { useState, useEffect } from "react";
import { makeStyles, Container } from "@material-ui/core";
import { useParams } from "react-router";
import LoadingComponent from "../../../components/loading";
import blogService from "../../../services/blog.service";
import NotFoundPage from "../../../components/404";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
export default function BlogPage() {
  const classes = useStyles();
  const { title } = useParams();
  const [waiting, setWaiting] = useState(true);
  const [html, setHtml] = useState();

  useEffect(() => {
    (async () => {
      setWaiting(true);
      const data = await blogService.getContent(title);
      setWaiting(false);
      if (typeof data === "object") return;
      setHtml(data);
    })();
  }, [title]);
  return (
    <LoadingComponent isLoading={waiting} className={classes.root}>
      {html ? (
        <Container>
          <div className="ql-snow">
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            />
          </div>
        </Container>
      ) : (
        <NotFoundPage />
      )}
    </LoadingComponent>
  );
}
