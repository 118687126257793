import React from "react";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../../components/data-table";
import FormModalContent from "./form";
import customerService from "../../../../services/customer.service";
import ConfirmModalContent from "../../../../components/confirm-modal-content";
import * as Yup from "yup";
import yupSchema from "../../../../constants/schema";

const importOptions = {
  validate: async (row) => {
    const schema = Yup.object().shape({
      phoneNumber: yupSchema.phoneNumber,
      firstName: yupSchema.firstName,
      lastName: yupSchema.lastName,
      birthDate: yupSchema.stringBirthDate,
      group: Yup.string().length(24),
    });
    let error;
    await schema.validate(row).catch((err) => {
      error = err.errors;
    });
    if (error) return { error };
    return { data: schema.cast(row) };
  },
  duplicate: ["phoneNumber", "code"],
  backend: customerService.importCustomersUrl,
};
const headerCells = [
  { field: "firstName", title: "نام" },
  { field: "lastName", title: "نام خانوادگی" },
  {
    field: "phoneNumber",
    title: "شماره تلفن",
    mainField: true,
  },
  { field: "code", title: "کد" },
  {
    field: "group",
    title: "گروه مشتریان",
    renderer: (group = {}) => group.title,
  },
  { field: "verified", title: "تاریخ ایجاد", date: true },
];
const defaultKeys = [
  {
    field: "firstName",
    title: "نام",
    active: true,
  },
  {
    field: "lastName",
    title: "نام خانوادگی",
    active: true,
  },
  {
    field: "phoneNumber",
    title: "شماره تلفن",
    active: true,
  },
  {
    field: "code",
    title: "کد",
    active: true,
  },
];

export default function CustomerPage() {
  const deleteItem = (item) => {
    return customerService.deleteCustomer(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        orderBy="verified"
        backend={customerService.getCustomersUrl}
        rowActions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <EditIcon />,
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            modalProps: { maxWidth: "sm" },
            icon: <DeleteIcon />,
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={`${props.data.firstName} ${props.data.lastName}`}
                close={props.close}
              />
            ),
          },
        ]}
        actions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <AddIcon />,
            tooltip: "ایجاد مشتری",
            modalContent: (props) => <FormModalContent {...props} />,
          },
        ]}
        searchKeys={defaultKeys}
        title="مشتریان"
        importOptions={importOptions}
        exportOptions={{
          data: [
            {
              phoneNumber: "",
              firstName: "",
              lastName: "",
              birthDate: "",
              code: "",
              group: "",
            },
          ],
          fileName: "customers",
        }}
      />
    </div>
  );
}
