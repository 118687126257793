import React, { useRef, useState } from "react";
import { Button } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { FTextField, FCaptcha } from "../../../components/form-elements";
import authService from "../../../services/auth.service";
import { setAccount } from "../../../redux/auth";
import { useDispatch } from "react-redux";
import AuthContainer from "../../../components/auth-container";
import yupSchema from "../../../constants/schema";
import { useHistory } from "react-router";

const schema = Yup.object().shape({
  username: yupSchema.username,
  password: yupSchema.password,
  captcha: yupSchema.captcha,
});
const initialValues = {
  username: "",
  password: "",
  captcha: { text: "", token: "" },
};
export default function AdminLogin() {
  const captchaRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const submit = async (values, actions) => {
    values = schema.cast(values);
    console.log("authenticate");
    const { data, error } = await authService.authenticate(values);

    if (error) {
      switch (error) {
        case "CaptchaExpired":
          setError("کپچا منقضی شده. دوباره تلاش کنید.");
          break;
        case "IncorrectCaptcha":
          setError("کپچا اشتباه وارد شده.");
          break;
        case "IncorrectCode":
          setError("کپچا اشتباه وارد شده.");
          break;
        case "WrongInfo":
          setError("نام کاربری یا رمز عبور اشتباه است.");
          break;
        default:
          setError("اشکالی پیش آمده، لطفا بعدا تلاش کنید.");
      }
      actions.setSubmitting(false);
      if (captchaRef?.current) {
        captchaRef.current.refresh();
      }
      return;
    }
    dispatch(setAccount(data));
    history.push("/panel");
  };
  return (
    <AuthContainer error={error}>
      <Formik
        onSubmit={submit}
        initialValues={initialValues}
        validationSchema={schema}
      >
        {({ isSubmitting }) => (
          <Form>
            <FTextField
              name="username"
              label="نام کاربری"
              className="code-text-field"
            />
            <FTextField
              name="password"
              label="رمز عبور"
              type="password"
              className="code-text-field"
            />
            <FCaptcha
              componentRef={captchaRef}
              name="captcha"
              className="mt-2 code-text-field"
            />
            <Button
              className="mt-2"
              type="submit"
              disabled={isSubmitting}
              fullWidth
              variant="contained"
              color="primary"
            >
              ورود
            </Button>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
}
