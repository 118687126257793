import httpService from "./http.service";

class AppointmentService {
  getAppointmentsUrl = "/appointments/";

  createAppointments = (model, subServiceId) => {
    return httpService.post("/appointments/" + subServiceId, model);
  };
  editAppointment = (id, model) => {
    return httpService.put("/appointments/" + id, model);
  };
  deleteAppointment = (id) => {
    return httpService.delete("/appointments/" + id);
  };
  getAppointments = (branchId, serviceId) => {
    return httpService.get(`/appointments/${branchId}/${serviceId}`);
  };
}
const appointmentService = new AppointmentService();
export default appointmentService;
