import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import yupSchema from "../../../../constants/schema";
import carItemService from "../../../../services/car-item.service";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from "@material-ui/core";
import FormBuilder from "../../../../components/form-builder";
import DialogTitle from "../../../../components/dialog-title";
import { useErrorHandler } from "../../../../hooks";
import carService from "../../../../services/car.service";
import ItemsTable from "./items-table";

const FormWrapper = ({ children }) => (
  <DialogContent>
    <Grid container spacing={2}>
      {children}
    </Grid>
  </DialogContent>
);

const GridItem = ({ children }) => (
  <Grid item sm={4} xs={12}>
    {children}
  </Grid>
);
const initialValues = {
  category: "",
  brand: "",
  model: "",
};
const schema = Yup.object().shape({
  category: yupSchema.category,
  brand: yupSchema.carItemBrand,
  model: yupSchema.carItemModel,
});
export default function FormModalContent({ close, id, items }) {
  const [formValues, setFormValues] = useState();
  const errorHandler = useErrorHandler();

  const form = useMemo(() => {
    return [
      {
        _type: "autocomplete",
        _wrapper: GridItem,
        name: "category",
        backend: carItemService.categoriesAC,
        valueSelector: "value",
        labelSelector: "title",
        label: "دسته",
        autoFocus: true,
      },
      {
        _type: "textField",
        _wrapper: GridItem,
        name: "brand",
        label: "برند",
      },
      {
        _type: "textField",
        _wrapper: GridItem,
        name: "model",
        label: "مدل",
      },
      {
        _type: "customContent",
        content: () => (
          <Grid item xs={12}>
            <ItemsTable filter={formValues} items={items} carId={id} />
          </Grid>
        ),
      },
    ];
  }, [formValues, items, id]);

  const handleError = (error) => {
    errorHandler(error, [
      { case: "DuplicateItem", message: "این آیتم تکراری است." },
      { case: "CarItemNotFound", message: "آیتم مورد نظر یافت نشد." },
      { case: "CarNotFound", message: "خودرو مورد نظر یافت نشد." },
    ]);
  };
  const handleSubmit = async (model, { setSubmitting }) => {
    const { error } = await carService.createCarItem(id, model);
    setSubmitting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <>
      <DialogTitle onClose={() => close(true)}>اضافه کردن آیتم</DialogTitle>
      <Divider />
      <FormBuilder
        schema={schema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        form={form}
        actionComponent={({ isSubmitting, values }) => (
          <DialogActions>
            <Button
              onClick={() => setFormValues(values)}
              disabled={isSubmitting}
              variant="outlined"
              color="primary"
            >
              جستجو
            </Button>
            <Button
              type="submit"
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              ایجاد
            </Button>
          </DialogActions>
        )}
        formWrapper={FormWrapper}
      />
    </>
  );
}
