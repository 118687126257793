import React, { useEffect, useState } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import LoadingComponent from "../loading";
import customerService from "../../services/customer.service";
import { StatusMap } from "../../constants/enums";
import {
  Hidden,
  Grid,
  Typography,
  makeStyles,
  createStyles,
} from "@material-ui/core";

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884d8",
  "#e2d810",
  "#d9138a",
  "#12a4d9",
  "#077b8a",
  "#5c3c92",
];

const useStyles = makeStyles((theme) =>
  createStyles({
    chartContainer: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      position: "relative",
    },
    innerText: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    loading: {
      height: 280,
    },
    placeholder: {
      height: 280,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  })
);
export default function CustomerStats() {
  const [data, setData] = useState([]);
  const [waiting, setWaiting] = useState();
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setWaiting(true);
    const { error, data } = await customerService.getStats();
    setWaiting(false);
    if (error) return;
    setData(data);
  };
  return (
    <>
      <Typography component="h3" variant="h6">
        آمار درخواست ها
      </Typography>
      {waiting ? (
        <LoadingComponent className={classes.loading} />
      ) : data.length ? (
        <Grid container justify="center">
          {data.map(({ service, status }) => (
            <Grid item xs={12} md={6} lg={4} key={service}>
              <ResponsiveChart title={service} data={status} />
              <Typography align="center">
                مجموع: {status.reduce((p, { count }) => p + count, 0)}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div className={classes.placeholder}>
          <Typography variant="h4">شما هنوز درخواستی ثبت نکردید.</Typography>
        </div>
      )}
    </>
  );
}

function Chart({ data, size = 100, label, title }) {
  const classes = useStyles();
  return (
    <div className={classes.chartContainer} dir="ltr">
      <Typography className={classes.innerText}>{title}</Typography>
      <PieChart
        width={size * 2 + (label ? 270 : 20)}
        height={size * 2 + (label ? 80 : 20)}
      >
        <Pie
          dataKey="count"
          nameKey="title"
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={size * 0.7}
          outerRadius={size}
          paddingAngle={5}
          // fill="#82ca9d"
          label={
            label
              ? ({ title, count }) => `${StatusMap[title]}: ${count}`
              : false
          }
          //   label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value, name) => [value, StatusMap[name]]} />
      </PieChart>
    </div>
  );
}
function ResponsiveChart(props) {
  return (
    <>
      <Hidden xsDown>
        <Chart {...props} size={100} label />
      </Hidden>
      <Hidden smUp>
        <Chart {...props} size={100} />
      </Hidden>
    </>
  );
}
