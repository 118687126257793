import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AppPanelSideBar from "../app-panel-side-bar";
import AppPanelHeader from "../app-panel-header";
import BACKGROUND_IMG from "../../assets/images/login-background.jpg";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: `url(${BACKGROUND_IMG}) center no-repeat fixed`,
      backgroundSize: "cover",
    },
    simpleRoot: {},
    overlay: {
      display: "flex",
      backgroundColor: theme.palette.panelBg.main,
      minHeight: "100vh",
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      minWidth: 0,
    },
  })
);

const AppPanel = ({ children, menuItems }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <div className={classes.root}>
      <div className={classes.overlay}>
        <AppPanelSideBar
          open={drawerOpen}
          onClose={toggleDrawer}
          menuItems={menuItems}
        />
        <main className={classes.content}>
          <AppPanelHeader toggleDrawer={toggleDrawer} />
          {children}
        </main>
      </div>
    </div>
  );
};

export default AppPanel;
