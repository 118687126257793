import React from "react";
import NumberFormat from "react-number-format";

export default function PhoneInput(props) {
  const { inputRef, onChange, ...other } = props;

  const handleChange = (values) => {
    onChange({
      target: {
        name: props.name,
        value: values.value,
      },
    });
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleChange}
      isNumericString
      allowEmptyFormatting
      allowLeadingZeros
      decimalScale={0}
      allowNegative={false}
      format={
        props.value && props.value[0] === "+"
          ? "### ### ### ####"
          : "#### ### ####"
      }
      mask="_&thinsp;"
    />
  );
}
