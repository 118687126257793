import React, { useState, Fragment, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  FormHelperText,
  IconButton,
  Typography,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import { FTextField } from "../../../components/form-elements";
import appointmentService from "../../../services/appointment.service";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { toGregorian } from "../../../helpers/date-helpers";
import moment from "moment-jalaali";
import DialogTitle from "../../../components/dialog-title";

const emptyValues = {
  title: "dddd jYYYY/jMM/jDD ساعت HH:mm",
  dates: [],
  times: [""],
};
const formSchema = Yup.object().shape({
  title: yupSchema.title128,
  dates: yupSchema.dates,
  times: yupSchema.timesBOD,
});
export default function FormModalContent({ subServiceId, close }) {
  const [formValue, setFormValue] = useState(emptyValues);
  const [dates, setDates] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const { dates, times } = formValue;
    const ds = [];
    if (!dates.length || !times.length || !times.find((t) => t)) {
      setDates(ds);
      return;
    }
    dates.forEach((date) => {
      const { year, month, day } = toGregorian(date);
      times.forEach((t) => {
        if (t) {
          const [h, m] = t.split(":");
          ds.push(new Date(year, month, day, h, m));
        }
      });
    });
    setDates(ds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.dates, formValue.times]);

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "SubServiceNotFound":
        message = "زیر خدمت مورد نظر یافت نشد.";
        variant = "error";
        break;
      case "PassedDate":
        message = "زمان انتخاب شده گذشته است.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async ({ title }) => {
    const now = Date.now();
    const op = await appointmentService.createAppointments(
      {
        title,
        times: dates.filter((d) => d > now),
      },
      subServiceId
    );
    const { error } = op;

    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };

  return (
    <>
      <DialogTitle onClose={() => close()}>ایجاد ساعت کاری</DialogTitle>
      <Divider />
      <Formik
        initialValues={emptyValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting, values, setFieldValue, errors, submitCount }) => {
          setFormValue(values);
          return (
            <Form autoComplete="off">
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm md={6}>
                    <div className="d-flex justify-content-center mt-1 mb-2">
                      <Calendar
                        value={values.dates}
                        locale="fa"
                        minimumDate={utils("fa").getToday()}
                        onChange={(v) => setFieldValue("dates", v)}
                        shouldHighlightWeekends
                      />
                    </div>
                    {Boolean(errors.dates && submitCount) && (
                      <FormHelperText error>{errors.dates}</FormHelperText>
                    )}

                    <FieldArray
                      name="times"
                      render={(arrayHelpers) => (
                        <>
                          <Grid container className="mt-2">
                            <Grid item xs>
                              <Typography variant="h6">ساعت ها</Typography>
                            </Grid>
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() => arrayHelpers.push("")}
                              >
                                <AddIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                          {values.times.map((property, index) => (
                            <Fragment key={index.toString()}>
                              <Grid
                                className="mb-4"
                                container
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item xs>
                                  <FTextField
                                    type="time"
                                    name={`times[${index}]`}
                                  />
                                </Grid>
                                {values.times.length > 1 && (
                                  <Grid item>
                                    <IconButton
                                      size="small"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Grid>
                                )}
                              </Grid>
                            </Fragment>
                          ))}
                        </>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm md={6}>
                    <FTextField name="title" label="عنوان" autoFocus />
                    {values.title &&
                      dates.map((date) => (
                        <Typography className="mt-2">
                          {moment(date).format(values.title)}
                        </Typography>
                      ))}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={close} color="primary" disabled={isSubmitting}>
                  لغو
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  ایجاد
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
