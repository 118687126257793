import { createSlice } from "@reduxjs/toolkit";
import { Role } from "../constants/enums";

const authSlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    setToken(state, { payload }) {
      state.token = payload;
    },
    setAccount(state, { payload }) {
      if (payload?.role === Role.Branch) {
        for (const service of payload.services) {
          if (service.needsAppointment) {
            payload.hasAppointment = true;
            break;
          }
        }
      }
      state.account = payload;
    },
    logOut(state) {
      state.token = undefined;
      state.account = undefined;
    },
  },
});

export const { setToken, logOut, setAccount } = authSlice.actions;
export default authSlice.reducer;
