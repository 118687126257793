import React, { useState } from "react";
import { useSnackbar } from "notistack";
import Loading from "../../../../components/loading";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import yupSchema from "../../../../constants/schema";
import { FTextField, FFilePicker } from "../../../../components/form-elements";
import carService from "../../../../services/car.service";
import DialogTitle from "../../../../components/dialog-title";

const emptyValues = {
  name: "",
  logo: undefined,
};
const formSchema = Yup.object().shape({
  name: yupSchema.name,
  logo: yupSchema.image,
});
export default function FormModalContent({ close, data }) {
  const [waiting, setWaiting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id, ...initialValues } = data || {};

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "NameTaken":
        message = "این نام استفاده شده است.";
        variant = "error";
        break;
      case "BrandNotfound":
        message = "برند مورد نظر یافت نشد.";
        variant = "error";
        break;
      case "DuplicateName":
        message = "این نام تکراری است.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async (model) => {
    setWaiting(true);
    let op;
    if (id) {
      op = await carService.editBrand(id, model);
    } else {
      op = await carService.createBrand(model);
    }
    const { error } = op;
    setWaiting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <Loading isLoading={waiting} overlay>
      <DialogTitle onClose={() => close()}>
        {id ? "ویرایش برند" : "ایجاد برند"}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={id ? initialValues : emptyValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <DialogContent>
              <FTextField name="name" label="نام" autoFocus />
              <FFilePicker name="logo" label="لوگو" isImage />
            </DialogContent>
            <DialogActions>
              <Button onClick={close} color="primary" disabled={isSubmitting}>
                لغو
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                {id ? "ذخیره" : "ایجاد"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Loading>
  );
}
