const env = require("./custom-environment-variables.json");
const def = require("./default.json");
const dev = require("./development.json");
const prod = require("./production.json");

module.exports = {
  get(key) {
    if (env[key] && process.env[env[key]]) return process.env[env[key]];
    const environment = process.env.NODE_ENV === "production" ? prod : dev;
    if (environment[key]) return environment[key];
    return def[key];
  },
};
