import React, { useMemo } from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import yupSchema from "../../../constants/schema";
import coordinatorService from "../../../services/coordinator.service";
import DialogFormBuilder from "../../../components/dialog-form-builder";

const initialValues = {
  username: "",
  password: "",
};

const form = [
  {
    _type: "textField",
    name: "username",
    label: "نام کاربری",
    autoFocus: true,
  },
  {
    _type: "textField",
    name: "password",
    label: "کلمه عبور",
    type: "password",
    autocomplete: "new-password",
  },
];
export default function FormModalContent({ close, data = {} }) {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = data;
  const schema = useMemo(() => {
    return Yup.object().shape({
      username: yupSchema.username,
      password: id ? yupSchema.optionalPassword : yupSchema.password,
    });
  }, [id]);

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "UsernameTaken":
        message = "این نام کاربری استفاده شده است.";
        variant = "error";
        break;
      case "CoordinatorNotFound":
        message = "هماهنگ کننده مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async (model, { setSubmitting }) => {
    let op;
    if (id) {
      op = await coordinatorService.editCoordinator(data.id, model);
    } else {
      op = await coordinatorService.createCoordinator(model);
    }
    const { error } = op;
    setSubmitting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <DialogFormBuilder
      {...{
        close,
        name: "هماهنگ کننده",
        data,
        schema,
        initialValues,
        handleSubmit,
        form,
      }}
    />
  );
}
