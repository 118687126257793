import React, { useEffect, useState } from "react";
import carService from "../../../services/car.service";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import CardIcon from "@material-ui/icons/ViewCarousel"; //Dashboard
import TableIcon from "@material-ui/icons/TableChart";
import TableView from "./table-view";
import CardView from "./card-view";

export default function CarPage() {
  const [tableView, setTableView] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const { data, error } = await carService.getCars();
    if (error) {
      // TODO: handleError
      return;
    }
    setData(data);
  };
  const deleteItem = (item) => {
    return carService.deleteCar(item.id);
  };

  return (
    <div className="p-3">
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        className="pb-2"
      >
        <span className="me-2">نمایش به صورت: </span>
        <ButtonGroup>
          <Button
            onClick={() => setTableView(false)}
            color="primary"
            variant={!tableView ? "contained" : "outlined"}
            size="small"
          >
            <CardIcon />
          </Button>
          <Button
            onClick={() => setTableView(true)}
            color="primary"
            variant={tableView ? "contained" : "outlined"}
            size="small"
          >
            <TableIcon />
          </Button>
        </ButtonGroup>
      </Box>
      {tableView ? (
        <TableView data={data} deleteItem={deleteItem} onRefresh={getData} />
      ) : (
        <CardView data={data} deleteItem={deleteItem} onRefresh={getData} />
      )}
    </div>
  );
}
