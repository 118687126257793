import {
  ButtonBase,
  Container,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(17),
      position: "relative",
      borderBottom: "2px solid #970B0B",

      "&::after": {
        content: "''",
        position: "absolute",
        width: "50%",
        maxHeight: theme.spacing(9),
        height: "10vw",
        backgroundColor: theme.palette.background.default,
        bottom: -2,
        left: "66%",
        transform: "skewX(40deg)",
        borderLeft: "2px solid #970B0B",
        borderTop: "2px solid #970B0B",
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(8),
      },
    },
    title: {
      color: "#222F3D",
      marginBottom: theme.spacing(6),
      fontWeight: 700,
    },
    swiper: {},
    card: {
      padding: 16,
      flexDirection: "column",
      backgroundColor: "#660808cc",
      color: "#fff",
      alignItems: "stretch",
      borderRadius: 16,
      display: "flex",
    },
    imageWrapper: {
      borderRadius: 8,
    },
    cardTitle: {
      fontWeight: 700,
      marginTop: 16,
      marginBottom: 8,
    },
    cardDesk: {},
  });
});
export default function HomeShop(props) {
  const { data } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Typography
          className={classes.title}
          align="center"
          component="h2"
          variant="h5"
        >
          فروشگاه (به زودی)
        </Typography>
        <Swiper
          spaceBetween={8}
          slidesPerView={1.5}
          breakpoints={{
            960: { slidesPerView: 3, spaceBetween: 16 },
            1200: { slidesPerView: 4, spaceBetween: 32 },
          }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
          className={classes.swiper}
          loop
          autoplay
        >
          {data.map((course) => (
            <SwiperSlide key={course.id}>
              <ButtonBase
                component={Link}
                href={course.url}
                className={classes.card}
              >
                <div className={clsx("image-wrapper", classes.imageWrapper)}>
                  <img src={course.image} alt="banner" border="0" />
                </div>
                <Typography className={classes.cardTitle} variant="h6">
                  {course.title}
                </Typography>
                <Typography className={classes.cardDesk}>
                  {course.description}
                </Typography>
              </ButtonBase>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
}
