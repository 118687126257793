export const currencyFormat = (value = 0, { rials, newStyle } = {}) => {
  if (newStyle) value = value * 10000;
  if (!rials) value = value / 10;
  const label = rials ? " ريال" : " تومان";
  return value.toLocaleString() + label;
};
export const assignDeep = (firstObject, secondObject) => {
  const obj = JSON.parse(JSON.stringify(firstObject));
  const fn = (obj1, obj2) => {
    if (typeof obj1 !== "object" || typeof obj2 !== "object") return;
    for (const [key, value] of Object.entries(obj2)) {
      if (typeof value === "object" && !Array.isArray(value)) {
        if (typeof obj1[key] !== "object") obj1[key] = {};
        fn(obj1[key], value);
      } else {
        obj1[key] = value;
      }
    }
  };
  fn(obj, secondObject);
  return obj;
};

export function makeRolesArray(obj) {
  const teams = ["village", "mafia", "thirdParty"];
  const values = JSON.parse(JSON.stringify(obj));
  teams.forEach((team) => {
    const arr = [];
    for (const [key, value] of Object.entries(values.roles[team])) {
      for (let i = 0; i < value; i++) {
        arr.push(key);
      }
    }
    values.roles[team] = arr;
  });
  return values;
}

function formatNum(num) {
  return ("0" + num).slice(-2);
}

export function calculateTimeLeft(date) {
  const difference = new Date(date) - Date.now();
  if (difference > 0) {
    return {
      minutes: formatNum(Math.floor(difference / 1000 / 60)),
      seconds: formatNum(Math.floor((difference / 1000) % 60)),
    };
  }
}

export function deepFind(obj, path) {
  const paths = path.split(".");
  let current = obj;

  for (const i in paths) {
    current = current[paths[i]];
    if (typeof current !== "object") {
      break;
    }
    if (Array.isArray(current) && +i < paths.length - 1) {
      const arr = [];
      paths.splice(0, +i + 1);
      current.forEach((c) => {
        arr.push(deepFind(c, paths.join(".")));
      });
      current = typeof arr[0] === "string" ? arr.join("|") : arr;

      break;
    }
  }
  return current;
}

export function getSize(num) {
  if (!num) return "";
  let i = -1;
  const measures = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  while (++i < measures.length - 1 && num >= 1024) {
    num /= 1024;
  }
  return Math.round(num * 100) / 100 + measures[i];
}
export function isSimpleObject(obj) {
  if (typeof obj !== "object") return;
  if (obj instanceof File) return;
  if (obj instanceof Date) return;
  // if (obj instanceof Array) return;
  return true;
}
export function zeroPad(num, target = 2) {
  return num.toString().padStart(target, "0");
}

// export function debounce(callback, wait, immediate = false) {
//   let timeout = 0;
//   return function (...args) {
//     const callNow = immediate && !timeout;
//     const next = () => {
//       callback.apply(null, args);
//     };

//     clearTimeout(timeout);
//     timeout = setTimeout(next, wait);

//     if (callNow) next();
//   };
// }
