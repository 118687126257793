import React, { useEffect, useState } from "react";
import { CssBaseline } from "@material-ui/core";
import LoadingComponent from "./components/loading";
import { useSelector } from "react-redux";
import Navigation from "./navigation";
import authService from "./services/auth.service";
import { Slide } from "@material-ui/core";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import { jss, theme } from "./theme";
import { SnackbarProvider } from "notistack";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

function SlideRtl(props) {
  return <Slide {...props} direction="right" />;
}

function App() {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!token) {
      return setLoading(false);
    }
    authService.getAccount().then(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={4}
          preventDuplicate
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          TransitionComponent={SlideRtl}
        >
          {loading ? <LoadingComponent fullPage /> : <Navigation />}
        </SnackbarProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
