import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import IMAGE from "../../assets/images/404.jpg";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: "center",
      backgroundColor: "#fff",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: theme.spacing(4),
    },
    title: {
      fontSize: "5rem",
    },
    imageContainer: {
      flexShrink: 1,
      minHeight: 0,
    },
    // link: { color: theme.palette.text.primary },
    image: { maxWidth: "100%", maxHeight: "100%", "-webkit-user-drag": "none" },
  })
);

export default function NotFoundPage({ fixed }) {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={fixed ? { position: "fixed" } : undefined}
    >
      <Typography
        className={classes.title}
        component="h1"
        variant="h1"
        color="primary"
      >
        <span className="en">404</span>
      </Typography>
      <Typography variant="h5" color="textPrimary">
        صفحه مورد نظر یافت نشد!
      </Typography>
      <Button className={classes.link} component={Link} to="/" color="primary">
        بازگشت به صفحه اصلی
      </Button>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={IMAGE} alt="404" />
      </div>
    </div>
  );
}
