import React, { useState, useRef } from "react";
import {
  Container,
  IconButton,
  Button,
  Dialog,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ReactQuill from "react-quill";
import { lightTheme } from "../../theme";
import { ThemeProvider } from "@material-ui/styles";

const Font = ReactQuill.Quill.import("formats/font");
Font.whitelist = ["Peyda", "sans-serif"];
ReactQuill.Quill.register(Font, true);

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: Font.whitelist }],
    ["bold", "italic", "underline", "strike"], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["blockquote", "code-block"],
    ["link", "image"],
    ["clean"],

    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ direction: "rtl" }], // text direction

    [{ align: [] }],
  ],
};

export default function Editor({ onChange, value }) {
  const [newValue, setNewValue] = useState(null);
  const [previewType, setPreviewType] = useState(0);
  const [edited, setEdited] = useState(false);

  const quill = useRef(null);

  const handleCloseModal = () => {
    setPreviewType(0);
  };
  const update = () => {
    const html = quill.current.getEditorContents();
    setNewValue(html);
    onChange(html);
    console.log(html);
    setEdited(false);
  };
  const preview = (t) => {
    console.log(t);
    if (t === 2) {
      const html = quill.current.getEditorContents();
      setNewValue(html);
    }
    setPreviewType(t);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <ReactQuill
        ref={quill}
        modules={modules}
        onChange={() => setEdited(true)}
        defaultValue={value}
      />
      <Button
        className="m-1 ms-0"
        onClick={update}
        color="primary"
        variant="contained"
        disabled={!edited}
      >
        ثبت تغییرات
      </Button>
      <Button
        className="m-1 ms-0"
        onClick={() => preview(1)}
        color="primary"
        variant="contained"
      >
        پیش نمایش
      </Button>
      <Button
        className="m-1 ms-0"
        onClick={() => preview(2)}
        color="primary"
        variant="contained"
      >
        پیش نمایش تغییرات
      </Button>
      <Dialog open={!!previewType} onClose={handleCloseModal} fullScreen>
        <AppBar className="relative">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container>
          <div className="ql-snow">
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{
                __html: previewType === 1 ? value : newValue,
              }}
            />
          </div>
        </Container>
      </Dialog>
    </ThemeProvider>
  );
}
