import { createMuiTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { jssPreset } from "@material-ui/styles";
import { create } from "jss";
import rtl from "jss-rtl";

const defaultTheme = {
  palette: {
    primary: {
      main: "#ee1d23",
    },
    secondary: {
      main: "#202125",
    },
  },
  direction: "rtl",
  typography: {
    fontFamily: ["Peyda", "sans-serif"].join(","),
    h1: { fontSize: "3rem", fontWeight: 600 },
    h2: { fontSize: "2.75rem", fontWeight: 600 },
    h3: { fontSize: "2.125rem", fontWeight: 600 },
    h4: { fontSize: "1.875rem", fontWeight: 500 },
    h5: { fontSize: "1.5rem", fontWeight: 500 },
    h6: { fontSize: "1.25rem" },
  },
};
const dark = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    panelBg: {
      main: "#000b",
    },
    glassBackground: {
      paper: "#42424272",
      default: "#0002",
    },
    background: {
      default: "#191a1e",
      paper: "#202125",
    },
    type: "dark",
  },
};
const light = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    panelBg: {
      main: "#fff1",
    },
    glassBackground: {
      paper: "#fffd",
      default: "#fffd",
    },
    type: "light",
  },
};
export const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const theme = createMuiTheme(dark);
export const lightTheme = createMuiTheme(light);

export const useGlobalStyles = makeStyles((theme) => ({
  "@global": {
    "[hidden]": {
      display: "none",
    },
    ".simple-card": {
      borderWidth: 1,
      borderColor: theme.palette.divider,
      padding: theme.spacing(2),
      borderStyle: "solid",
      borderRadius: 4,
    },
    ".Calendar": {
      "--cl-color-primary": `${theme.palette.primary.main} !important`,
      "--cl-color-primary-light": `${theme.palette.primary.light} !important`,
      "--cl-color-black": theme.palette.text.primary,
      "--cl-color-disabled": theme.palette.text.secondary,
      "--cl-color-error": theme.palette.error.main,
      background: theme.palette.background.paper,
    },
    ".Calendar__monthSelector, .Calendar__yearSelector": {
      backgroundColor: theme.palette.background.paper,
    },
    ".Calendar__day, .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween), .Calendar > :not(.Calendar__footer) button":
      {
        color: theme.palette.text.primary,
      },
    ".Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after":
      {
        backgroundColor: theme.palette.text.primary,
      },
    ".Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover, .Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus, .Calendar__monthYear > *.-activeBackground, .Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover":
      {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
    ".Calendar__yearSelectorWrapper::after": {
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.paper} 5%, transparent)`,
    },
    ".Calendar__yearSelectorWrapper::before": {
      backgroundImage: `linear-gradient(to top, ${theme.palette.background.paper} 5%, transparent)`,
    },
    ".Calendar__monthArrow": {
      filter: "invert(100%)",
    },
    ".MuiPaper-root.padder": {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    ".MuiAvatar-colorDefault.self": { backgroundColor: "#fff" },
    ".MuiPagination-ul": { justifyContent: "center" },
    ".MuiFormControlLabel-root.full-width": {
      display: "flex",
      "&.MuiFormControlLabel-labelPlacementStart": { marginLeft: 0 },
      "&.MuiFormControlLabel-labelPlacementEnd": { marginRight: 0 },
      "& .MuiFormControlLabel-label": { flex: 1 },
    },
    ".MuiAccordionDetails-root": { display: "block" },
    ".text-nowrap": { whiteSpace: "nowrap" },
    ".text-center": { textAlign: "center" },
    ".justify-content-center": { justifyContent: "center" },
    ".number-button": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      "& .value": { minWidth: 50 },
    },
    ".fixed-fab": {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    ".paper-header": { display: "flex", alignItems: "center" },
    ".logo-container": {
      textAlign: "center",
      "& img": { maxWidth: "100%", height: 120, borderRadius: "50%" },
    },
    ".landing-separator": {
      position: "relative",
      display: "flex",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      alignItems: "center",
      justifyContent: "center",
      "& span": {
        width: theme.spacing(2),
        height: theme.spacing(1.25),
        marginLeft: 1,
        marginRight: 1,
        transform: "skewX(-10deg)",
        display: "inline-block",
        position: "relative",
        backgroundColor: "#fff",
      },
      "&::before, &::after": {
        content: "''",
        width: 100,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        height: theme.spacing(0.5),
        transform: "skewX(-10deg)",
      },
      "&.dark": {
        "& span": {
          backgroundColor: "#202125",
        },
      },
    },
    ".slick-slide": { outline: "none !important", direction: "ltr" },
    ".slick-slide *": { outline: "none !important" },
    ".uppercase, .uppercase *": { textTransform: "uppercase" },
    ".flex-1": { flex: 1 },
    ".w-0": { width: 0 },
    ".m-1": { margin: `${theme.spacing(1)}px !important` },
    ".m-2": { margin: `${theme.spacing(2)}px !important` },
    ".m-auto": { margin: "auto" },
    ".my-1": {
      marginBottom: `${theme.spacing(1)}px !important`,
      marginTop: `${theme.spacing(1)}px !important`,
    },
    ".my-2": {
      marginBottom: `${theme.spacing(2)}px !important`,
      marginTop: `${theme.spacing(2)}px !important`,
    },
    ".my-5": {
      marginBottom: `${theme.spacing(5)}px !important`,
      marginTop: `${theme.spacing(5)}px !important`,
    },
    ".my-auto": { marginTop: "auto", marginBottom: "auto" },
    ".mt-auto": { marginTop: "auto" },
    ".mt-1": { marginTop: `${theme.spacing(1)}px !important` },
    ".mt-2": { marginTop: `${theme.spacing(2)}px !important` },
    ".mt-3": { marginTop: `${theme.spacing(3)}px !important` },
    ".mx-2": {
      marginLeft: `${theme.spacing(2)}px !important`,
      marginRight: `${theme.spacing(2)}px !important`,
    },
    ".mt-5": { marginTop: `${theme.spacing(5)}px !important` },
    ".mb-1": { marginBottom: `${theme.spacing(1)}px !important` },
    ".mb-2": { marginBottom: `${theme.spacing(2)}px !important` },
    ".mb-3": { marginBottom: `${theme.spacing(3)}px !important` },
    ".me-1": { marginRight: `${theme.spacing(1)}px !important` },
    ".me-2": { marginRight: `${theme.spacing(2)}px !important` },
    ".ms-0": { marginLeft: 0 },
    ".ms-1": { marginLeft: theme.spacing(1) },
    ".ms-2": { marginLeft: theme.spacing(2) },
    ".ms-auto": { marginLeft: "auto !important" },
    ".p-0": { padding: 0 },
    ".p-1": { padding: `${theme.spacing(1)}px !important` },
    ".p-2": { padding: `${theme.spacing(2)}px !important` },
    ".p-3": { padding: `${theme.spacing(3)}px !important` },
    ".py-1": {
      paddingTop: `${theme.spacing(1)}px !important`,
      paddingBottom: `${theme.spacing(1)}px !important`,
    },
    ".py-2": {
      paddingTop: `${theme.spacing(2)}px !important`,
      paddingBottom: `${theme.spacing(2)}px !important`,
    },
    ".py-3": {
      paddingTop: `${theme.spacing(3)}px !important`,
      paddingBottom: `${theme.spacing(3)}px !important`,
    },
    ".py-10": {
      paddingTop: `${theme.spacing(10)}px !important`,
      paddingBottom: `${theme.spacing(10)}px !important`,
    },
    ".px-2": {
      paddingLeft: `${theme.spacing(2)}px !important`,
      paddingRight: `${theme.spacing(2)}px !important`,
    },
    ".px-4": {
      paddingLeft: `${theme.spacing(4)}px !important`,
      paddingRight: `${theme.spacing(4)}px !important`,
    },
    ".pb-0": { paddingBottom: 0 },
    ".pb-2": { paddingBottom: `${theme.spacing(2)}px !important` },
    ".pb-10": { paddingBottom: `${theme.spacing(10)}px !important` },
    ".hide-empty:empty": { display: "none !important" },
    ".visually-hidden": {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    ".relative": { position: "relative !important" },
    img: { maxWidth: "100%" },

    ".d-flex": {
      display: "flex",
    },
    ".flex-nowrap": { flexWrap: "no-wrap" },
    ".w-1": {
      width: 1,
    },
    ".MuiPaper-root.glass": {
      backgroundColor: theme.palette.glassBackground.paper,
      backdropFilter: "blur(6px)",
    },
    ".MuiPaper-root.matte-glass": {
      backgroundColor: theme.palette.glassBackground.paper,
      backdropFilter: "blur(30px)",
    },
    ".image-wrapper": {
      overflow: "hidden",
      paddingTop: "50%",
      isolation: "isolate",
      position: "relative",
      "& > img": {
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        position: "absolute",
      },
    },
  },
}));
