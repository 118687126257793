import React, { useMemo } from "react";
import {
  Container,
  Paper,
  Grid,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import FormBuilder from "../../../components/form-builder";
import yupSchema from "../../../constants/schema";
import customerService from "../../../services/customer.service";
import authService from "../../../services/auth.service";
import { setAccount } from "../../../redux/auth";
import ExitIcon from "@material-ui/icons/ExitToApp";
import { useErrorHandler } from "../../../hooks";
import { useSnackbar } from "notistack";
import CustomerStats from "../../../components/customer-stats";

const schema = Yup.object().shape({
  firstName: yupSchema.firstName,
  lastName: yupSchema.lastName,
  phoneNumber: yupSchema.phoneNumber,
  code: yupSchema.customerCode,
});

function FormWrapper({ children }) {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
}
function ElementWrapper({ children }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}
export default function CustomerProfile() {
  const { account } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo(() => {
    const { firstName, lastName, phoneNumber, code, group, birthDate } =
      account;
    return {
      firstName,
      lastName,
      phoneNumber,
      code,
      group,
      birthDate,
    };
  }, [account]);

  const form = useMemo(() => {
    return [
      {
        _type: "textField",
        _wrapper: ElementWrapper,
        name: "firstName",
        label: "نام",
        autoFocus: true,
        disabled: !!account.code,
      },
      {
        _type: "textField",
        _wrapper: ElementWrapper,
        name: "lastName",
        label: "نام خانوادگی",
        disabled: !!account.code,
      },
      {
        _type: "datePicker",
        _wrapper: ElementWrapper,
        name: "birthDate",
        label: "تاریخ تولد",
        birthDate: true,
        disabled: !!account.birthDate,
      },
      {
        _type: "textField",
        _wrapper: ElementWrapper,
        name: "phoneNumber",
        label: "شماره تلفن",
        type: "phone",
        className: "code-text-field",
        disabled: true,
      },
      {
        _type: "textField",
        _wrapper: ElementWrapper,
        name: "code",
        label: "کد",
        disabled: true,
      },
      {
        _type: "autocomplete",
        _wrapper: ElementWrapper,
        name: "group",
        backend: customerService.customerGroupsAC,
        valueSelector: "value",
        labelSelector: "title",
        label: "گروه مشتریان",
        disabled: true,
        hidden: !account.group,
      },
    ];
  }, [account]);

  const submit = async (values, actions) => {
    values = schema.cast(values);
    const { error, data } = await authService.updateProfile(values);
    actions.setSubmitting(false);
    if (error) {
      handleError();
      return;
    }
    enqueueSnackbar("تغییرات با موفقیت ثبت شد.", { variant: "success" });
    dispatch(setAccount(data));
  };

  return (
    <Container className="py-3">
      <Paper className="mt-2 p-2">
        <CustomerStats />
      </Paper>
      <Paper className="mt-2 p-2">
        <Typography className="mb-2" component="h3" variant="h6">
          مشخصات
        </Typography>
        <FormBuilder
          schema={schema}
          initialValues={initialValues}
          handleSubmit={submit}
          form={form}
          formWrapper={FormWrapper}
          actionComponent={account.code ? () => null : undefined}
          submitDirty
        />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            className="px-3 mt-2"
            size="large"
            startIcon={<ExitIcon />}
            onClick={authService.logout}
          >
            خروج
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
