import httpService from "./http.service";

class SubServiceService {
  subServicesAC = "/sub-services/ac/";

  createSubService = (serviceId, model) => {
    return httpService.form(
      "/sub-services/" + serviceId,
      model,
      "createSubService"
    );
  };
  getServiceSubServices = (serviceId) => {
    return httpService.get("/sub-services/by-service-id/" + serviceId);
  };
  editSubService = (serviceId, id, model) => {
    return httpService.form(
      `/sub-services/${serviceId}/${id}`,
      model,
      "editService",
      {},
      "put"
    );
  };
  deleteSubService = (serviceId, id) => {
    return httpService.delete(`/sub-services/${serviceId}/${id}`);
  };
  toggleHide = (serviceId, id) => {
    return httpService.put(`/sub-services/toggle-hide/${serviceId}/${id}`);
  };
}

const subServiceService = new SubServiceService();
export default subServiceService;
