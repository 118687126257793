import React from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import yupSchema from "../../../../constants/schema";
import carItemService from "../../../../services/car-item.service";
import DialogFormBuilder from "../../../../components/dialog-form-builder";

const initialValues = {
  category: "",
  brand: "",
  model: "",
  description: "",
  image: undefined,
};
const schema = Yup.object().shape({
  category: yupSchema.category,
  brand: yupSchema.carItemBrand,
  model: yupSchema.carItemModel,
  description: yupSchema.description,
  image: yupSchema.image,
});
const form = [
  {
    _type: "autocomplete",
    name: "category",
    backend: carItemService.categoriesAC,
    valueSelector: "value",
    labelSelector: "title",
    label: "دسته",
    autoFocus: true,
  },
  {
    _type: "textField",
    name: "brand",
    label: "برند",
  },
  {
    _type: "textField",
    name: "model",
    label: "مدل",
  },
  {
    _type: "textField",
    name: "description",
    label: "توضیحات",
    multiline: true,
    rows: 4,
  },
  {
    _type: "filePicker",
    name: "image",
    label: "عکس",
    isImage: true,
  },
];
export default function FormModalContent({ close, data }) {
  const newData = { ...data };
  if (newData.category) {
    newData.category = newData.category.id;
  }
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "DuplicateItem":
        message = "این آیتم تکراری است.";
        variant = "error";
        break;
      case "CarItemNotFound":
        message = "آیتم مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async (model, { setSubmitting }) => {
    let op;
    if (data && data.id) {
      op = await carItemService.editCarItem(data.id, model);
    } else {
      op = await carItemService.createCarItem(model);
    }
    const { error } = op;
    setSubmitting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <DialogFormBuilder
      {...{
        close,
        name: "آیتم",
        data: newData,
        schema,
        initialValues,
        handleSubmit,
        form,
      }}
    />
  );
}
