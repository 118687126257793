import React from "react";
import { Typography, makeStyles, createStyles } from "@material-ui/core";
import BuildIcon from "@material-ui/icons/Build";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "calc(100% - 70px)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: { fontSize: 100, margin: "40px 0" },
  })
);
export default function Reports() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BuildIcon className={classes.icon} />
      <Typography variant="h4">این صفحه در دست ساخت است.</Typography>
    </div>
  );
}
