import Loading from "../../../components/loading";
import { useSnackbar } from "notistack";
import config from "../../../config";
import axios from "axios";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  FormHelperText,
} from "@material-ui/core";
import React, { useState, useEffect, useMemo } from "react";
import { Form, Formik, FastField } from "formik";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import { FFilePicker, FTextField } from "../../../components/form-elements";
import blogService from "../../../services/blog.service";
import Editor from "../../../components/editor";
import DialogTitle from "../../../components/dialog-title";

const emptyValues = {
  title: "",
  category: "",
  summery: "",
  tags: [],
  author: "",
  content: undefined,
  image: undefined,
};

export default function FormModalContent({ close, data }) {
  const [waiting, setWaiting] = useState(true);
  const [content, setContent] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { id, content: contentAddress, ...initialValues } = data || {};

  const formSchema = useMemo(() => {
    return Yup.object().shape({
      title: yupSchema.title,
      category: yupSchema.category,
      tags: yupSchema.tags,
      author: yupSchema.author,
      summery: yupSchema.summery,
      content: id ? Yup.mixed() : yupSchema.content,
      image: yupSchema.image,
    });
  }, [id]);

  useEffect(() => {
    (async () => {
      if (contentAddress) {
        // TODO: Comment this in prod!
        // const op = await axios.get(contentAddress);

        const op = await axios.get(
          `${config.get("endpoint")}${contentAddress}`
        );
        if (op.data) {
          setContent(op.data);
        }
      }
      setWaiting(false);
    })();
  }, [contentAddress]);

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "DuplicateTitle":
        message = "این عنوان تکراری است.";
        variant = "error";
        break;
      case "BlogNotFound":
        message = "بلاگ مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async (old) => {
    const model = { ...old };
    if (model.content) {
      const file = new File([model.content], model.title + ".txt", {
        type: "text/plain",
      });
      model.content = file;
    }

    let op;
    if (id) {
      op = await blogService.editBlog(id, model);
    } else {
      op = await blogService.createBlog(model);
    }
    const { error } = op;
    setWaiting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <Loading isLoading={waiting} overlay>
      <DialogTitle onClose={() => close()}>
        {id ? "ویرایش بلاگ" : "ایجاد بلاگ"}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={id ? initialValues : emptyValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting, setFieldValue, errors, submitCount }) => (
          <Form autoComplete="off">
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FTextField name="title" label="عنوان" autoFocus />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField name="author" label="نویسنده" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField name="category" label="دسته" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FFilePicker name="image" isImage label="عکس" />
                </Grid>
                <Grid item xs={12}>
                  <FTextField name="summery" multiline rows={4} label="خلاصه" />
                </Grid>
                <Grid item xs={12}>
                  <FastField
                    component={Editor}
                    name="content"
                    onChange={(v) => setFieldValue("content", v)}
                    value={content}
                  />

                  {Boolean(errors.content && submitCount) && (
                    <FormHelperText error>{errors.content}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={close} color="primary" disabled={isSubmitting}>
                لغو
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                {id ? "ذخیره" : "ایجاد"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Loading>
  );
}
