import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from "@material-ui/core";
import FormBuilder from "../form-builder";

import DialogTitle from "../dialog-title";

export default function DialogFormBuilder({
  close,
  name,
  data = {},
  schema,
  initialValues,
  handleSubmit,
  form = [],
  submitLabel,
  title,
  submitButton,
}) {
  const { id, ...other } = data;
  if (id) {
    initialValues = { ...initialValues, ...other };
  }
  return (
    <>
      <DialogTitle onClose={() => close()}>
        {title || (id ? "ویرایش " : "ایجاد ") + name}
      </DialogTitle>
      <Divider />
      <FormBuilder
        schema={schema}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        form={form}
        actionComponent={({ isSubmitting }) => (
          <DialogActions>
            <Button onClick={close} color="primary" disabled={isSubmitting}>
              لغو
            </Button>
            {submitButton || (
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                {submitLabel || (id ? "ذخیره" : "ایجاد")}
              </Button>
            )}
          </DialogActions>
        )}
        formWrapper={DialogContent}
      />
    </>
  );
}
