import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  makeStyles,
  Typography,
  CardContent,
  Link,
} from "@material-ui/core";
import BACKGROUND_IMG from "../../../assets/images/contact-us.jpg";
import branchService from "../../../services/branch.service";
import LoadingComponent from "../../../components/loading";
import ThemeSection from "../../../components/theme-section";
import LocationIcon from "@material-ui/icons/PinDrop";
import InstagramIcon from "@material-ui/icons/Instagram";
import PhoneIcon from "@material-ui/icons/Phone";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MARKER_ICON from "leaflet/dist/images/marker-icon.png";
import MARKER_SHADOW from "leaflet/dist/images/marker-shadow.png";
import Leaflet from "leaflet";

const DefaultIcon = Leaflet.icon({
  iconUrl: MARKER_ICON,
  shadowUrl: MARKER_SHADOW,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});
Leaflet.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
  root: {},
  head: {
    background: `url(${BACKGROUND_IMG}) top center`,
    backgroundSize: "cover",
  },
  headOverlay: {
    backgroundColor: "rgba(0,0,0,0.7)",
    paddingTop: "15%",
    paddingBottom: "15%",
  },
  content: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  card: {
    marginBottom: theme.spacing(4),
  },
  cardTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  addressWrapper: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  icon: {
    width: "4rem",
    fontSize: "2rem",
  },
  addressTitle: {
    fontWeight: 600,
  },
  mapContainer: {
    direction: "ltr",
    height: 300,
    marginTop: theme.spacing(3),
  },
}));
export default function ContactPage() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  useEffect(() => {
    (async () => {
      const { data, error } = await branchService.getBranches();
      setLoading(false);
      if (error) return;
      setBranches(data);
    })();
  }, []);
  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div className={classes.headOverlay}>
          <Container>
            <Typography component="h2" variant="h3">
              تماس با ما
            </Typography>
          </Container>
        </div>
      </div>
      <ThemeSection className={classes.content}>
        <Container>
          <LoadingComponent isLoading={loading}>
            {branches.map((branch) => {
              const [lat, long] = branch.contact.location.split(",");
              return (
                <Card className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      component="h2"
                      variant="h4"
                    >
                      {branch.branchName}
                    </Typography>
                    <Typography>{branch.description}</Typography>

                    <div className={classes.addressWrapper}>
                      <PhoneIcon className={classes.icon} />
                      <div>
                        <Typography className={classes.addressTitle}>
                          تلفن
                        </Typography>
                        {branch.contact.phoneNumbers.map((pn) => (
                          <Link
                            className="ltr"
                            component="a"
                            href={`tel:${pn}`}
                          >
                            {pn}
                          </Link>
                        ))}
                      </div>
                    </div>
                    {branch.contact.instagram && (
                      <div className={classes.addressWrapper}>
                        <InstagramIcon className={classes.icon} />
                        <div>
                          <Link
                            href={branch.contact.instagram}
                            target="_blank"
                            className={classes.addressTitle}
                          >
                            اینستاگرام
                          </Link>
                        </div>
                      </div>
                    )}
                    <div className={classes.addressWrapper}>
                      <LocationIcon className={classes.icon} />
                      <div>
                        <Typography className={classes.addressTitle}>
                          آدرس
                        </Typography>
                        <Typography>{branch.contact.address}</Typography>
                      </div>
                    </div>

                    <MapContainer
                      className={classes.mapContainer}
                      center={[lat, long]}
                      zoom={16}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker position={[lat, long]}>
                        <Popup>
                          A pretty CSS3 popup. <br /> Easily customizable.
                        </Popup>
                      </Marker>
                    </MapContainer>
                  </CardContent>
                </Card>
              );
            })}
          </LoadingComponent>
        </Container>
      </ThemeSection>
    </div>
  );
}
