import React from "react";
import MuiFilePicker from "../../file-picker";
import { getIn } from "formik";

function FilePicker(props) {
  const {
    form: { errors, setFieldValue, submitCount },
    field: { name, value },
    onChange,
    ...other
  } = props;

  const errorText = getIn(errors, name);
  const hasError = Boolean(errorText && submitCount);
  const options = props.isImage
    ? {
        type: "jpg",
        maxWidth: 1280,
        maxHeight: 1280,
        maxRatio: 1.9,
        minRatio: 0.8,
        ...(props.imageOptions || {}),
      }
    : undefined;

  const handleChange = (value) => {
    setFieldValue(name, value);
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <MuiFilePicker
      name={name}
      error={hasError}
      helperText={hasError ? errorText : ""}
      value={value}
      onChange={handleChange}
      imageOptions={options}
      {...other}
    />
  );
}

FilePicker.defaultProps = {
  form: {},
  field: {},
};
export default FilePicker;
