import React from "react";
import {
  Typography,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from "@material-ui/core";
import DialogTitle from "../../../components/dialog-title";
import moment from "moment-jalaali";
import CoordinatorSection from "./coordinator";
import CustomerMessageSection from "./customer-message";
import BranchDescriptionSection from "./branch-description";
import BranchDoneListSection from "./branch-done-list";

function GridItemText({ value, label, valueClassName, sm }) {
  if (!value) return null;
  return (
    <Grid item xs={12} sm={sm || 6}>
      <Typography>
        {label}: <span className={valueClassName}> {value} </span>
      </Typography>
    </Grid>
  );
}

export default function RequestInfo({ close, data, extra }) {
  return (
    <>
      <DialogTitle onClose={() => close()}>مشخصات درخواست</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <GridItemText label="کد" value={data.code} valueClassName="en" />
          <GridItemText
            label="تاریخ"
            value={moment(data.date).format("jYYYY/jMM/jDD HH:mm")}
          />
          <GridItemText
            label="خدمت"
            value={extra.services[data.service]?.title}
          />
          <GridItemText
            label="زیر خدمت ها"
            value={data.subServices.map((s) => extra.subServices[s]).join(", ")}
          />
          {data.car && (
            <Grid item xs={12}>
              <div className="simple-card">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">مشخصات خودرو</Typography>
                  </Grid>
                  <GridItemText label="برند" value={data.car.brand.name} />
                  <GridItemText label="مدل" value={data.car.carClass.name} />
                  <GridItemText
                    label="شماره شاسی"
                    value={data.car.chassisNumber}
                  />
                  <GridItemText label="رنگ" value={data.car.color} />
                  <GridItemText label="کیلومتر" value={data.car.mileage} />
                  <GridItemText label="سال" value={data.car.year} />
                </Grid>
              </div>
            </Grid>
          )}
          {data.customer && (
            <Grid item xs={12}>
              <div className="simple-card">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">مشخصات مالک</Typography>
                  </Grid>
                  <GridItemText label="کد" value={data.customer.account.code} />
                  <GridItemText
                    label="شماره تلفن"
                    value={data.customer.account.phoneNumber}
                    valueClassName="en"
                  />
                  <GridItemText
                    label="نام"
                    value={data.customer.account.firstName}
                  />
                  <GridItemText
                    label="نام خانوادگی"
                    value={data.customer.account.lastName}
                  />
                </Grid>
              </div>
            </Grid>
          )}
          <CustomerMessageSection customer={data.customer} title="پیام مشتری" />
          <CoordinatorSection
            coordinator={data.coordinator}
            title="پیام هماهنگ کننده"
          />
          <BranchDescriptionSection branch={data.branch} />
          <BranchDoneListSection branch={data.branch} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="contained" color="primary">
          بستن
        </Button>
      </DialogActions>
    </>
  );
}
