import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { compressImage } from "../../helpers/file-helpers";
import { getSize } from "../../helpers";
import config from "../../config";

export default function FilePicker(props) {
  const { isImage, multiple, imageOptions, value, onChange, ...other } = props;
  const fileInput = useRef(null);
  const [previewFiles, setPreviewFiles] = useState([]);

  useEffect(() => {
    const checkPrevFiles = async () => {
      if (!value || (Array.isArray(value) && !value.length)) {
        setPreviewFiles([]);
        return;
      }
      const newPreviewFiles = [];
      let changed = false;
      const setPreviewHelper = async (val) => {
        if (typeof val === "string") {
          const found = previewFiles.find((f) => f.src === val);
          if (!found) {
            changed = true;
          }
          newPreviewFiles.push(found || { src: val });
        } else {
          const found = previewFiles.find(
            (f) =>
              f.lastModified === val.lastModified &&
              f.name === val.name &&
              f.size === val.size
          );
          if (!found) {
            changed = true;
            const {
              src,
              newFile: { lastModified, size, name },
            } = await compressImage(val, imageOptions);
            newPreviewFiles.push({ src, lastModified, size, name });
            return;
          }
          newPreviewFiles.push(found);
        }
      };
      if (isImage) {
        if (!Array.isArray(value)) {
          await setPreviewHelper(value);
        } else {
          await Promise.all(
            value.map(async (v) => {
              await setPreviewHelper(v);
            })
          );
        }
      }
      if (changed || newPreviewFiles.length !== previewFiles.length) {
        setPreviewFiles(newPreviewFiles);
      }
    };
    checkPrevFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFocusInput = () => {
    if (fileInput.current && !props.disabled) {
      fileInput.current.click();
    }
  };
  const handleChange = async (e) => {
    const { files: inputFiles } = e.target;
    if (!inputFiles) return;
    let filesArr = Array.from(inputFiles);
    if (isImage) {
      filesArr = await Promise.all(
        filesArr.map(async (f) => {
          const { src, newFile } = await compressImage(f, imageOptions);
          const { lastModified, size, name } = newFile;
          setPreviewFiles((prev) => [
            ...prev,
            { src, lastModified, size, name },
          ]);
          return newFile;
        })
      );
    } else {
      //  TODO: set file extension image
    }
    if (filesArr.length) {
      const newFiles = multiple ? [...value, ...filesArr] : filesArr;
      if (onChange) {
        onChange(multiple ? newFiles : newFiles[0]);
      }
    }
  };
  const removeFile = (index) => {
    if (Array.isArray(value)) {
      const newVal = [...value];
      newVal.splice(index, 1);
      if (onChange) {
        onChange(newVal);
      }
    } else {
      if (onChange) {
        onChange(undefined);
      }
    }
  };
  const renderListItem = (value, index) => {
    let prev;
    if (typeof value === "string") {
      prev = previewFiles.find((p) => p.src === value) || {};
      prev.src = config.get("endpoint") + prev.src;
    } else {
      prev = previewFiles.find(
        (p) =>
          p.size === value.size &&
          p.name === value.name &&
          p.lastModified === value.lastModified
      );
    }
    if (!prev) return null;

    const size = getSize(prev.size);
    return (
      <ListItem key={prev?.name || prev.src} button>
        <ListItemAvatar>
          <Avatar
            alt={prev.name}
            src={prev.src}
            variant="square"
            // variant="rounded"
          />
        </ListItemAvatar>
        <ListItemText
          primary={prev.name}
          secondary={size}
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ noWrap: true }}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => removeFile(index)} edge="end">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <div>
      <input
        ref={fileInput}
        type="file"
        hidden
        multiple={multiple || true}
        accept={isImage ? "image/*" : undefined}
        onChange={handleChange}
      />
      <TextField
        onClick={handleFocusInput}
        fullWidth
        label="label"
        inputProps={{ disabled: true }}
        {...other}
      />
      {value && previewFiles.length ? (
        <div className="bg-default">
          <List dense>
            {Array.isArray(value)
              ? value.map(renderListItem)
              : renderListItem(value)}
          </List>
        </div>
      ) : null}
    </div>
  );
}
