import React, { useEffect, useMemo, useState } from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../../components/data-table";
import ConfirmModalContent from "../../../../components/confirm-modal-content";
import FormModalContent from "./form";
import CarClassForm from "./car-class-form";
import carItemService from "../../../../services/car-item.service";
import carService from "../../../../services/car.service";

const headerCells = [
  {
    field: "brand",
    title: "برند",
    mainField: true,
  },
  {
    field: "model",
    title: "مدل",
  },
  {
    field: "category",
    title: "دسته",
    renderer: (category) => category.name,
  },
];

const defaultKeys = [
  {
    title: "برند",
    field: "brand",
    active: true,
  },
  {
    title: "مدل",
    field: "model",
    active: true,
  },
];
const filterForm = {
  form: [
    {
      _type: "autocomplete",
      _empty: undefined,
      name: "category",
      backend: carItemService.categoriesAC,
      valueSelector: "value",
      labelSelector: "title",
      label: "دسته",
    },
  ],
};

export default function CarItemGroup({ id }) {
  const [data, setData] = useState();

  useEffect(() => {
    if (!id) {
      setData(undefined);
      return;
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getData = async () => {
    const { data, error } = await carService.getCarItems(id);
    if (error) return;
    setData(data);
  };

  const backend = useMemo(() => {
    if (id) return undefined;
    return carItemService.getCarItemsUrl;
  }, [id]);

  const deleteItem = (item) => {
    if (id) {
      return carService.deleteCarItem(id, item.id);
    }
    return carItemService.deleteCarItem(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        backend={backend}
        data={data}
        onRefresh={getData}
        headCells={headerCells}
        rowActions={[
          {
            icon: <EditIcon />,
            hidden: () => !!id,
            modalProps: { maxWidth: "sm" },
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            icon: <DeleteIcon />,
            modalProps: { maxWidth: "sm" },
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={`${props.data.brand} ${props.data.model}`}
                close={props.close}
              />
            ),
          },
        ]}
        actions={
          id
            ? [
                {
                  icon: <AddIcon />,
                  modalContent: (props) => (
                    <CarClassForm {...props} id={id} items={data} />
                  ),
                },
              ]
            : [
                {
                  icon: <AddIcon />,
                  modalContent: (props) => <FormModalContent {...props} />,
                  modalProps: { maxWidth: "sm" },
                },
              ]
        }
        searchKeys={defaultKeys}
        title="آیتم ها"
        filterForm={filterForm}
      />
    </div>
  );
}
