import React from "react";
import { FastField } from "formik";
import { Switch } from "../../formik-material-ui";
import { FormControlLabel } from "@material-ui/core";

export default function FSwitch({
  label,
  labelPlacement,
  fullWidth,
  ...other
}) {
  return (
    <FormControlLabel
      control={
        <FastField
          component={Switch}
          type="checkbox"
          color="primary"
          {...other}
        />
      }
      label={label}
      labelPlacement={labelPlacement || "start"}
      className={fullWidth !== false ? "full-width" : ""}
    />
  );
}
