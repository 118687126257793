import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import config from "../../../config";
import PLACEHOLDER from "../../../assets/images/car-placeholder.png";
import { useSelector } from "react-redux";
import {
  ExpandMore as ExpandMoreIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  MenuBook as ItemsInfoIcon,
  Speed as MileageIcon,
  Save as SaveIcon,
  Build as RequestIcon,
} from "@material-ui/icons";
import {
  CircularProgress,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import MileageInput from "../../../components/mileage-input";
import carService from "../../../services/car.service";
import { useErrorHandler } from "../../../hooks";
import ReminderProgress from "../../../components/reminder-progress";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    // marginTop: "auto",
  },
  mediaWrapper: {
    position: "relative",
    backgroundImage: `url(${PLACEHOLDER})`,
    backgroundSize: "cover",
    "& > *": {
      transition: theme.transitions.create(),
    },
    "& .overlay": {
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backdropFilter: "blur(5px)",
      opacity: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "&:hover": {
      "& .overlay": {
        opacity: 1,
      },
      "& .MuiCardMedia-root": {
        opacity: 0.2,
      },
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function CarCard(props) {
  const {
    id,
    title,
    brand,
    carClass,
    // owner,
    // year,
    // chassisNumber,
    // code,
    mileage,
    // color,
    description,
    // lastMileageUpdate,
    image,
    //   Methods
    onRequest,
    onEdit,
    onDelete,
    onItemInfo,
    onRefresh,
  } = props;
  const inputRef = useRef();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [mileageEditable, setMileageEditable] = useState();
  const [mileageText, setMileageText] = useState(mileage);
  const [mileageLoading, setMileageLoading] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { account } = useSelector((state) => state.auth);

  const handleError = useErrorHandler();

  useEffect(() => {
    setMileageText(mileage);
  }, [mileage]);

  const reminder = useMemo(() => {
    const reminders = account.reminders.filter(
      (r) => !r.seen && r.car.id === id
    );
    if (!reminders.length) return;
    if (reminders.length === 1) return reminders[0];

    const now = Date.now();
    const rates = [];
    reminders.forEach((r) => {
      const dateRate = calcRate(
        new Date(r.date).getTime(),
        new Date(r.initialDate).getTime(),
        now
      );
      const mileageRate = calcRate(r.mileage, r.initialMileage, mileage);
      const totalRate = Math.max(dateRate, mileageRate);
      rates.push(totalRate);
    });
    const maxRate = Math.max(...rates);
    const maxRateIdx = rates.findIndex((v) => v === maxRate);
    console.log(reminders, rates, reminders[maxRateIdx]);
    return reminders[maxRateIdx];
  }, [account.reminders, id, mileage]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const startEdit = () => {
    setMileageEditable(true);
    if (inputRef?.current) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  };
  const handleMileageBlur = () => {
    setMileageText(mileage);
    setMileageEditable(false);
  };
  const updateMileage = async () => {
    setMileageEditable(false);
    if (!mileageText || mileageText <= mileage) {
      setMileageText(mileage);
      enqueueSnackbar("کیلومتر نمیتواند کمتر از قبل شود.", {
        variant: "error",
      });
      return;
    }
    setMileageLoading(true);
    const { error } = await carService.updateMileage(id, mileageText);
    setMileageLoading(false);
    if (error) {
      handleError(error);
      return;
    }
    onRefresh();
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar
            //   TODO: remove this
            src={`${config.get("endpoint")}${brand.logo}`}
            aria-label="Brand Logo"
            className={classes.avatar}
          >
            {brand.name[0]}
          </Avatar>
        }
        title={title}
        subheader={`${brand.name} ${carClass.name}`}
        action={
          <Tooltip title="ایجاد درخواست">
            <IconButton onClick={onRequest}>
              <RequestIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <div className={classes.mediaWrapper}>
        <CardMedia
          className={classes.media}
          //   TODO: remove this
          image={config.get("endpoint") + (image || carClass.image)}
        />
        {/* <div className="overlay">
          <Button className="px-4 py-1" variant="outlined" color="primary">
            مشخصات
          </Button>
        </div> */}
      </div>
      <CardContent className={classes.content}>
        <div className="text-center">
          <TextField
            label="کیلومتر فعلی"
            fullWidth
            inputRef={inputRef}
            value={mileageText}
            inputProps={{ min: mileage, className: "text-center" }}
            onChange={(e) => {
              setMileageText(e.target.value);
            }}
            onBlur={handleMileageBlur}
            disabled={!mileageEditable}
            variant="outlined"
            InputProps={{
              inputComponent: MileageInput,
              startAdornment: (
                <InputAdornment position="start">
                  <MileageIcon fontSize="large" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {mileageLoading ? (
                    <span className="p-1">
                      <CircularProgress size={32} />
                    </span>
                  ) : (
                    <IconButton
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={mileageEditable ? updateMileage : startEdit}
                    >
                      {mileageEditable ? <SaveIcon /> : <EditIcon />}
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
        {Boolean(reminder) && (
          <ReminderProgress
            {...{
              date: reminder.date,
              initialDate: reminder.initialDate,
              mileage: reminder.mileage,
              currentMileage: mileage,
              initialMileage: reminder.initialMileage,
              service: reminder.service,
              subService: reminder.subService,
              description: reminder.description,
            }}
          />
        )}
      </CardContent>
      <CardActions className={classes.footer} disableSpacing>
        <IconButton onClick={onItemInfo} aria-label="items info">
          <ItemsInfoIcon />
        </IconButton>
        <IconButton onClick={onEdit} aria-label="edit">
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete} aria-label="delete">
          <DeleteIcon />
        </IconButton>
        {!!description && (
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
      </CardActions>
      {!!description && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
}

function calcRate(last, first, now) {
  if (!last || now > last) return 0;
  return (now - first) / (last - first);
}
