import React, { useState, useMemo } from "react";
import { useSnackbar } from "notistack";
import Loading from "../../../components/loading";
import {
  Typography,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import { FTextField, FAutocomplete } from "../../../components/form-elements";
import requestService from "../../../services/request.service";
import carService from "../../../services/car.service";
import customerService from "../../../services/customer.service";
import DialogTitle from "../../../components/dialog-title";

const formSchema = Yup.object().shape({
  car: Yup.object().shape({
    title: yupSchema.title,
    brand: yupSchema.brand,
    carClass: yupSchema.carClass,
    year: yupSchema.year,
    chassisNumber: yupSchema.chassisNumber,
    mileage: yupSchema.mileage(),
    color: yupSchema.color,
    description: yupSchema.description,
    code: yupSchema.armaghanCode,
  }),
  customer: Yup.object().shape({
    firstName: yupSchema.firstName,
    lastName: yupSchema.lastName,
    phoneNumber: yupSchema.phoneNumber,
    code: yupSchema.customerCode,
  }),
  description: yupSchema.description,
});
export default function FormModalContent({ close, data }) {
  const [waiting, setWaiting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id } = data;
  const initialValues = useMemo(() => {
    const { car, customer } = JSON.parse(JSON.stringify(data));
    delete car.owner;
    return {
      car: {
        ...car,
        brand: car.brand.id,
        carClass: car.carClass.id,
      },
      // customer: customer.account,
      customer: {
        ...customer.account,
        group: customer.account.group?.id,
      },
      description: "",
    };
  }, [data]);

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "RequestNotFound":
        message = "درخواست مورد نظر یافت نشد";
        variant = "error";
        break;
      case "NotAcceptable":
        message =
          "این درخواس قابل پذیرش نمی‌باشد. ممکن است قبلا پذیرش شده باشد.";
        variant = "error";
        break;
      case "AccessDenied":
        message = "این درخواست توسط شعبه دیگری ثبت شده است.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };

  const handleSubmit = async (model) => {
    setWaiting(true);
    const { error } = await requestService.reception(id, model);
    setWaiting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <Loading isLoading={waiting} overlay>
      <DialogTitle onClose={() => close()}>پذیرش خودرو</DialogTitle>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">مشخصات خودرو</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField name="car.title" label="عنوان" disabled />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FAutocomplete
                    name="car.brand"
                    backend={carService.brandsAC}
                    valueSelector="value"
                    labelSelector="title"
                    label="برند"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FAutocomplete
                    name="car.carClass"
                    fast={false}
                    backend={
                      values.car.brand
                        ? carService.carClassAC + values.car.brand
                        : undefined
                    }
                    disabled={!values.car.brand}
                    valueSelector="value"
                    labelSelector="title"
                    label="مدل"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField type="number" name="car.year" label="سال" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField
                    type="number"
                    name="car.mileage"
                    label="کیلومتر"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField name="car.color" label="رنگ" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField name="car.code" label="کد ارمغان" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField name="car.chassisNumber" label="شماره شاسی" />
                </Grid>
                <Grid item xs={12}>
                  <FTextField
                    name="car.description"
                    label="توضیحات"
                    multiline
                    rows={4}
                    disabled
                  />
                </Grid>
              </Grid>

              <Divider className="my-2" />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">مشخصات مشتری</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField name="customer.firstName" label="نام" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField name="customer.lastName" label="نام خانوادگی" />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FTextField
                    name="customer.phoneNumber"
                    label="شماره تلفن"
                    type="phone"
                    className="code-text-field"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FTextField name="customer.code" label="کد مشتری" />
                </Grid>
                <Grid item xs={12}>
                  <FAutocomplete
                    name="customer.group"
                    backend={customerService.customerGroupsAC}
                    valueSelector="value"
                    labelSelector="title"
                    label="گروه مشتریان"
                  />
                </Grid>
              </Grid>

              <Divider className="my-2" />

              <FTextField
                name="description"
                label="توضیحات پذیرش"
                multiline
                rows={4}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={close} color="primary" disabled={isSubmitting}>
                لغو
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                پذیرش
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Loading>
  );
}
