import React from "react";
import DefCaptcha from "../../captcha";
import { getIn } from "formik";

function Captcha(props) {
  const {
    form: { errors, setFieldValue = () => {}, submitCount },
    field: { name, value },
    onChange,
    componentRef,
    ...other
  } = props;

  const errorText = getIn(errors, name);
  const hasError = Boolean(errorText && submitCount);

  const handleChange = (value) => {
    setFieldValue(name, value);
    if (onChange) onChange(value);
  };

  return (
    <DefCaptcha
      ref={componentRef}
      error={hasError}
      value={value}
      helperText={hasError ? errorText : ""}
      {...other}
      onChange={handleChange}
    />
  );
}

Captcha.defaultProps = {
  form: {},
  field: {},
};
export default Captcha;
