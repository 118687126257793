export const Role = {
  Admin: "Admin",
  Branch: "Branch",
  Coordinator: "Coordinator",
  Customer: "Customer",
};
export const Status = {
  Coordination: "Coordination",
  Coordinated: "Coordinated",
  Payment: "Payment",
  SetAppointment: "SetAppointment",
  WaitingForDate: "WaitingForDate",
  Expired: "Expired",
  Accepted: "Accepted",
  Done: "Done",
  Rejected: "Rejected",
};
export const StatusMap = {
  Coordination: "هماهنگی",
  Coordinated: "برآورد شده",
  Payment: "پرداخت",
  SetAppointment: "انتخاب زمان مراجعه",
  WaitingForDate: "در انتظار مراجعه",
  Expired: "منقضی شده",
  Accepted: "پذیرش شده",
  Done: "انجام شده",
  Rejected: "رد شده",
};

export const DiscountTypes = {
  CustomerGroup: "CustomerGroup",
  Service: "Service",
  Birthday: "Birthday",
  Code: "Code",
  Manual: "Manual",
};
