import httpService from "./http.service";

class RequestService {
  getRequestsUrl = "/requests";

  createRequest = (model) => {
    const data = new FormData();
    for (const [key, value] of Object.entries(model)) {
      if (value instanceof Array) {
        value.forEach((v) => {
          data.append(key + "[]", v);
        });
      } else {
        data.append(key, value);
      }
    }
    return httpService.form("/requests", data, "createRequest");
  };
  branchCreate = (model) => {
    return httpService.post("/requests", model);
  };
  coordinatorCreate = (model) => {
    return httpService.post("/requests", model);
  };
  coordinatorAnswer = (id, model) => {
    return httpService.put("/requests/answer/" + id, model);
  };
  validatePayment = (token) => {
    return httpService.get(`/requests/verify-payment/${token}`);
  };
  setAppointment = (id, appointment) => {
    return httpService.put("/requests/set-appointment/" + id, { appointment });
  };
  payRequest = (id) => {
    return httpService.get("/requests/pay/" + id);
  };
  getRequest = (code) => {
    return httpService.get("/requests/invoice/" + code);
  };
  expireRequest = (id) => {
    return httpService.put("/requests/expire/" + id);
  };
  reception = (id, model) => {
    return httpService.put("/requests/reception/" + id, model);
  };
  clearance = (id, model) => {
    return httpService.put("/requests/clearance/" + id, model);
  };
  deleteRequest = (id) => {
    return httpService.delete("/requests/" + id);
  };
}
const requestService = new RequestService();
export default requestService;
