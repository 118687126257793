import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListSubheader,
} from "@material-ui/core";
import ButtonPopover from "../button-popover";

const useStyles = makeStyles((theme) => ({
  listItem: {
    minWidth: 230,
  },
}));

export default function SelectPopover({
  icon: Icon,
  items = [],
  itemsChange,
  title,
}) {
  const classes = useStyles();

  const handleToggle = (title) => () => {
    const newItems = [...items];
    const item = newItems.find((i) => i.title === title);
    item.hidden = !item.hidden;
    itemsChange(newItems);
  };

  return (
    <ButtonPopover icon={Icon}>
      <List
        subheader={<ListSubheader component="div">{title}</ListSubheader>}
        className={classes.root}
      >
        {items.map((item) => (
          <ListItem
            className={classes.listItem}
            key={item.title}
            dense
            button
            onClick={handleToggle(item.title)}
          >
            <ListItemIcon>
              <Checkbox
                color="primary"
                edge="start"
                checked={!item.hidden}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </ButtonPopover>
  );
}
