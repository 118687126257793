import React from "react";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import yupSchema from "../../../../constants/schema";
import carItemService from "../../../../services/car-item.service";
import DialogFormBuilder from "../../../../components/dialog-form-builder";

const initialValues = {
  name: "",
  image: undefined,
};
const schema = Yup.object().shape({
  name: yupSchema.name,
  image: yupSchema.image,
});
const form = [
  {
    _type: "textField",
    name: "name",
    label: "نام",
    autoFocus: true,
  },
  {
    _type: "filePicker",
    name: "image",
    label: "عکس",
    isImage: true,
  },
];
export default function FormModalContent({ close, data }) {
  const newData = { ...data };
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "NameTaken":
        message = "این نام تکراری است.";
        variant = "error";
        break;
      case "CategoryNotFound":
        message = "دسته بندی مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async (model, { setSubmitting }) => {
    let op;
    if (data && data.id) {
      op = await carItemService.editCategory(data.id, model);
    } else {
      op = await carItemService.createCategory(model);
    }
    const { error } = op;
    setSubmitting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <DialogFormBuilder
      {...{
        close,
        name: "دسته بندی آیتم",
        data: newData,
        schema,
        initialValues,
        handleSubmit,
        form,
      }}
    />
  );
}
