import React, { useState, useMemo } from "react";
import { useSnackbar } from "notistack";
import Loading from "../../../components/loading";
import DialogTitle from "../../../components/dialog-title";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import { FTextField, FAutocomplete } from "../../../components/form-elements";
import carService from "../../../services/car.service";

const emptyValues = {
  title: "",
  brand: "",
  carClass: "",
  year: "",
  chassisNumber: "",
  mileage: "",
  color: "",
  description: "",
  code: "",
  owner: "",
};

export default function FormModalContent({ close, data }) {
  const [waiting, setWaiting] = useState(false);
  const { id, ...initialValues } = data || {};
  const { enqueueSnackbar } = useSnackbar();
  if (data?.owner) {
    initialValues.owner = data.owner.code || data.owner.id;
  }

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "ChassisNumberTaken":
        message = "این شماره شاسی ثبت شده است.";
        variant = "error";
        break;
      case "DuplicateTitle":
        message = "این عنوان تکراری است.";
        variant = "error";
        break;
      case "CustomerNotFound":
        message = "مشتری مورد نظر یافت نشد.";
        variant = "error";
        break;
      case "CarNotFound":
        message = "خودرو مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };

  const formSchema = useMemo(() => {
    return Yup.object().shape({
      // title: yupSchema.optionalTitle,
      brand: yupSchema.brand,
      carClass: yupSchema.carClass,
      year: yupSchema.year,
      chassisNumber: yupSchema.chassisNumber,
      mileage: yupSchema.mileage(),
      color: yupSchema.color,
      description: yupSchema.description,
      code: yupSchema.armaghanCode,
      owner: yupSchema.owner,
    });
  }, []);
  const handleSubmit = async (model) => {
    setWaiting(true);
    let op;
    if (id) {
      op = await carService.editCar(id, model);
    } else {
      op = await carService.createCar(model);
    }
    const { error } = op;
    setWaiting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <Loading isLoading={waiting} overlay>
      <DialogTitle onClose={() => close()}>
        {id ? "ویرایش خودرو" : "ایجاد خودرو"}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={id ? initialValues : emptyValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <DialogContent>
              {/* <FTextField name="title" label="عنوان" autoFocus /> */}
              <FTextField name="owner" label="مالک (موبایل یا کد)" autoFocus />
              <FAutocomplete
                name="brand"
                backend={carService.brandsAC}
                valueSelector="value"
                labelSelector="title"
                label="برند"
              />
              <FAutocomplete
                name="carClass"
                fast={false}
                backend={
                  values.brand
                    ? carService.carClassAC + values.brand
                    : undefined
                }
                disabled={!values.brand}
                valueSelector="value"
                labelSelector="title"
                label="مدل"
              />
              <FTextField type="number" name="year" label="سال" />
              <FTextField type="mileage" name="mileage" label="کیلومتر" />
              <FTextField name="color" label="رنگ" />
              <FTextField name="code" label="کد ارمغان" />
              <FTextField name="chassisNumber" label="شماره شاسی" />
              <FTextField
                name="description"
                label="توضیحات"
                multiline
                rows={4}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={close} color="primary" disabled={isSubmitting}>
                لغو
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                {id ? "ذخیره" : "ایجاد"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Loading>
  );
}
