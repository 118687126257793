import React from "react";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../components/data-table";
import FormModalContent from "./form";
import coordinatorService from "../../../services/coordinator.service";
import ConfirmModalContent from "../../../components/confirm-modal-content";

const headerCells = [
  {
    field: "username",
    title: "نام کاربری",
    mainField: true,
  },
];
const defaultKeys = [
  {
    title: "نام کاربری",
    field: "username",
    active: true,
  },
];

export default function CoordinatorsPage() {
  const deleteItem = (item) => {
    return coordinatorService.deleteCoordinator(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        backend={coordinatorService.getCoordinatorsUrl}
        rowActions={[
          {
            icon: <EditIcon />,
            modalProps: { maxWidth: "sm" },
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            modalProps: { maxWidth: "sm" },
            icon: <DeleteIcon />,
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.username}
                close={props.close}
              />
            ),
          },
        ]}
        actions={[
          {
            icon: <AddIcon />,
            modalProps: { maxWidth: "sm" },
            tooltip: "ایجاد هماهنگ کننده",
            modalContent: (props) => <FormModalContent {...props} />,
          },
        ]}
        searchKeys={defaultKeys}
        title="هماهنگ کننده ها"
      />
    </div>
  );
}
