import React from "react";
import MuiSwitch from "@material-ui/core/Switch";
import invariant from "tiny-warning";

export function fieldToSwitch({
  disabled,
  field,
  form,
  type,
  onBlur,
  ...props
}) {
  if (process.env.NODE_ENV !== "production") {
    invariant(
      type === "checkbox",
      `property type=checkbox is missing from field ${field?.name}, this can caused unexpected behaviour`
    );
  }

  return {
    disabled: disabled ?? form?.isSubmitting,
    onBlur:
      onBlur ??
      function (e) {
        field.onBlur(e ?? field.name);
      },
    ...field,
    ...props,
  };
}

export default function Switch(props) {
  return <MuiSwitch {...fieldToSwitch(props)} />;
}

Switch.displayName = "FormikMaterialUISwitch";
