import React from "react";
import AppPanel from "../../components/app-panel";
import {
  Home as HomeIcon,
  HeadsetMic as CoordinatorsIcon,
  EmojiTransportation as BranchesIcon,
  Build as ServicesIcon,
  People as CustomersIcon,
  GroupAdd as CustomerGroupsIcon,
  DirectionsCar as CarsIcon,
  Label as CarBrandsIcon,
  ViewAgenda as CarItemIcon,
  ViewCarousel as CarItemCategoryIcon,
} from "@material-ui/icons";

const menuItems = [
  {
    title: "گزارشات",
    route: "/panel/admin/reports",
    icon: <HomeIcon />,
  },
  {
    title: "هماهنگ کننده ها",
    route: "/panel/admin/coordinators",
    icon: <CoordinatorsIcon />,
  },
  {
    title: "خدمات",
    route: "/panel/admin/services",
    icon: <ServicesIcon />,
  },
  {
    title: "شعب",
    route: "/panel/admin/branches",
    icon: <BranchesIcon />,
  },
  { divider: true },
  {
    title: "مشتریان",
    route: "/panel/admin/customers",
    icon: <CustomersIcon />,
  },
  {
    title: "گروه مشتریان",
    route: "/panel/admin/customer-groups",
    icon: <CustomerGroupsIcon />,
  },
  { divider: true },
  {
    title: "برند خودرو",
    route: "/panel/admin/car-brands",
    icon: <CarBrandsIcon />,
  },
  {
    title: "خودرو ها",
    route: "/panel/admin/car-models",
    icon: <CarsIcon />,
  },
  {
    title: "خودرو مشتریان",
    route: "/panel/admin/cars",
    icon: <CarsIcon />,
  },
  { divider: true },
  {
    title: "دسته بندی آیتم",
    route: "/panel/admin/car-item-categories",
    icon: <CarItemCategoryIcon />,
  },
  {
    title: "آیتم ها",
    route: "/panel/admin/car-items",
    icon: <CarItemIcon />,
  },
  {
    title: "بلاگ ها",
    route: "/panel/admin/blogs",
    icon: <CarItemIcon />,
  },
];

const AdminPanel = ({ children }) => {
  return <AppPanel menuItems={menuItems}>{children}</AppPanel>;
};

export default AdminPanel;
