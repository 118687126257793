import React from "react";
import * as Yup from "yup";
import { getIn } from "formik";
import { FormHelperText } from "@material-ui/core";
import yupSchema from "../../../constants/schema";
import DialogFormBuilder from "../../../components/dialog-form-builder";
import SelectBranchAppointment from "../../../components/select-branch-appointment";
import serviceService from "../../../services/service.service";
import subServiceService from "../../../services/sub-service.service";
import requestService from "../../../services/request.service";
import { useErrorHandler } from "../../../hooks";

const initialValues = {
  carCode: "",
  service: undefined,
  message: "",
  subServices: [],
  appointment: undefined,
};
const schema = Yup.object().shape({
  carCode: yupSchema.carCode,
  message: yupSchema.message,
  service: yupSchema.service,
  subServices: yupSchema.subServices,
  appointment: yupSchema.appointment,
});
const form = ({ values, setFieldValue, errors, submitCount }) => [
  {
    _type: "textField",
    name: "carCode",
    label: "کد ارمغان",
    autoFocus: true,
  },
  {
    _type: "autocomplete",
    name: "service",
    backend: serviceService.servicesAC,
    valueSelector: "id",
    labelSelector: "title",
    label: "خدمت",
    disableClearable: true,
    onChange: (o) => {
      setFieldValue("subServices", []);
    },
  },
  {
    _type: "autocomplete",
    name: "subServices",
    disabled: !values.service,
    backend: values.service
      ? subServiceService.subServicesAC + values.service
      : undefined,
    valueSelector: "value",
    labelSelector: "title",
    label: "زیر خدمت ها",
    multiple: true,
    fast: false,
  },
  {
    _type: "textField",
    name: "message",
    label: "پیام",
    multiline: true,
    rows: 4,
  },
  {
    _type: "customContent",
    content: () => {
      const errorText = getIn(errors, "appointment");
      const hasError = Boolean(errorText && submitCount);
      return (
        <>
          <SelectBranchAppointment
            onChange={(val) => setFieldValue("appointment", val)}
            serviceId={values.service}
            hideResult
            error={hasError}
          />

          {hasError && <FormHelperText error>{errorText}</FormHelperText>}
        </>
      );
    },
  },
];
export default function FormModalContent({ close }) {
  const errorHandler = useErrorHandler();
  const handleSubmit = async (model, { setSubmitting }) => {
    const { error } = await requestService.coordinatorCreate(model);
    setSubmitting(false);
    if (error) {
      errorHandler(error, [
        { case: "ServiceNotFound", message: "خدمت مورد نظر یافت نشد." },
        {
          case: "CarNotFound",
          message: "خودرو مورد نظر یافت نشد. کد ارمغان اشتباه وارد شده.",
        },
        {
          case: "AppointmentNotFound",
          message:
            "زمان مورد نظر یافت نشد. ممکن است کسی پیش از شما آن را برای خود ثبت کرده باشد.",
        },
      ]);
      return;
    }
    close(true);
  };
  return (
    <DialogFormBuilder
      {...{
        close,
        name: "درخواست",
        schema,
        initialValues,
        handleSubmit,
        form,
      }}
    />
  );
}
