import React from "react";
import { Typography } from "@material-ui/core";

export default function RequestFailure() {
  return (
    <div>
      <Typography>مشکلی پیش آمده. بعدا دوباره تلاش کنید.</Typography>
    </div>
  );
}
