import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import carService from "../../../services/car.service";
import requestService from "../../../services/request.service";
import serviceService from "../../../services/service.service";
import subServiceService from "../../../services/sub-service.service";
import DialogFormBuilder from "../../../components/dialog-form-builder";
import SelectBranchAppointment from "../../../components/select-branch-appointment";
import { currencyFormat } from "../../../helpers";
import { Typography, Divider } from "@material-ui/core";
import { useErrorHandler } from "../../../hooks";
import { useHistory } from "react-router-dom";

const _initialValues = {
  car: undefined,
  service: undefined,
  subServices: [],
  message: "",
  images: [],
};
const PriceText = ({ label, value }) => {
  return (
    <Typography className="d-flex mb-1">
      <span className="me-1">{label}: </span>
      <span>{currencyFormat(value)}</span>
    </Typography>
  );
};
export default function FormModalContent({ close, data = {} }) {
  const [service, setService] = useState();
  const [subServices, setSubServices] = useState([]);
  const [car, setCar] = useState();
  const history = useHistory();
  const handleError = useErrorHandler();

  const initialValues = useMemo(() => {
    return { ..._initialValues, ...data };
  }, [data]);

  const schema = useMemo(() => {
    const obj = {
      car: yupSchema.car,
      service: yupSchema.service,
      subServices: yupSchema.subServices,
    };
    if (service?.needsCoordination) {
      obj.message = yupSchema.messageRequired;
      obj.images = yupSchema.images;
    }
    return Yup.object().shape(obj);
  }, [service]);
  const form = useMemo(() => {
    return ({ setFieldValue, values }) => [
      {
        _type: "autocomplete",
        name: "car",
        backend: carService.carAC,
        valueSelector: "id",
        labelSelector: "title",
        label: "خودرو",
        onChange: (o) => setCar(o),
        onSetOptions: (options) => {
          if (!car && values.car) {
            const carObj = options.find((c) => c.id === values.car);

            if (carObj) setCar(carObj);
          }
        },
      },
      {
        _type: "autocomplete",
        name: "service",
        backend: serviceService.servicesAC,
        valueSelector: "id",
        labelSelector: "title",
        label: "خدمت",
        disableClearable: true,
        onChange: (o) => {
          setFieldValue("subServices", []);
          setSubServices([]);
          setService(o);
        },
      },
      {
        _type: "autocomplete",
        name: "subServices",
        backend: service
          ? subServiceService.subServicesAC + service.id
          : undefined,
        disabled: !service,
        valueSelector: "value",
        labelSelector: "title",
        label: "زیر خدمت ها",
        multiple: true,
        fast: false,
        onChange: (o) => setSubServices(o),
      },
      {
        _type: "textField",
        name: "message",
        label: "پیام",
        multiline: true,
        rows: 4,
        _hidden: !service?.needsCoordination,
      },
      {
        _type: "filePicker",
        name: "images",
        label: "تصاویر",
        isImage: true,
        multiple: true,
        _hidden: !service?.needsCoordination,
      },
      {
        _type: "customContent",
        content: () => {
          if (!service || !car) return null;
          const servicePrice = service.prices.find(
            (p) => p.type === car.type
          )?.price;
          let totalPrice = servicePrice || 0;
          return (
            <div className="mt-2">
              {servicePrice ? (
                <PriceText
                  label={"هزینه " + service.title}
                  value={servicePrice}
                />
              ) : null}
              {subServices.map((ss) => {
                const price = ss.prices.find((p) => p.type === car.type)?.price;
                if (!price) return null;
                totalPrice += price;
                return <PriceText label={"هزینه " + ss.title} value={price} />;
              })}
              {totalPrice ? (
                <>
                  <Divider className="mt-2 mb-2" />
                  <PriceText label={"هزینه کل"} value={totalPrice} />
                  {service?.needsAppointment && (
                    <PriceText
                      label={"مبلغ پیش پرداخت"}
                      value={(totalPrice * service.prePayment) / 100}
                    />
                  )}
                </>
              ) : null}
              {service?.needsAppointment ? (
                <SelectBranchAppointment serviceId={service.id} dialog />
              ) : null}
            </div>
          );
        },
      },
    ];
  }, [service, subServices, car]);

  const handleSubmit = async (model, { setSubmitting }) => {
    if (!service?.needsCoordination && !service?.needsAppointment) {
      return close();
    }
    const { error, data: resData } = await requestService.createRequest({
      redirect: window.location.origin + "/invoice/request",
      ...model,
    });
    setSubmitting(false);
    if (error) {
      handleError(error, [
        { case: "ServiceNotFound", message: "خدمت مورد نظر یافت نشد." },
        { case: "CarNotFound", message: "خودرو مورد نظر یافت نشد." },
        {
          case: "LimitReached",
          // ASK: 5 requests per month
          message:
            "شما در 30 روز گذشته بیش از 5 درخواست بدون پرداخت ثبت کرده‌اید. لطفا نسبت به پرداخت آنها اقدام کنید.",
        },
      ]);
      return;
    }
    if (!service.needsCoordination) {
      if (typeof resData === "string") {
        window.location.href = resData;
      } else {
        history.push("/panel/requests");
      }
      return;
    }
    close(true);
  };
  const submitLabel = service?.needsCoordination
    ? undefined
    : service?.needsAppointment
    ? "پرداخت"
    : "متوجه شدم";
  return (
    <DialogFormBuilder
      {...{
        close,
        name: "درخواست",
        schema,
        initialValues,
        handleSubmit,
        form,
        submitLabel,
      }}
    />
  );
}
