import React, { useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from "@material-ui/core";
import DialogTitle from "../dialog-title";

export default function ConfirmModalContent({
  title,
  description,
  confirmText,
  close,
  onConfirm,
}) {
  const [value, setValue] = useState("");
  const handleConfirm = async () => {
    if (confirmText && value !== confirmText) return;
    await onConfirm();
    close(true);
  };
  return (
    <div
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          handleConfirm();
        }
      }}
    >
      <DialogTitle onClose={() => close()}>
        {title || "آیا اطمینان دارید؟"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description ||
            `برای تایید «${confirmText}» را در فیلد زیر وارد کنید.`}
        </DialogContentText>
        {confirmText ? (
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            fullWidth
            autoFocus
            placeholder={"برای تایید نام را وارد کنید."}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          لغو
        </Button>
        <Button
          disabled={Boolean(confirmText && value !== confirmText)}
          onClick={handleConfirm}
          color="primary"
        >
          حذف
        </Button>
      </DialogActions>
    </div>
  );
}
