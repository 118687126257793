import React, { useMemo } from "react";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import requestService from "../../../services/request.service";
import serviceService from "../../../services/service.service";
import subServiceService from "../../../services/sub-service.service";
import DialogFormBuilder from "../../../components/dialog-form-builder";
import { Paper, Typography, Grid, Divider } from "@material-ui/core";
import { currencyFormat } from "../../../helpers";
import DiscountComponent from "./discount";

const schema = Yup.object().shape({
  description: yupSchema.description,
  doneList: yupSchema.doneList,
  reminders: yupSchema.reminders,
  discounts: yupSchema.discounts,
});

const GridItemText = ({ title, value }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item>
        <Typography>{currencyFormat(value, { rials: true })}</Typography>
      </Grid>
    </Grid>
  );
};

export default function FormModalContent({ close, data, extra }) {
  const { enqueueSnackbar } = useSnackbar();
  const { id, branch, invoice } = data;

  const initialValues = useMemo(
    () => ({
      description: branch.description || "",
      doneList: (invoice?.items || []).map(({ item, amount }) => ({
        title: item,
        price: amount,
      })),
      discounts: [],
      reminders: [],
    }),
    [invoice, branch.description]
  );

  const form = useMemo(() => {
    return ({ values, setFieldValue }) => [
      {
        _type: "textField",
        name: "description",
        label: "توضیحات",
        multiline: true,
        rows: 4,
      },
      {
        _type: "array",
        name: "doneList",
        label: "لیست",
        minLength: 0,
        emptyValue: {
          title: "",
          price: 0,
        },
        items: [
          {
            _type: "textField",
            name: "title",
            label: "عنوان",
          },
          {
            _type: "textField",
            name: "price",
            label: "مبلغ",
            type: "currency",
          },
        ],
      },
      {
        _type: "customContent",
        content: () => {
          console.log(values);
          const total = values.doneList.reduce(
            (p, { price }) => p + (price || 0),
            0
          );
          const prePayment = invoice?.prePayment || 0;
          let discountPercentage = 0;
          let discount = 0;
          values.discounts.forEach((d) => {
            if (d.amount) {
              discount += d.amount;
            } else {
              discountPercentage += d.percentage || 0;
            }
          });
          discount += (discountPercentage * total) / 100;
          return (
            <>
              <Paper variant="outlined" className="p-2 mt-2">
                <GridItemText title="مجموع" value={total} />
                <GridItemText title="پیش پرداخت" value={prePayment} />
                <GridItemText title="تخفیف" value={discount} />
                <Divider className="my-2" />
                <GridItemText
                  title="قابل پرداخت"
                  value={total - prePayment - discount}
                />
              </Paper>
              <Paper variant="outlined" className="p-2 mt-2">
                <DiscountComponent
                  extra={extra}
                  {...data}
                  onChange={(value) => setFieldValue("discounts", value)}
                  total={total}
                />
              </Paper>
            </>
          );
        },
      },
      {
        _type: "array",
        name: "reminders",
        label: "یادآوری",
        minLength: 0,
        emptyValue: {
          date: 0,
          mileage: 0,
          service: data.service,
          subService: data.subServices?.length ? data.subServices[0] : "",
          description: "",
        },
        items: [
          {
            _type: "textField",
            name: "date",
            label: "مراجعه بعد از (روز)",
            type: "number",
            inputProps: {
              min: 0,
            },
          },
          {
            _type: "textField",
            name: "mileage",
            label: "مراجعه بعد از (کیلومتر)",
            type: "mileage",
          },
          (idx) => ({
            _type: "autocomplete",
            name: "service",
            backend: serviceService.servicesAC,
            valueSelector: "id",
            labelSelector: "title",
            label: "خدمت",
            disableClearable: true,
            onChange: (o) => {
              setFieldValue(`reminders[${idx}].subService`, "");
            },
          }),
          (idx) => {
            const { service } = values.reminders[idx];
            return {
              _type: "autocomplete",
              name: "subService",
              backend: service
                ? subServiceService.subServicesAC + service
                : undefined,
              disabled: !service,
              valueSelector: "value",
              labelSelector: "title",
              label: "زیر خدمت",
              fast: false,
            };
          },
          {
            _type: "textField",
            name: "description",
            label: "توضیحات",
            multiline: true,
            rows: 4,
          },
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "RequestNotFound":
        message = "درخواست مورد نظر یافت نشد";
        variant = "error";
        break;
      case "NotClearable":
        message =
          "این درخواس قابل ترخیص نمی‌باشد. ممکن است قبلا ترخیص شده باشد.";
        variant = "error";
        break;
      case "AccessDenied":
        message = "این درخواست توسط شعبه دیگری ثبت شده است.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };

  const handleSubmit = async (model, { setSubmitting }) => {
    const { error } = await requestService.clearance(id, model);
    setSubmitting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <DialogFormBuilder
      {...{
        close,
        title: "ترخیص",
        submitLabel: "ترخیص",
        schema,
        initialValues,
        handleSubmit,
        form,
      }}
    />
  );
}
