import React from "react";
import moment from "moment-jalaali";
import { Grid, Typography } from "@material-ui/core";
import { currencyFormat } from "../../../helpers";

function GridItemText({ value, label, valueClassName }) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography>
        {label}: <span className={valueClassName}> {value} </span>
      </Typography>
    </Grid>
  );
}
export default function CoordinatorSection({ coordinator, title }) {
  if (!coordinator?.account) return null;
  return (
    <Grid item xs={12}>
      <div className="simple-card">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{coordinator.message}</Typography>
          </Grid>
          <GridItemText
            label="قیمت پیش بینی شده"
            value={currencyFormat(coordinator.predictedPrice)}
          />
          <GridItemText
            label="تاریخ"
            value={moment(coordinator.date).format("jYYYY/jMM/jDD HH:mm")}
          />
          <GridItemText
            label="مبلغ قابل پرداخت"
            value={currencyFormat(coordinator.prePaymentPrice)}
          />
        </Grid>
      </div>
    </Grid>
  );
}
