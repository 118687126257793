import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import reminderService from "../../services/reminder.service";
import carService from "../../services/car.service";
import LoadingComponent from "../loading";
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  TextField,
} from "@material-ui/core";
import moment from "moment-jalaali";
import MileageInput from "../mileage-input";
import { useErrorHandler } from "../../hooks";

function GridItemText({ value, label, valueClassName, sm }) {
  return (
    <Grid item xs={12} sm={sm || 6}>
      <Typography>
        {label}: <span className={valueClassName}> {value} </span>
      </Typography>
    </Grid>
  );
}

function Reminder(props) {
  const [open, setOpen] = useState(true);

  const subService = useMemo(() => {
    if (!props.subService) return;
    return props.service.subServices.find((ss) => ss.id === props.subService);
  }, [props]);

  const gotIt = () => {
    reminderService.seen(props.id);
    setOpen(false);
  };
  const remindLater = () => {
    reminderService.reschedule(props.id);
    setOpen(false);
  };
  if (!open) return null;
  return (
    <Dialog
      open
      disableBackdropClick
      onClose={() => setOpen(false)}
      maxWidth={"sm"}
      fullWidth
      classes={{
        paper: "matte-glass",
      }}
    >
      <DialogTitle>یادآوری</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <GridItemText label="خدمت" value={props.service.title} />
          {subService && (
            <GridItemText label="زیر خدمت" value={subService.title} />
          )}
        </Grid>
        <Grid container spacing={2}>
          <GridItemText label="خودرو" value={props.car.title} />
        </Grid>
        <Typography>{props.description}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={gotIt} color="primary">
          متوجه شدم
        </Button>
        <Button onClick={remindLater} color="primary" variant="contained">
          دوباره یادآوری کن
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function MileageReminder(props) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [mileage, setMileage] = useState(+props.mileage);
  const handleError = useErrorHandler();

  const handleMileageChange = ({ target: { value } }) => {
    setMileage(value);
  };
  const handleBlur = () => {
    console.log(mileage, props.mileage);
    if (mileage < props.mileage) {
      setMileage(props.mileage);
    }
  };
  const later = () => {
    setOpen(false);
  };
  const set = async () => {
    if (loading) return;
    setLoading(true);
    const { error } = await carService.updateMileage(
      props.id,
      mileage < props.mileage ? props.mileage : mileage
    );
    setLoading(false);
    if (error) {
      handleError(error);
      return;
    }
    setOpen(false);
  };
  if (!open) return null;
  return (
    <Dialog
      open
      disableBackdropClick
      onClose={() => setOpen(false)}
      maxWidth={"sm"}
      fullWidth
      classes={{
        paper: "matte-glass",
      }}
    >
      <LoadingComponent isLoading={loading} overlay>
        <DialogTitle>کیلومتر خودرو</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography>
            کیلومتر خودرو "{props.title}" از تاریخ{" "}
            {moment(props.lastMileageUpdate).format("jYYYY/jMM/jDD")} به روز
            رسانی نشده. لطفا مقدار کیلومتر خودرو خود را وارد کنید:
          </Typography>
          <div className="text-center">
            <TextField
              value={mileage}
              inputProps={{ min: props.mileage, className: "text-center" }}
              onChange={handleMileageChange}
              onBlur={handleBlur}
              InputProps={{
                inputComponent: MileageInput,
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={later} color="primary">
            بعدا
          </Button>
          <Button onClick={set} color="primary" variant="contained">
            ثبت
          </Button>
        </DialogActions>
      </LoadingComponent>
    </Dialog>
  );
}

export default function Reminders() {
  const { account } = useSelector((state) => state.auth);
  const mileageUpdates = useMemo(() => {
    // ASK: 30Days
    if (!account) return [];
    const updateTime = 30 * 24 * 60 * 60 * 1000;
    return (account.cars || []).filter(
      (car) => new Date(car.lastMileageUpdate) < Date.now() - updateTime
    );
  }, [account]);
  const reminders = useMemo(() => {
    if (!account) return [];
    return account.reminders.filter((r) => {
      const now = Date.now();
      const hasNotSeen = r.seen !== true;
      const passedRescheduled = r.reschedule
        ? new Date(r.reschedule) <= now
        : false;
      const datePassed = r.date && new Date(r.date) <= now;
      const mileagePassed = r.mileage <= r.car.mileage;

      return hasNotSeen && r.reschedule
        ? passedRescheduled
        : datePassed || mileagePassed;
    });
  }, [account]);

  return (
    <>
      {reminders.map((props) => (
        <Reminder key={props.id} {...props} />
      ))}
      {mileageUpdates.map((props) => (
        <MileageReminder key={props.id} {...props} />
      ))}
    </>
  );
}
