import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { useGlobalStyles } from "../theme";
import { Role } from "../constants/enums";
import LandingContainer from "../pages/landing";

import AdminLoginPage from "../pages/auth/admin";
import AdminForgetPage from "../pages/auth/forget";
import LoginPage from "../pages/auth/login";

import CustomerPanel from "../pages/customer";
import AdminPanel from "../pages/admin";
import CoordinatorPanel from "../pages/coordinator";
import BranchPanel from "../pages/branch";

import Customers from "../pages/_shared/customers/customers";
import CustomerGroups from "../pages/_shared/customers/group";

import AdminReports from "../pages/admin/reports";
import AdminBranches from "../pages/admin/branches";
import AdminCoordinators from "../pages/admin/coordinators";
import AdminServices from "../pages/admin/services";
import AdminCarItemGroups from "../pages/admin/carItems/group";
import AdminCarItems from "../pages/admin/carItems/item";
import AdminCarBrands from "../pages/admin/cars/brands";
import AdminCarModels from "../pages/admin/cars/models";
import AdminCars from "../pages/_shared/cars";
import AdminBlogs from "../pages/admin/blogs";
import AdminProfile from "../pages/admin/profile";

import CoordinatorRequests from "../pages/coordinator/requests";

import BranchAppointments from "../pages/branch/appointments";
import BranchRequests from "../pages/branch/requests";

import CustomerCars from "../pages/customer/my-cars";
import CustomerProfile from "../pages/customer/profile";
import CustomerRequests from "../pages/customer/requests";

import HomePage from "../pages/landing/home";
import AboutPage from "../pages/landing/about";
import ContactPage from "../pages/landing/contact";
import BlogPage from "../pages/landing/blog";

import InvoicePage from "../pages/invoice";
import PaymentPage from "../pages/payment";
import UpdateAccount from "../pages/auth/update-account";

function Landing() {
  return (
    <LandingContainer>
      <Switch>
        <Route path="/home">
          <HomePage />
        </Route>
        <Route path="/about-us">
          <AboutPage />
        </Route>
        <Route path="/contact-us">
          <ContactPage />
        </Route>
        <Route path="/blog/:title">
          <BlogPage />
        </Route>
        <Redirect path="/panel/*" to="/login" />
        <Redirect to="/home" />
      </Switch>
    </LandingContainer>
  );
}
function Panel({ account }) {
  switch (account.role) {
    case Role.Admin:
      return (
        <AdminPanel>
          <Switch>
            <Route path="/panel/admin/reports">
              <AdminReports />
            </Route>
            <Route path="/panel/admin/branches">
              <AdminBranches />
            </Route>
            <Route path="/panel/admin/coordinators">
              <AdminCoordinators />
            </Route>
            <Route path="/panel/admin/services">
              <AdminServices />
            </Route>
            <Route path="/panel/admin/customers">
              <Customers />
            </Route>
            <Route path="/panel/admin/customer-groups">
              <CustomerGroups />
            </Route>
            <Route path="/panel/admin/car-brands">
              <AdminCarBrands />
            </Route>
            <Route path="/panel/admin/car-models">
              <AdminCarModels />
            </Route>
            <Route path="/panel/admin/cars">
              <AdminCars />
            </Route>
            <Route path="/panel/admin/car-item-categories">
              <AdminCarItemGroups />
            </Route>
            <Route path="/panel/admin/car-items">
              <AdminCarItems />
            </Route>
            <Route path="/panel/admin/blogs">
              <AdminBlogs />
            </Route>
            <Route path="/panel/admin/profile">
              <AdminProfile />
            </Route>
            <Redirect to="/panel/admin/reports" />
          </Switch>
        </AdminPanel>
      );
    case Role.Coordinator:
      return (
        <CoordinatorPanel>
          <Switch>
            <Route path="/panel/coordinator/requests">
              <CoordinatorRequests />
            </Route>
            <Route path="/panel/coordinator/customers">
              <Customers />
            </Route>
            <Route path="/panel/coordinator/cars">
              <AdminCars />
            </Route>
            <Redirect to="/panel/coordinator/requests" />
          </Switch>
        </CoordinatorPanel>
      );
    case Role.Branch:
      return (
        <BranchPanel>
          <Switch>
            {account.hasAppointment && (
              <Route path="/panel/branch/appointments">
                <BranchAppointments />
              </Route>
            )}
            <Route path="/panel/branch/customers">
              <Customers />
            </Route>
            <Route path="/panel/branch/cars">
              <AdminCars />
            </Route>
            <Route path="/panel/branch/requests">
              <BranchRequests />
            </Route>
            <Redirect to="/panel/branch/requests" />
          </Switch>
        </BranchPanel>
      );
    default:
      return (
        <CustomerPanel>
          <Switch>
            <Route path="/panel/my-cars">
              <CustomerCars />
            </Route>
            <Route path="/panel/requests">
              <CustomerRequests />
            </Route>
            <Route path="/panel/profile">
              <CustomerProfile />
            </Route>
            <Redirect to="/panel/my-cars" />
          </Switch>
        </CustomerPanel>
      );
  }
}

export default function Navigation() {
  const { account } = useSelector((state) => state.auth);

  useGlobalStyles();

  if (!account) {
    return (
      <Router>
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/admin/login">
            <AdminLoginPage />
          </Route>
          <Route exact path="/admin/forget">
            <AdminForgetPage />
          </Route>
          <Route path="*">
            <Landing />
          </Route>
        </Switch>
      </Router>
    );
  }
  if (account.role === Role.Customer && !account.firstName) {
    return <UpdateAccount />;
  }
  return (
    <Router>
      <Switch>
        <Route path="/panel">
          <Panel account={account} />
        </Route>
        <Route path="/invoice/:field/:code">
          <InvoicePage />
        </Route>
        <Route path="/payment/:field">
          <PaymentPage />
        </Route>
        <Route path="*">
          <Landing />
        </Route>
      </Switch>
    </Router>
  );
}
