import React, { useState } from "react";
import XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ButtonPopover from "../button-popover";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ImportIcon from "@material-ui/icons/Publish";
import ExportIcon from "@material-ui/icons/GetApp";
import ImportConfirmModal from "./import-confirm-modal";
import httpService from "../../services/http.service";
import { useErrorHandler } from "../../hooks";

function duplicateChecker(duplicate, validatedData, model) {
  if (!duplicate) return;
  const fn =
    typeof duplicate === "function"
      ? duplicate
      : (a, b) =>
          duplicate.find(
            (field) => typeof a[field] !== "undefined" && a[field] === b[field]
          );

  for (let index = 0; index < validatedData.length; index++) {
    const field = fn(model, validatedData[index]);

    if (field) return { field, index };
  }
}

export default function ImportExport({
  importOptions,
  exportOptions,
  onRefresh,
}) {
  const [importData, setImportData] = useState();
  const errorHandler = useErrorHandler();

  const resetImportData = () => {
    setImportData();
  };

  const confirmImport = async () => {
    console.log(importData.validatedData);
    if (importOptions.backend && importData.validatedData?.length) {
      const { error } = await httpService.post(
        importOptions.backend,
        importData.validatedData
      );
      if (error) {
        errorHandler(error);
      }
      onRefresh();
    }
    setImportData();
  };

  const read = (e) => {
    console.log("READING");
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onload = async (e) => {
      /* Parse data */
      const ab = e.target.result;
      const wb = XLSX.read(ab, { type: "array" });
      /* Get first worksheet */
      const wsName = wb.SheetNames[0];
      const ws = wb.Sheets[wsName];
      /* Convert array of arrays */
      const [header, ...data] = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const validatedData = [];
      const errors = [];
      await Promise.all(
        data.map(async (arr, index) => {
          const row = {};
          arr.forEach((val, i) => {
            row[header[i]] = val;
          });
          // validate row
          const { error, data: model } = await importOptions.validate(row);

          if (!model) {
            return errors.push({
              error,
              row,
              arr,
              rowIndex: index + 2,
            });
          }

          const duplicate = duplicateChecker(
            importOptions?.duplicate,
            validatedData,
            model
          );

          if (duplicate) {
            return errors.push({
              error: `فیلد ${duplicate.field} در سطر ${
                duplicate.index + 1
              } پذیرفته شده تکراری است`,
              row,
              arr,
              rowIndex: index + 2,
            });
          }
          return validatedData.push(model);
        })
      );
      console.log({ validatedData, errors, header });
      setImportData({ validatedData, errors, header });
    };
    reader.readAsArrayBuffer(file);
    e.target.value = null;
  };
  const download = () => {
    // debugger;
    const { data: exportData, fileName } = exportOptions;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportTable = () => {
    download();
  };

  const inputComponent = (
    <input
      type="file"
      onChange={read}
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      hidden
    />
  );

  if (importOptions && exportOptions) {
    return (
      <>
        <ButtonPopover
          // closeOnClick
          icon={ImportExportIcon}
        >
          <List component="nav" aria-label="main mailbox folders">
            <ListItem button component="label">
              <ListItemIcon>
                <ImportIcon />
              </ListItemIcon>
              <ListItemText primary="آپدیت کردن" />
              {inputComponent}
            </ListItem>
            <ListItem button onClick={exportTable}>
              <ListItemIcon>
                <ExportIcon />
              </ListItemIcon>
              <ListItemText primary="دانلود جدول" />
            </ListItem>
          </List>
        </ButtonPopover>
        <ImportConfirmModal
          data={importData}
          onClose={resetImportData}
          onConfirm={confirmImport}
        />
      </>
    );
  }
  if (importOptions) {
    return (
      <>
        <IconButton component="label">
          <ImportIcon />
          {inputComponent}
        </IconButton>
        <ImportConfirmModal
          data={importData}
          onClose={resetImportData}
          onConfirm={confirmImport}
        />
      </>
    );
  }
  if (exportOptions) {
    return (
      <IconButton onClick={exportTable}>
        <ExportIcon />
      </IconButton>
    );
  }
  return null;
}
