import React from "react";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import TableSortLabel from "@material-ui/core/TableSortLabel";

export default function TableHeader(props) {
  const { headCells, onRequestSort, orderBy, order, hasActions } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((cell) =>
          cell.hidden ? null : (
            <TableCell
              key={cell.title}
              sortDirection={orderBy === cell.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === cell.field}
                direction={orderBy === cell.field ? order : "asc"}
                onClick={createSortHandler(cell.field)}
              >
                {cell.title}
                {orderBy === cell.field ? (
                  <span className="visually-hidden">
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
        {hasActions ? <TableCell className="w-1">عملیات</TableCell> : null}
      </TableRow>
    </TableHead>
  );
}
