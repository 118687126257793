import httpService from "./http.service";

class RequestService {
  seen = (id) => {
    return httpService.put("/reminders/seen/" + id);
  };
  reschedule = (id) => {
    return httpService.put("/reminders/reschedule/" + id);
  };
}
const requestService = new RequestService();
export default requestService;
