import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { calculateTimeLeft } from "../../helpers";
import CodeInput from "../../components/code-input";

export default function VerifyCode({
  phoneNumber,
  goBack,
  reSend,
  retryDate,
  onSubmit,
  loading,
}) {
  const [timeLeft, setTimeLeft] = useState();
  const [code, setCode] = useState("");

  useEffect(() => {
    if (!retryDate) return;
    setTimeLeft(calculateTimeLeft(retryDate));
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(retryDate));
    }, 1000);
    return () => clearInterval(timer);
  }, [retryDate]);

  const onCodeChange = (e) => {
    let value = e.target.value || "";
    setCode(value);
  };
  const sendAgain = () => {
    setCode("");
    reSend();
  };
  const handleSubmit = (e) => {
    if (e && e.key !== "Enter") return;
    if (code.length < 6 || loading) return;
    onSubmit(code);
  };

  return (
    <>
      <Typography align="center">
        پیامی حاوی کد تایید به شماره تلفن {phoneNumber} ارسال شد. در صورت اشتباه
        بودن این شماره{" "}
        <Typography
          color="primary"
          onClick={goBack}
          className="pointer"
          component="span"
        >
          اینجا کلیک کنید
        </Typography>
        .
      </Typography>
      <Box textAlign="center">
        <Button
          onClick={sendAgain}
          disabled={Boolean(timeLeft || loading)}
          color="primary"
        >
          ارسال مجدد کد
        </Button>
        {timeLeft && `در ${timeLeft.minutes}:${timeLeft.seconds}`}
      </Box>

      <TextField
        value={code}
        onChange={onCodeChange}
        label="کد"
        className="code-text-field"
        InputProps={{ inputComponent: CodeInput }}
        margin="normal"
        variant="outlined"
        fullWidth
        size="small"
        onKeyPress={handleSubmit}
      />
      <Button
        onClick={() => handleSubmit()}
        className="my-2"
        disabled={Boolean(code.length < 6 || loading)}
        fullWidth
        variant="contained"
        color="primary"
      >
        تایید
      </Button>
    </>
  );
}
