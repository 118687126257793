import httpService from "./http.service";

class CoordinatorService {
  getCoordinatorsUrl = "/coordinators";

  createCoordinator = (model) => {
    return httpService.post("/coordinators", model);
  };
  editCoordinator = (id, model) => {
    return httpService.put("/coordinators/" + id, model);
  };
  deleteCoordinator = (id) => {
    return httpService.delete("/coordinators/" + id);
  };
}

const coordinatorService = new CoordinatorService();
export default coordinatorService;
