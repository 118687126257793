import React, { Fragment } from "react";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { Form, Formik, FieldArray } from "formik";
import {
  FTextField,
  FAutocomplete,
  FDatePicker,
  FFilePicker,
  FSwitch,
} from "../form-elements";

export default function FormBuilder({
  submitTitle,
  schema,
  initialValues,
  handleSubmit,
  form = [],
  actionComponent,
  submitDirty,
  formWrapper: FormWrapper = Fragment,
  formContainer: FormContainer = Fragment,
}) {
  const renderFormElement = (
    { _type, _wrapper: Wrapper, _hidden, ...props },
    { values, ...args }
  ) => {
    if (_hidden) return null;
    const element = (() => {
      switch (_type) {
        case "textField":
          return <FTextField key={props.name} {...props} />;
        case "autocomplete":
          return <FAutocomplete key={props.name} {...props} />;
        case "filePicker":
          return <FFilePicker key={props.name} {...props} />;
        case "datePicker":
          return <FDatePicker key={props.name} {...props} />;
        case "switch":
          return <FSwitch key={props.name} {...props} />;
        case "array":
          return (
            <FieldArray
              key={props.name}
              name={props.name}
              render={(arrayHelpers) => {
                const items =
                  props.items instanceof Array
                    ? props.items
                    : props.items(arrayHelpers);
                return (
                  <>
                    <Grid container className="mt-2">
                      {props.label && (
                        <Grid item xs>
                          <Typography variant="h6">{props.label}</Typography>
                        </Grid>
                      )}
                      <Grid item>
                        <IconButton
                          size="small"
                          onClick={() => arrayHelpers.push(props.emptyValue)}
                        >
                          <AddIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {values[props.name].map((property, index) => (
                      <Fragment key={index.toString()}>
                        <Grid
                          className="mb-4"
                          container
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item xs>
                            {items.map((arg) => {
                              if (arg instanceof Function) arg = arg(index);
                              let { name, ...el } = arg;
                              name = `${props.name}[${index}].${name}`;
                              return renderFormElement(
                                { ...el, name },
                                { values, ...args }
                              );
                            })}
                          </Grid>
                          {values[props.name].length >
                            (typeof props.minLength === "number"
                              ? props.minLength
                              : 1) && (
                            <Grid item>
                              <IconButton
                                size="small"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Fragment>
                    ))}
                  </>
                );
              }}
            />
          );
        case "customContent":
          return props.content({ values, ...args });
        default:
          return null;
      }
    })();
    if (Wrapper) {
      return <Wrapper key={props.name}>{element}</Wrapper>;
    } else {
      return element;
    }
  };
  const renderFormElements = (args) => {
    const formArray = form instanceof Array ? form : form(args);
    return formArray.map((el) => renderFormElement(el, args));
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting, ...args }) => (
        <Form>
          <FormContainer>
            <FormWrapper>
              {renderFormElements({ isSubmitting, ...args })}
            </FormWrapper>
            {actionComponent ? (
              actionComponent({ isSubmitting, ...args })
            ) : (
              <div className="text-center mt-2">
                <Button
                  type="submit"
                  disabled={isSubmitting || (submitDirty && !args.dirty)}
                  variant="contained"
                  color="primary"
                >
                  {submitTitle || "ذخیره"}
                </Button>
              </div>
            )}
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
}
