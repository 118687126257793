import React, { useMemo } from "react";
import { TextField as MuiTextField } from "@material-ui/core";
import CurrencyInput from "../../currency-input";
import MileageInput from "../../mileage-input";
import CodeInput from "../../code-input";
import { getIn } from "formik";
import PhoneInput from "../../phone-input";

function TextField(props) {
  const {
    form: { errors, submitCount, setFieldValue },
    field,
    autocomplete,
    inputProps = {},
    onChange,
    type,
    ...other
  } = props;

  const errorText = getIn(errors, field.name);
  const hasError = Boolean(errorText && submitCount);

  const _type = useMemo(() => {
    switch (type) {
      case "currency":
      case "mileage":
      case "code":
        return "text";
      case "phone":
        return "tel";
      default:
        return type;
    }
  }, [type]);

  const inputComponent = useMemo(() => {
    switch (type) {
      case "currency":
        return CurrencyInput;
      case "mileage":
        return MileageInput;
      case "phone":
        return PhoneInput;
      case "code":
        return CodeInput;
      default:
        return undefined;
    }
  }, [type]);

  const handleBlur = () => {
    if (inputProps.onBlur) inputProps.onBlur();
    if (type === "number" && inputProps) {
      const { min, max } = inputProps;
      if (min > field.value) {
        setFieldValue(field.name, min);
      }
      if (max < field.value) {
        setFieldValue(field.name, max);
      }
    }
  };
  const handleChange = (e) => {
    field.onChange(e);
    if (onChange) onChange(e?.target?.value);
  };

  return (
    <MuiTextField
      error={hasError}
      helperText={hasError ? errorText : ""}
      onBlur={handleBlur}
      autoComplete={autocomplete || "off"}
      type={_type}
      InputProps={{ inputComponent }}
      inputProps={{
        ...inputProps,
        onBlur: handleBlur,
      }}
      {...field}
      {...other}
      onChange={handleChange}
    />
  );
}

TextField.defaultProps = {
  form: {},
  field: {},
};
export default TextField;
