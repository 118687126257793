import React, { useMemo } from "react";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import requestService from "../../../services/request.service";
import DialogFormBuilder from "../../../components/dialog-form-builder";
import { useSelector } from "react-redux";

const schema = Yup.object().shape({
  carCode: yupSchema.carCode,
  service: yupSchema.service,
  subServices: yupSchema.subServices,
  description: yupSchema.description,
});

export default function FormModalContent({ close }) {
  const { account } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo(
    () => ({
      carCode: "",
      service:
        account.services.length === 1 ? account.services[0].id : undefined,
      subServices: [],
      description: "",
    }),
    [account]
  );

  const form = useMemo(
    () =>
      ({ values, setFieldValue }) =>
        [
          {
            _type: "textField",
            name: "carCode",
            label: "کد ارمغان",
            autoFocus: true,
          },
          {
            _type: "autocomplete",
            _hidden: account.services.length === 1,
            name: "service",
            options: account.services,
            valueSelector: "id",
            labelSelector: "title",
            label: "خدمت",
            disableClearable: true,
            onChange: (o) => {
              setFieldValue("subServices", []);
            },
          },
          {
            _type: "autocomplete",
            name: "subServices",
            options:
              account.services.find((s) => s.id === values.service)
                ?.subServices || [],
            disabled: !values.service,
            valueSelector: "id",
            labelSelector: "title",
            label: "زیر خدمت ها",
            multiple: true,
            fast: false,
          },
          {
            _type: "textField",
            name: "description",
            label: "توضیحات",
            multiline: true,
            rows: 4,
          },
        ],
    [account]
  );
  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "ServiceNotFound":
        message = "خدمت مورد نظر یافت نشد";
        variant = "error";
        break;
      case "CarNotFound":
        message = "خودرو مورد نظر یافت نشد. کد ارمغان اشتباه وارد شده.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };

  const handleSubmit = async (model, { setSubmitting }) => {
    const { error } = await requestService.branchCreate(model);
    setSubmitting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <DialogFormBuilder
      {...{
        close,
        title: "ایجاد درخواست",
        schema,
        initialValues,
        handleSubmit,
        form,
      }}
    />
  );
}
