import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Divider,
  Button,
  Typography,
  Grid,
  DialogActions,
} from "@material-ui/core";
import Loading from "../loading";
import DialogTitle from "../dialog-title";
import branchService from "../../services/branch.service";
import { useSnackbar } from "notistack";
import { Autocomplete } from "../formik-material-ui";
import appointmentService from "../../services/appointment.service";
import moment from "moment-jalaali";
import requestService from "../../services/request.service";
import { useHistory } from "react-router";

function GridItemText({ value, label, valueClassName, sm }) {
  return (
    <Grid item xs={12} sm={sm || 6}>
      <Typography>
        {label}: <span className={valueClassName}> {value} </span>
      </Typography>
    </Grid>
  );
}
function Content({
  serviceId,
  requestId,
  dialog,
  onChange,
  hideResult,
  error,
}) {
  const [branch, setBranch] = useState();
  const [waiting, setWaiting] = useState();
  const [dates, setDates] = useState([]);
  const [dateObject, setDateObject] = useState();
  const [day, setDay] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branch]);
  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "RequestNotFound":
        message = "درخواست مورد نظر یافت نشد";
        variant = "error";
        break;
      case "YouAreNotOwner":
        message = "این درخواست را شما ایجاد نکردید.";
        variant = "error";
        break;
      case "HasAppointment":
        message = "برای این درخواست زمان ثبت شده است.";
        variant = "error";
        break;
      case "AppointmentNotFound":
        message =
          "زمان مورد نظر یافت نشد. ممکن است کسی پیش از شما آن را برای خود ثبت کرده باشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const manageDates = (dates = []) => {
    let ds = [];
    dates.forEach((date) => {
      let withoutTime = new Date(date.time).setHours(0, 0, 0, 0);
      let _date = ds.find((d) => d.day === withoutTime);
      if (!_date) {
        _date = { day: withoutTime, times: [] };
        ds.push(_date);
      }
      _date.times.push(date);
    });
    setDates(ds);
  };
  const getData = async () => {
    if (!branch) {
      return setDates([]);
    }
    setWaiting(true);
    const { data, error } = await appointmentService.getAppointments(
      branch.id,
      serviceId
    );
    setWaiting(false);
    if (error) {
      handleError(error);
      return;
    }
    manageDates(data);
  };
  const handleSelect = async () => {
    setWaiting(true);
    const { error, data } = await requestService.setAppointment(
      requestId,
      dateObject.id
    );
    setWaiting(false);
    if (error) {
      handleError(error);
      return;
    }
    history.replace("/invoice/request/" + data.code, data);
  };
  const handleSetDateObject = (val) => {
    setDateObject(val);
    if (onChange) onChange(val?.id);
  };
  return (
    <Loading isLoading={waiting} overlay>
      {requestId && !dialog && (
        <Typography className="mb-2" variant="h5">
          انتخاب شعبه و زمان
        </Typography>
      )}
      <Autocomplete
        label="شعبه"
        valueSelector="id"
        labelSelector="branchName"
        backend={
          serviceId ? branchService.serviceBranchAc + serviceId : undefined
        }
        onChange={(b) => {
          setBranch(b);
          setDay(undefined);
          setDateObject(undefined);
        }}
        error={error && !branch?.id}
        field={{ value: branch?.id }}
        disabled={!serviceId || waiting}
      />
      <Autocomplete
        label="روز"
        valueSelector="day"
        labelSelector={(option) => moment(option.day).format("jYYYY/jMM/jDD")}
        options={dates}
        onChange={(d) => {
          setDay(d);
          setDateObject(undefined);
        }}
        field={{ value: day?.day }}
        disabled={!branch || waiting}
        error={error && !day?.day}
      />
      <Autocomplete
        label="ساعت"
        valueSelector="id"
        labelSelector={(option) => moment(option.time).format("HH:mm")}
        options={day?.times}
        onChange={handleSetDateObject}
        field={{ value: dateObject?.id }}
        disabled={!branch || waiting || !day}
        error={error && !dateObject?.id}
      />
      {branch && !hideResult && (
        <div className="simple-card">
          <Grid container spacing={2}>
            <GridItemText label="مدیریت" value={branch.manager} />
            <GridItemText
              label="شماره تلفن"
              value={branch.contact.phoneNumbers.join(", ")}
              valueClassName="en"
            />
            <GridItemText sm={12} label="آدرس" value={branch.contact.address} />

            {dateObject && (
              <GridItemText
                sm={12}
                label="زمان"
                value={moment(dateObject.time).format(dateObject.title)}
              />
            )}
          </Grid>
        </div>
      )}
      {requestId && (
        <Button
          fullWidth
          disabled={!dateObject}
          onClick={handleSelect}
          color="primary"
          variant="contained"
          className="mt-2"
        >
          ثبت
        </Button>
      )}
    </Loading>
  );
}

export default function SelectBranchAppointment({
  dialog,
  title,
  buttonProps,
  ...others
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const onClose = () => setModalOpen(false);

  if (dialog) {
    return (
      <>
        <Button
          onClick={() => setModalOpen(true)}
          color="primary"
          {...buttonProps}
        >
          {title || "مشاهده شعب و زمان"}
        </Button>
        <Dialog
          open={modalOpen}
          onClose={onClose}
          maxWidth={"sm"}
          fullWidth
          classes={{
            paper: "matte-glass",
          }}
        >
          <DialogTitle onClose={onClose}>
            {title || "مشاهده شعب و زمان"}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Content dialog={dialog} {...others} />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="primary">
              متوجه شدم
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return <Content {...others} />;
}
