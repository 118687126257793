import Loading from "../../../components/loading";
import { useSnackbar } from "notistack";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import React, { useState, Fragment, useMemo } from "react";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import { FTextField, FAutocomplete } from "../../../components/form-elements";
import serviceService from "../../../services/service.service";
import branchService from "../../../services/branch.service";
import DialogTitle from "../../../components/dialog-title";

const emptyValues = {
  username: "",
  manager: "",
  branchName: "",
  code: "",
  password: "",
  description: "",
  contact: {
    address: "",
    phoneNumbers: [""],
    location: "",
    instagram: "",
  },
  services: [],
};

export default function FormModalContent({ close, data }) {
  const [waiting, setWaiting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id, ...initialValues } = data || {};

  const formSchema = useMemo(() => {
    return Yup.object().shape({
      username: yupSchema.username,
      manager: yupSchema.manager,
      branchName: yupSchema.branchName,
      code: yupSchema.branchCode,
      password: id ? yupSchema.optionalPassword : yupSchema.password,
      description: yupSchema.requiredDescription,
      contact: yupSchema.contact,
    });
  }, [id]);

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "UsernameTaken":
        message = "این نام کاربری تکراری است.";
        variant = "error";
        break;
      case "BranchNotFound":
        message = "شعبه مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async (model) => {
    setWaiting(true);

    model = formSchema.cast(model);
    let op;
    if (id) {
      op = await branchService.editBranch(id, model);
    } else {
      op = await branchService.createBranch(model);
    }
    const { error } = op;
    setWaiting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <Loading isLoading={waiting} overlay>
      <DialogTitle onClose={() => close()}>
        {id ? "ویرایش شعبه" : "ایجاد شعبه"}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={id ? initialValues : emptyValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6"></Typography>
                  <FTextField name="username" label="نام کاربری" autoFocus />
                  <FTextField
                    name="password"
                    label="کلمه عبور"
                    type="password"
                    autocomplete="new-password"
                  />
                  <FTextField name="manager" label="مدیر" />
                  <FTextField name="branchName" label="نام شعبه" />
                  <FTextField name="code" label="کد شعبه" />
                  <FAutocomplete
                    name="services"
                    backend={serviceService.servicesAC}
                    valueSelector="id"
                    labelSelector="title"
                    label="خدمات"
                    multiple
                  />
                  <FTextField
                    name="description"
                    label="توضیحات"
                    multiline
                    rows={4}
                  />
                  <Grid
                    container
                    justify="flex-start"
                    alignItems="flex-end"
                  ></Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Hidden smUp implementation="css">
                    <Divider className="my-2" />
                  </Hidden>
                  <FTextField
                    name="contact.address"
                    label="آدرس"
                    multiline
                    rows={4}
                  />
                  <FTextField
                    name="contact.location"
                    className="code-text-field"
                    label="موقعیت"
                  />
                  <FTextField
                    name="contact.instagram"
                    className="code-text-field"
                    label="اینستاگرام"
                  />
                  <FieldArray
                    name="contact.phoneNumbers"
                    render={(arrayHelpers) => (
                      <>
                        <Grid container className="mt-2">
                          <Grid item xs>
                            <Typography variant="h6">تلفن ها</Typography>
                          </Grid>
                          <Grid item>
                            <IconButton
                              size="small"
                              onClick={() => arrayHelpers.push("")}
                            >
                              <AddIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                        {values.contact.phoneNumbers.map((property, index) => (
                          <Fragment key={index.toString()}>
                            <Grid
                              className="mb-4"
                              container
                              alignItems="center"
                              spacing={2}
                            >
                              <Grid item xs>
                                <FTextField
                                  name={`contact.phoneNumbers[${index}]`}
                                  className="code-text-field"
                                  label="تلفن"
                                />
                              </Grid>
                              {values.contact.phoneNumbers.length > 1 && (
                                <Grid item>
                                  <IconButton
                                    size="small"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </Fragment>
                        ))}
                      </>
                    )}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={close} color="primary" disabled={isSubmitting}>
                لغو
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                {id ? "ذخیره" : "ایجاد"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Loading>
  );
}
