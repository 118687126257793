import moment from "moment-jalaali";

export function getTimeFormat(date) {
  return moment(date).format("HH:mm");
}
export function toJalaali(date) {
  const d = moment(date);
  return {
    year: d.jYear(),
    month: d.jMonth() + 1,
    day: d.jDate(),
  };
}
export function toGregorian({ year, month, day }) {
  const m = moment(`${year}/${month}/${day}`, "jYYYY/jM/jD");
  return {
    year: m.year(),
    month: m.month(),
    day: m.date(),
  };
}
export function toGregorianDate(date) {
  const { year, month, day } = toGregorian(date);
  return new Date(year, month, day);
}
