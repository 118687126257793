import React, { useEffect, useMemo, useState } from "react";
import { Container, Paper, Typography, Button, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useParams, useLocation, Link } from "react-router-dom";
import requestService from "../../services/request.service";
import SelectBranchAppointment from "../../components/select-branch-appointment";
import ThemeSection from "../../components/theme-section";
import Loading from "../../components/loading";
import NotFoundPage from "../../components/404";
import RequestFailure from "./request-failure";
import FAIL_IMAGE from "../../assets/images/payment-failed.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    successIcon: {
      fontSize: 100,
      color: theme.palette.success.main,
    },
    img: {
      width: 400,
    },
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
  })
);
function useQuery() {
  const entries = new URLSearchParams(useLocation().search).entries();
  return useMemo(() => {
    const res = {};
    for (let [key, value] of entries) {
      res[key] = value;
    }
    return res;
  }, [entries]);
}
export default function PaymentPage() {
  const classes = useStyles();
  const [waiting, setWaiting] = useState(true);
  const { Status, Authority } = useQuery();
  const { field } = useParams();
  const [data, setData] = useState();
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    if (Status !== "OK") {
      setData("PaymentFailure");
      setWaiting(false);
      return;
    }
    setWaiting(true);
    switch (field) {
      case "request":
        requestService
          .validatePayment(Authority)
          .then(({ data, error }) => {
            setWaiting(false);
            if (error) {
              setData("PaymentFailure");
              setErrorMessage(data?.data);
              return;
            }
            setData(data);
          })
          .catch(() => {
            setData("RequestFailure");
            setWaiting(false);
          });
        break;
      default:
        setData("NotFound");
        setWaiting(false);
    }
  }, [field, Status, Authority]);
  return (
    <Loading isLoading={waiting} fullPage>
      <Container>
        <ThemeSection noParent>
          <Grid justify="center" container>
            <Grid item xs={12} sm={8} md={6}>
              <Paper className="my-5 p-2">
                {data === "PaymentFailure" ? (
                  <div className={classes.root}>
                    <img
                      className={classes.img}
                      src={FAIL_IMAGE}
                      alt="failed-transaction"
                    />
                    <Typography
                      className={classes.title}
                      component="h1"
                      variant="h3"
                      color="error"
                    >
                      پرداخت نا موفق
                    </Typography>
                    <Typography
                      className={classes.title}
                      component="h2"
                      variant="h5"
                    >
                      {errorMessage}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to="/panel/requests"
                    >
                      بازگشت به پنل
                    </Button>
                  </div>
                ) : data === "RequestFailure" ? (
                  <RequestFailure />
                ) : data === "NotFound" ? (
                  <NotFoundPage />
                ) : data ? (
                  <>
                    <div className={classes.root}>
                      <CheckCircleIcon className={classes.successIcon} />
                      <Typography
                        className={classes.title}
                        component="h1"
                        variant="h3"
                      >
                        پرداخت موفق
                      </Typography>
                    </div>
                    <SelectBranchAppointment
                      serviceId={data.service}
                      requestId={data.id}
                    />
                  </>
                ) : null}
              </Paper>
            </Grid>
          </Grid>
        </ThemeSection>
      </Container>
    </Loading>
  );
}
