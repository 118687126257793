import httpService from "./http.service";

class BranchService {
  getBranchesUrl = "/branches";
  serviceBranchAc = "/branches/by-service/";

  createBranch = (model) => {
    return httpService.post("/branches", model);
  };
  editBranch = (id, model) => {
    return httpService.put("/branches/" + id, model);
  };
  deleteBranch = (id) => {
    return httpService.delete("/branches/" + id);
  };
  getBranches = () => {
    return httpService.get("/branches");
  };
}
const branchService = new BranchService();
export default branchService;
