import React from "react";
import { createStyles, fade, makeStyles } from "@material-ui/core/styles";
import {
  Hidden,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NestedList from "../nested-list";
import authService from "../../services/auth.service";
import LOGO from "../../assets/images/armaghan-logo.png";

const drawerWidth = 280;
const useStyles = makeStyles((theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      "&::before, &::after": {
        content: "''",
        display: "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 1,
      },
    },
    contentContainer: {
      zIndex: 2,
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    },
    toolbar: {
      ...theme.mixins.toolbar,
      padding: `0 ${theme.spacing(2)}px`,
      display: "flex",
      flexDirection: "column",
    },
    logo: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        maxWidth: "100%",
        maxHeight: 45,
      },
    },
    content: {
      flexGrow: 1,
      overflow: "auto",
      padding: `0 ${theme.spacing(2)}px`,
      "& .MuiListItem-root": {
        borderRadius: 5,
        userDrag: "none",
        "&.Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          boxShadow:
            `0 12px 20px -10px ${fade(theme.palette.primary.main, 0.28)}, ` +
            "0 4px 20px 0 rgba(0, 0, 0,.12), " +
            `0 7px 8px -5px ${fade(theme.palette.primary.main, 0.2)}`,
          "& .MuiListItemIcon-root": {
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
  })
);

export default function AppPanelSideBar({ window, menuItems, open, onClose }) {
  const classes = useStyles();

  const drawer = (
    <div className={classes.contentContainer}>
      <div className={classes.toolbar}>
        <div className={classes.logo}>
          <img src={LOGO} alt="" />
        </div>
        <Divider />
      </div>
      <div className={classes.content}>
        <NestedList data={menuItems} />
        <Divider className="mt-auto" />
        <List>
          <ListItem onClick={authService.logout} button color={"primary"}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="خروج" />
          </ListItem>
        </List>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Hidden mdUp implementation="js">
        <Drawer
          container={container}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={onClose}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="js">
        <Drawer
          classes={{
            paper: classes.drawerPaper + " glass",
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}
