import React from "react";
import { Button, Typography } from "@material-ui/core";
import { FDatePicker, FTextField } from "../../components/form-elements";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import yupSchema from "../../constants/schema";
import AuthContainer from "../../components/auth-container";
import authService from "../../services/auth.service";
import { setAccount } from "../../redux/auth";
import { useDispatch } from "react-redux";

const schema = Yup.object().shape({
  firstName: yupSchema.firstName,
  lastName: yupSchema.lastName,
  birthDate: yupSchema.birthDate,
});

export default function UpdateAccount() {
  const dispatch = useDispatch();

  const submit = async (values, actions) => {
    values = schema.cast(values);
    actions.setSubmitting(true);
    const { error, data } = await authService.updateProfile(values);
    actions.setSubmitting(false);
    if (error) {
      // TODO: handleError
      return;
    }
    dispatch(setAccount(data));
  };

  return (
    <AuthContainer>
      <Formik
        onSubmit={submit}
        initialValues={{ firstName: "", lastName: "" }}
        validationSchema={schema}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <Typography className="mb-2">
              لطفا فرم زیر را تکمیل کنید..
            </Typography>
            <FTextField name="firstName" label="نام" />
            <FTextField name="lastName" label="نام خانوادگی" />

            <FDatePicker name="birthDate" label="تاریخ تولد" birthDate />
            <Button
              className="mt-2"
              type="submit"
              disabled={isSubmitting}
              fullWidth
              variant="contained"
              color="primary"
            >
              تایید
            </Button>
          </Form>
        )}
      </Formik>
    </AuthContainer>
  );
}
