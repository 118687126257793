import httpService from "./http.service";
import { store } from "../redux";
import { logOut, setAccount } from "../redux/auth";

class AuthService {
  confirmPhone = (model) => {
    return httpService.post("/accounts/verify-phone", model);
  };
  login = (model) => {
    return httpService.post("/accounts/login", model);
  };
  authenticate = (model) => {
    return httpService.post("/accounts/authenticate", model);
  };
  updateProfile = (model) => {
    return httpService.put("/accounts/edit-profile", model);
  };
  getAccount = async () => {
    const { data, error } = await httpService.get("/accounts/get-profile");
    store.dispatch(setAccount(error ? undefined : data));
  };
  logout = () => {
    httpService.post("/accounts/revoke-token", {});
    store.dispatch(logOut());
  };
  updateAdmin = (model) => {
    return httpService.put("/accounts/admin-profile", model);
  };
  forget = (model) => {
    return httpService.post("/accounts/admin-forget", model);
  };
  verifyAdminPhone = (model) => {
    return httpService.post("/accounts/admin-verify-phone", model);
  };
}

const authService = new AuthService();
export default authService;
