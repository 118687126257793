import { Container, createStyles, makeStyles } from "@material-ui/core";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";
import { Link } from "react-router-dom";

// install Swiper modules
SwiperCore.use([Pagination]);

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down("sm")]: {
        paddingRight: theme.spacing(0),
        paddingLeft: theme.spacing(0),
      },
    },
    root: {
      borderRadius: 20,
      isolation: "isolate",
      paddingTop: "33.26%",
      position: "relative",
      overflow: "hidden",
      margin: `${theme.spacing(5)}px 0`,

      [theme.breakpoints.down("sm")]: {
        marginTop: 0,
        borderRadius: 0,
      },
    },
    swiper: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
    bullet: {
      width: "1.3%",
      minWidth: 8,
      // width: 16,
      display: "inline-block",
      borderRadius: 8,
      backgroundColor: "#fff",
      opacity: 0.4,
      margin: `0 4px`,
      cursor: "pointer",
      transition: "all 200ms ease-in-out",
      boxShadow: "0 0 3px -1px #000",
      "&::before": {
        content: "''",
        paddingTop: "100%",
        display: "block",
        transition: "all 200ms ease-in-out",
      },
    },
    activeBullet: {
      width: "2.7%",
      minWidth: 18.5,
      margin: `0 2px`,
      backgroundColor: "#fff",
      opacity: 0.75,
      boxShadow: "0 0 3px -1px #0008",

      "&::before": {
        paddingTop: "48.2%",
      },
    },
    bannerSlide: {
      height: "100%",
      "& img": {
        width: "100%",
        height: "100%",
      },
    },
  })
);
export default function HomeBanner(props) {
  const { data } = props;

  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <Swiper
          slidesPerView={1}
          pagination={{
            clickable: true,
            bulletClass: classes.bullet,
            bulletActiveClass: classes.activeBullet,
          }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
          className={classes.swiper}
          loop
          autoplay
        >
          {data.map((slide) => (
            <SwiperSlide key={slide.id}>
              {slide.url ? (
                <Link href={slide.url} className={classes.bannerSlide}>
                  <img src={slide.image} alt="banner" border="0" />
                </Link>
              ) : (
                <div className={classes.bannerSlide}>
                  <img src={slide.image} alt="banner" border="0" />
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Container>
  );
}
