import React from "react";
import { currencyFormat } from "../../../helpers";
import { Typography, Grid, Divider } from "@material-ui/core";

export default function BranchDoneListSection({ branch }) {
  const total = React.useMemo(() => {
    if (!branch.doneList?.length) return;
    let sum = 0;
    branch.doneList.forEach(({ price }) => {
      sum += price;
    });
    return sum;
  }, [branch]);

  if (!branch.doneList?.length) return null;
  return (
    <Grid item xs={12}>
      <div className="simple-card">
        <Typography variant="h6" className="mb-2">
          لیست کار
        </Typography>
        {branch.doneList.map((row) => (
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography>{row.title}</Typography>
            </Grid>
            <Grid item>
              <Typography>{currencyFormat(row.price)}</Typography>
            </Grid>
          </Grid>
        ))}

        <Divider className="my-2" />
        <Grid container spacing={2}>
          <Grid item xs>
            <Typography>مجموع</Typography>
          </Grid>
          <Grid item>
            <Typography>{currencyFormat(total)}</Typography>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
