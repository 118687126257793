import React, { useState } from "react";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import Loading from "../../../components/loading";
import moment from "moment-jalaali";
import config from "../../../config";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import DialogTitle from "../../../components/dialog-title";
import { Form, Formik } from "formik";
import { FTextField } from "../../../components/form-elements";
import requestService from "../../../services/request.service";
import { useErrorHandler } from "../../../hooks";

const initialValues = {
  message: "",
  predictedPrice: 0,
  prePaymentPrice: 0,
};
const schema = Yup.object().shape({
  message: yupSchema.messageRequired,
  predictedPrice: yupSchema.predictedPrice,
  prePaymentPrice: yupSchema.prePaymentPrice,
});
function GridItemText({ value, label, valueClassName }) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography>
        {label}: <span className={valueClassName}> {value} </span>
      </Typography>
    </Grid>
  );
}
export default function FormModalContent({ close, data, extra }) {
  const [waiting, setWaiting] = useState();
  const [rejected, setRejected] = useState(false);
  const handleError = useErrorHandler();

  const handleSubmit = async (model) => {
    setWaiting(true);
    const op = await requestService.coordinatorAnswer(data.id, {
      ...model,
      rejected,
    });
    setRejected(false);
    const { error } = op;
    setWaiting(false);
    if (error) {
      handleError(error, [
        {
          case: "NotCoordinatable",
          message:
            "درخواست قابل هماهنگی نیست. ممکن است قبلا هماهنگی صورت گرفته باشد.",
        },
        { case: "RequestNotFound", message: "درخواست مورد نظر یافت نشد." },
      ]);
      return;
    }
    close(true);
  };
  const setPrePayment = (value, setFieldValue) => {
    const per = (extra.services[data.service]?.prePayment || 0) / 100;
    if (!per) return;
    setFieldValue("prePaymentPrice", value * per);
  };
  return (
    <Loading isLoading={waiting} overlay>
      <DialogTitle divider onClose={() => close()}>
        استعلام قیمت
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ isSubmitting, handleSubmit, setFieldValue }) => (
          <Form autoComplete="off">
            <DialogContent>
              <Grid container spacing={2}>
                <GridItemText
                  label="کد"
                  value={data.code}
                  valueClassName="en"
                />
                <GridItemText
                  label="تاریخ"
                  value={moment(data.date).format("jYYYY/jMM/jDD HH:mm")}
                />
                <GridItemText
                  label="خدمت"
                  value={extra.services[data.service]?.title}
                />
                <GridItemText
                  label="زیر خدمت ها"
                  value={data.subServices
                    .map((s) => extra.subServices[s])
                    .join(", ")}
                />
                <Grid item xs={12}>
                  <div className="simple-card">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">مشخصات خودرو</Typography>
                      </Grid>
                      <GridItemText label="برند" value={data.car.brand.name} />
                      <GridItemText
                        label="مدل"
                        value={data.car.carClass.name}
                      />
                      <GridItemText
                        label="شماره شاسی"
                        value={data.car.chassisNumber}
                      />
                      <GridItemText label="رنگ" value={data.car.color} />
                      <GridItemText label="کیلومتر" value={data.car.mileage} />
                      <GridItemText label="سال" value={data.car.year} />
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="simple-card">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">مشخصات مالک</Typography>
                      </Grid>
                      <GridItemText
                        label="کد"
                        value={data.customer.account.code}
                      />
                      <GridItemText
                        label="شماره تلفن"
                        value={data.customer.account.phoneNumber}
                        valueClassName="en"
                      />
                      <GridItemText
                        label="نام"
                        value={data.customer.account.firstName}
                      />
                      <GridItemText
                        label="نام خانوادگی"
                        value={data.customer.account.lastName}
                      />
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="simple-card">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h6">پیام مشتری</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>{data.customer.message}</Typography>
                      </Grid>
                      {data.customer.images.map((image) => (
                        <Grid item xs={12} sm={6}>
                          {/* TODO: REMOVE THIS */}
                          <img
                            src={`${config.get("endpoint")}${image}`}
                            alt=""
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Grid>
              </Grid>

              <FTextField
                name="message"
                label="پیام"
                autoFocus={true}
                multiline={true}
                rows={4}
              />
              <FTextField
                name="predictedPrice"
                type="currency"
                label="قیمت پیش بینی شده"
                onChange={(v) => setPrePayment(v, setFieldValue)}
              />
              <FTextField
                name="prePaymentPrice"
                type="currency"
                label="مبلغ پیش پرداخت"
                fast={false}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={close} color="primary" disabled={isSubmitting}>
                لغو
              </Button>
              <Button
                color="primary"
                variant="outlined"
                disabled={isSubmitting}
                onClick={() => {
                  setRejected(true);
                  handleSubmit();
                }}
              >
                رد کردن
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                color="primary"
              >
                تایید
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Loading>
  );
}
