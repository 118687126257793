import {
  ButtonBase,
  Container,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import PLACEHOLDER from "../../../../assets/images/blog-placeholder.png";

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      backgroundColor: "#222F3D",
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(8),
      position: "relative",

      "&::after": {
        content: "''",
        position: "absolute",
        width: "50%",
        maxHeight: theme.spacing(9),
        height: "10vw",
        backgroundColor: "#222F3D",
        top: "100%",
        left: "66%",
        transform: "skewX(-40deg)",
      },
    },
    title: {
      color: "#fff",
      marginBottom: theme.spacing(6),
      fontWeight: 700,
    },
    swiper: {},
    card: {
      padding: 16,
      flexDirection: "column",
      backgroundColor: "#fffa",
      alignItems: "stretch",
      borderRadius: 16,
      display: "flex",
    },
    imageWrapper: {
      borderRadius: 8,
    },
    cardTitle: {
      fontWeight: 700,
      marginTop: 16,
      marginBottom: 8,
    },
    cardDesk: {},
  });
});
export default function HomeCourses() {
  const classes = useStyles();
  const { blogs } = useSelector((state) => state.blog);

  const courses = useMemo(() => {
    return blogs?.filter((b) => b.category === "آموزش") || [];
  }, [blogs]);

  if (!courses.length) return null;
  return (
    <div className={classes.root}>
      <Container>
        <Typography
          className={classes.title}
          align="center"
          component="h2"
          variant="h5"
        >
          دوره‌های آموزشی
        </Typography>
        <Swiper
          spaceBetween={8}
          slidesPerView={1.5}
          breakpoints={{
            960: { slidesPerView: 2, spaceBetween: 16 },
            1200: { slidesPerView: 3, spaceBetween: 32 },
          }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
          className={classes.swiper}
          loop
          autoplay
        >
          {courses.map((course) => (
            <SwiperSlide key={course.id}>
              <ButtonBase
                component={Link}
                to={`/blog/${course.title}`}
                className={classes.card}
              >
                <div className={clsx("image-wrapper", classes.imageWrapper)}>
                  <img
                    src={course.image || PLACEHOLDER}
                    alt="banner"
                    border="0"
                  />
                </div>
                <Typography className={classes.cardTitle} variant="h6">
                  {course.title}
                </Typography>
                <Typography className={classes.cardDesk}>
                  {course.summery}
                </Typography>
              </ButtonBase>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
}
