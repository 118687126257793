import httpService from "./http.service";

class CustomerService {
  getCustomersUrl = "/customers";
  getGroupsUrl = "/customers/groups";
  customerGroupsAC = "/customers/groups/ac";
  importCustomersUrl = "/customers/import";

  createGroup = (model) => {
    return httpService.post("/customers/groups", model);
  };
  editGroup = (id, model) => {
    return httpService.put("/customers/groups/" + id, model);
  };
  deleteGroup = (id) => {
    return httpService.delete("/customers/groups/" + id);
  };

  createCustomer = (model) => {
    return httpService.post("/customers", model);
  };
  editCustomer = (id, model) => {
    return httpService.put("/customers/" + id, model);
  };
  deleteCustomer = (id) => {
    return httpService.delete("/customers/" + id);
  };
  getStats = (id) => {
    return httpService.get("/customers/stats/" + id);
  };
}

const customerService = new CustomerService();
export default customerService;
