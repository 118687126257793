import React from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../../components/data-table";
import ConfirmModalContent from "../../../../components/confirm-modal-content";
import FormModalContent from "./form";
import carItemService from "../../../../services/car-item.service";

const headerCells = [
  {
    field: "name",
    title: "نام",
    mainField: true,
  },
];

const defaultKeys = [
  {
    title: "نام",
    field: "name",
    active: true,
  },
];

export default function CarItemGroup() {
  const deleteItem = (item) => {
    return carItemService.deleteCategory(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        backend={carItemService.getCategoriesUrl}
        headCells={headerCells}
        rowActions={[
          {
            icon: <EditIcon />,
            modalProps: { maxWidth: "sm" },
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            icon: <DeleteIcon />,
            modalProps: { maxWidth: "sm" },
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.name}
                close={props.close}
              />
            ),
          },
        ]}
        actions={[
          {
            icon: <AddIcon />,
            tooltip: "ایجاد خدمت",
            modalContent: (props) => <FormModalContent {...props} />,
            modalProps: { maxWidth: "sm" },
          },
        ]}
        searchKeys={defaultKeys}
        title="دسته بندی آیتم"
      />
    </div>
  );
}
