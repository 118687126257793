import React from "react";
import config from "../../../config";
import { Grid, Typography } from "@material-ui/core";

export default function CustomerMessageSection({ customer, title }) {
  if (!customer.message && !customer.images?.length) return null;
  return (
    <Grid item xs={12}>
      <div className="simple-card">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{customer.message}</Typography>
          </Grid>
          {customer.images.map((image) => (
            <Grid item xs={12} sm={6}>
              {/* TODO: REMOVE THIS */}
              <img src={`${config.get("endpoint")}${image}`} alt="" />
            </Grid>
          ))}
        </Grid>
      </div>
    </Grid>
  );
}
