import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import LoadingComponent from "../../../../components/loading";
import carItemService from "../../../../services/car-item.service";
import httpService from "../../../../services/http.service";
import { useErrorHandler, useDidUpdateEffect } from "../../../../hooks";
import { Close as RemoveIcon, Add as AddIcon } from "@material-ui/icons";
import carService from "../../../../services/car.service";

export default function ItemsTable({ carId, filter, items: _items }) {
  const [waitingItems, setWaitingItems] = useState({});
  const [items, setItems] = useState(_items);
  const [loading, setLoading] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState();
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const errorHandler = useErrorHandler();

  useDidUpdateEffect(() => {
    (async () => {
      setLoading(true);
      const { error, data } = await httpService.get(
        carItemService.getCarItemsUrl,
        { params: { page, rowsPerPage, filter } }
      );
      setLoading(false);
      if (error) {
        handleError(error);
        return;
      }
      setRows(data.rows);
      setTotalRows(data.totalRows);
      setPage(data.page);
    })();
  }, [page, filter]);

  const handleError = (error) => {
    errorHandler(error, [
      { case: "DuplicateItem", message: "این آیتم تکراری است." },
      { case: "CarItemNotFound", message: "آیتم مورد نظر یافت نشد." },
      { case: "CarNotFound", message: "خودرو مورد نظر یافت نشد." },
    ]);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const setItemWaiting = (id, val) => {
    const newWaitings = { ...waitingItems };
    newWaitings[id] = val;
    setWaitingItems(newWaitings);
  };
  const waiting = (id) => {
    return !!waitingItems[id];
  };
  const included = (id) => {
    return items && !!items.find((i) => i.id === id);
  };
  const add = (id) => async () => {
    setItemWaiting(id, true);
    const { error, data } = await carService.addCarItem(carId, id);
    setItemWaiting(id, false);
    if (error) {
      // TODO: handle
      return;
    }
    setItems([...items, data]);
  };
  const remove = (id) => async () => {
    setItemWaiting(id, true);
    const { error } = await carService.deleteCarItem(carId, id);
    setItemWaiting(id, false);
    if (error) {
      // TODO: handle
      return;
    }
    const newItems = [...items];
    const idx = newItems.findIndex((i) => i.id === id);
    if (idx !== -1) newItems.splice(idx, 1);
    setItems(newItems);
  };

  if (loading) return <LoadingComponent className="my-2" />;
  if (rows === undefined) return null;
  if (!totalRows)
    return (
      <Typography className="text-center my-2">موردی یافت نشد.</Typography>
    );
  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            {rows.map((row) => {
              const isWaiting = waiting(row.id);
              const isIncluded = included(row.id);
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.category.name}</TableCell>
                  <TableCell>{row.brand}</TableCell>
                  <TableCell>{row.model}</TableCell>
                  <TableCell className="w-0">
                    <IconButton
                      hidden={!isWaiting}
                      size="small"
                      disabled
                      component="span"
                    >
                      <CircularProgress size={24} />
                    </IconButton>
                    <IconButton
                      onClick={add(row.id)}
                      hidden={isIncluded || isWaiting}
                      size="small"
                    >
                      <AddIcon />
                    </IconButton>
                    <IconButton
                      onClick={remove(row.id)}
                      hidden={!isIncluded || isWaiting}
                      size="small"
                    >
                      <RemoveIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPage={rowsPerPage}
        component="div"
        count={totalRows}
        page={page - 1}
        onChangePage={handleChangePage}
        //   labelRowsPerPage="تعداد سطرها"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} از ${totalRows}`
        }
      />
    </>
  );
}
