import React, { useEffect, useState } from "react";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import { getIn } from "formik";
import { TextField, CircularProgress } from "@material-ui/core";
import httpService from "../../../services/http.service";
import { useDebouncedEffect } from "../../../hooks";

function Autocomplete(props) {
  const {
    form: { errors, setFieldValue = () => {}, submitCount } = {},
    field: { name, value } = {},
    options: _options,
    valueSelector,
    labelSelector,
    defaultValue,
    label,
    onChange,
    backend,
    multiple,
    onSetOptions,
    error,
    ...other
  } = props;
  const [options, setOptions] = useState(_options || []);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setOptions(_options || []);
  }, [_options]);

  useDebouncedEffect(
    () => {
      if (!backend) return;
      getData();
    },
    100,
    [backend]
  );

  const getData = async () => {
    setLoading(true);
    const { error, data } = await httpService.get(`${backend}`);
    setLoading(false);
    if (error) return;
    setOptions(data || []);
    if (onSetOptions) onSetOptions(data || []);
  };

  const handleChange = (e, option) => {
    if (onChange) {
      onChange(option);
    }
    if (!valueSelector) {
      setFieldValue(name, option);
      return;
    }
    if (Array.isArray(option)) {
      setFieldValue(
        name,
        option.map((o) => (o ? o[valueSelector] : o))
      );
      return;
    }
    setFieldValue(name, option ? option[valueSelector] : option);
  };
  const getValue = () => {
    if (multiple) {
      if (valueSelector) {
        return options.filter((o) =>
          (value || []).find((v) => v === o[valueSelector])
        );
      }
      return options.filter((o) => (value || []).find((v) => v === o));
    }

    if (valueSelector) {
      return options.find((o) => o[valueSelector] === value) || null;
    }
    return options.find((o) => o === value) || null;
  };
  const getOptionLabel = () => {
    if (!labelSelector) return;
    if (typeof labelSelector === "string") {
      return (option) => `${option[labelSelector]}`;
    }
    return labelSelector;
  };
  const errorText = getIn(errors, name);
  const hasError = Boolean(errorText && submitCount);
  return (
    <MuiAutocomplete
      loading={loading}
      multiple={multiple}
      options={options}
      getOptionLabel={getOptionLabel()}
      noOptionsText="هیچ گزینه ای یافت نشد."
      defaultValue={defaultValue}
      value={getValue()}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          margin="dense"
          variant="outlined"
          fullWidth
          label={label}
          error={error || hasError}
          helperText={hasError ? errorText : ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...other}
    />
  );
}

export default Autocomplete;
