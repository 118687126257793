import React, { useState, useRef } from "react";
import { Button, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { FTextField, FCaptcha } from "../../../components/form-elements";
import AuthContainer from "../../../components/auth-container";
import authService from "../../../services/auth.service";
import { setAccount } from "../../../redux/auth";
import { useDispatch } from "react-redux";
import VerifyCode from "../verify-code";
import yupSchema from "../../../constants/schema";
import { useHistory } from "react-router";

const schema = Yup.object().shape({
  phoneNumber: yupSchema.phoneNumber,
  captcha: yupSchema.captcha,
});

export default function Forget() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState();
  const [phone, setPhone] = useState();
  const [retryDate, setRetryDate] = useState();
  const [isVerify, setIsVerify] = useState();
  const captchaRef = useRef();

  const handleError = (error) => {
    let message;
    switch (error) {
      // verify
      case "TryFirst":
        message = "قبلا از این کد استفاده شده، دوباره تلاش کنید.";
        setPhone();
        setIsVerify();
        break;
      case "CodeExpired":
        message = "کد منقضی شده، لطفا دوباره درخواست کد بدید.";
        setPhone();
        setIsVerify();
        break;
      case "TooMuchAttempts":
        message = "کد منقضی شده، لطفا دوباره درخواست کد بدید.";
        break;
      case "IncorrectCode":
        message = "کد اشتباه وارد شد.";
        break;
      // submit
      case "CaptchaExpired":
        message = "کپچا منقضی شده. دوباره تلاش کنید.";
        break;
      case "IncorrectCaptcha":
        message = "کپچا اشتباه وارد شده.";
        break;
      default:
        message = "مشکلی پیش آمده، لطفا بعدا تلاش کنید.";
    }
    setError(message);
  };
  const submit = async (values, actions) => {
    values = schema.cast(values);
    const { data, error } = await authService.forget(values);
    actions.setSubmitting(false);
    if (captchaRef?.current) {
      captchaRef.current.refresh();
    }
    if (error && error !== "TryAgainLater") {
      // TODO: handle errors
      return handleError(error);
    }
    setError();
    setRetryDate(data);
    setPhone(values.phoneNumber);
    setIsVerify(true);
  };
  const verify = async (code) => {
    if (!phone) return;
    setLoading(true);
    const model = { code, phoneNumber: phone };
    const { error, data } = await authService.verifyAdminPhone(model);
    setLoading(false);
    if (error) {
      handleError(error);
      return;
    }
    dispatch(setAccount(data));
    history.push("/panel/admin");
  };
  return (
    <AuthContainer error={error}>
      {!isVerify ? (
        <Formik
          onSubmit={submit}
          initialValues={{
            phoneNumber: phone || "",
            captcha: { text: "", token: "" },
          }}
          validationSchema={schema}
        >
          {({ isSubmitting }) => (
            <Form>
              <Typography className="mb-2">
                برای ورود شماره تلفن خود را وارد نمایید.
              </Typography>
              <FTextField
                name="phoneNumber"
                label="شماره تلفن"
                type="phone"
                className="code-text-field"
              />
              <FCaptcha
                componentRef={captchaRef}
                name="captcha"
                className="mt-2 code-text-field"
              />
              <Button
                className="mt-2"
                type="submit"
                disabled={isSubmitting}
                fullWidth
                variant="contained"
                color="primary"
              >
                ارسال کد تایید
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <VerifyCode
          phoneNumber={phone}
          goBack={() => {
            setPhone();
            setError();
            setIsVerify();
          }}
          reSend={() => {
            setError();
            setIsVerify();
          }}
          retryDate={retryDate}
          onSubmit={verify}
          loading={loading}
        />
      )}
    </AuthContainer>
  );
}
