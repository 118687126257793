import React, { useEffect, useState } from "react";
import Header from "../../components/site-header";
import Footer from "../../components/site-footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import blogService from "../../services/blog.service";
import LoadingComponent from "../../components/loading";
import { useErrorHandler } from "../../hooks";
import ThemeSection from "../../components/theme-section";
import { useDispatch } from "react-redux";
import { setBlogs } from "../../redux/blog";

export default function LandingContainer({ children }) {
  const [waiting, setWaiting] = useState(true);
  const [categorizedBlogs, setCategorizedBlogs] = useState();
  const handleError = useErrorHandler();
  const dispatch = useDispatch();

  useEffect(() => {
    getData();

    document?.body?.classList?.add("bg-white");
    return () => {
      document?.body?.classList?.remove("bg-white");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    const { data, error } = await blogService.getCategorizedBlogs();
    setWaiting(false);
    if (error) {
      handleError(error);
      return;
    }

    dispatch(setBlogs(data));

    const blogCategories = [];
    data.forEach((blog) => {
      let category = blogCategories.find((c) => c.title === blog.category);
      if (!category) {
        category = {
          title: blog.category,
          subList: [],
        };
        blogCategories.push(category);
      }
      category.subList.push({
        title: blog.title,
        route: "/blog/" + blog.title,
      });
    });
    setCategorizedBlogs(blogCategories);
    console.log(blogCategories);
  };
  return (
    <LoadingComponent isLoading={waiting} fullPage>
      <ThemeSection>
        <Header blogs={categorizedBlogs} />
        {children}
        <Footer blogs={categorizedBlogs} />
      </ThemeSection>
    </LoadingComponent>
  );
}
