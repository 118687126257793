function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function stableSort(array, order, orderBy) {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function deepFind(obj, path) {
  const paths = path.split(".");
  let current = obj;

  for (const i in paths) {
    current = current[paths[i]];
    if (typeof current !== "object") {
      break;
    }
    if (Array.isArray(current) && +i < paths.length - 1) {
      const arr = [];
      paths.splice(0, +i + 1);
      current.forEach((c) => {
        arr.push(deepFind(c, paths.join(".")));
      });
      current = typeof arr[0] === "string" ? arr.join("|") : arr;

      break;
    }
  }
  return current;
}
