import httpService from "./http.service";

class CarItemService {
  getCategoriesUrl = "/car-item/categories";
  categoriesAC = "/car-item/categories/ac";
  getCarItemsUrl = "/car-item";

  createCategory = (model) => {
    return httpService.form("/car-item/categories", model, "createCategory");
  };
  editCategory = (id, model) => {
    return httpService.form(
      "/car-item/categories/" + id,
      model,
      "editCategory",
      {},
      "put"
    );
  };
  deleteCategory = (id) => {
    return httpService.delete("/car-item/categories/" + id);
  };
  createCarItem = (model) => {
    return httpService.form("/car-item", model, "createCarItem");
  };
  editCarItem = (id, model) => {
    return httpService.form("/car-item/" + id, model, "editCarItem", {}, "put");
  };
  deleteCarItem = (id) => {
    return httpService.delete("/car-item/" + id);
  };
}

const carItemService = new CarItemService();
export default carItemService;
