import React from "react";
import NumberFormat from "react-number-format";

export default function DateInput(props) {
  const { inputRef, onChange, ...other } = props;

  const handleChange = (values) => {
    onChange({
      target: {
        name: props.name,
        value: values.floatValue,
      },
    });
  };
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={handleChange}
      thousandSeparator
      isNumericString
      decimalScale={0}
      allowNegative={false}
      // allowEmptyFormatting
      format="####/##/##"
      mask="_ "
    />
  );
}
