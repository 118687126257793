import React, { useMemo } from "react";
import { Container, Paper, Grid } from "@material-ui/core";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import FormBuilder from "../../../components/form-builder";
import yupSchema from "../../../constants/schema";
import authService from "../../../services/auth.service";
import { setAccount } from "../../../redux/auth";
import { useErrorHandler } from "../../../hooks";
import { useSnackbar } from "notistack";

const schema = Yup.object().shape({
  username: yupSchema.username,
  phoneNumber: yupSchema.phoneNumber,
  password: yupSchema.optionalPassword,
});

function FormWrapper({ children }) {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
}
function ElementWrapper({ children }) {
  return (
    <Grid item xs={12} sm={4}>
      {children}
    </Grid>
  );
}

const form = [
  {
    _type: "textField",
    _wrapper: ElementWrapper,
    name: "username",
    label: "نام کاربری",
  },
  {
    _type: "textField",
    _wrapper: ElementWrapper,
    name: "phoneNumber",
    label: "شماره تلفن",
    type: "phone",
    className: "code-text-field",
  },
  {
    _type: "textField",
    _wrapper: ElementWrapper,
    name: "password",
    label: "کلمه عبور",
    type: "password",
    autocomplete: "new-password",
  },
];

export default function AdminProfile() {
  const { account } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo(() => {
    const { username, phoneNumber } = account;
    return { username, phoneNumber, password: "" };
  }, [account]);

  const submit = async (values, actions) => {
    values = schema.cast(values);
    const { error, data } = await authService.updateAdmin(values);
    actions.setSubmitting(false);
    if (error) {
      handleError(error);
      return;
    }
    enqueueSnackbar("تغییرات با موفقیت ثبت شد.", { variant: "success" });
    dispatch(setAccount(data));
  };

  return (
    <Container>
      <Paper className="mt-5 p-2">
        <FormBuilder
          schema={schema}
          initialValues={initialValues}
          handleSubmit={submit}
          form={form}
          formWrapper={FormWrapper}
          actionComponent={account.code ? () => null : undefined}
          submitDirty
        />
      </Paper>
    </Container>
  );
}
