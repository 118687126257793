import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CarCard from "./car-card";
import FormModalContent from "./form";
import CarItemsInfo from "../../../components/car-items-info";
import ConfirmModalContent from "../../../components/confirm-modal-content";
import RequestForm from "../requests/create-form";

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {},
    createCard: {
      backgroundColor: theme.palette.glassBackground.paper,
      height: "100%",
      minHeight: 230,
      border: `5px dashed ${theme.palette.divider}`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "& .icon": {
        marginBottom: theme.spacing(3),
        fontSize: 80,
      },
    },
  })
);

export default function CardView({ data, deleteItem, onRefresh }) {
  const classes = useStyles();
  const [modalContent, setModalContent] = useState();

  const closeModal = (refresh) => {
    setModalContent();
    if (refresh) onRefresh();
  };

  if (!data)
    return (
      <Box className="mt-5" display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  return (
    <>
      <Grid container spacing={2} justify="center">
        {data.map((car) => (
          <Grid key={car.id} item xs={12} sm={6} md={4} lg={3}>
            <CarCard
              {...car}
              onRefresh={onRefresh}
              onRequest={() =>
                setModalContent(
                  <RequestForm close={closeModal} data={{ car: car.id }} />
                )
              }
              onEdit={() =>
                setModalContent(
                  <FormModalContent close={closeModal} data={car} />
                )
              }
              onDelete={() =>
                setModalContent(
                  <ConfirmModalContent
                    onConfirm={() => deleteItem(car)}
                    confirmText={car.title}
                    close={closeModal}
                  />
                )
              }
              onItemInfo={() =>
                setModalContent(
                  <CarItemsInfo
                    data={{ brand: car.brand.id, carClass: car.carClass.id }}
                    brandName={car.brand.name}
                    className={car.carClass.name}
                    close={closeModal}
                  />
                )
              }
            />
          </Grid>
        ))}
        {data && data.length < 18 && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Paper
              onClick={() =>
                setModalContent(<FormModalContent close={closeModal} />)
              }
              className={classes.createCard}
            >
              <AddIcon className="icon" />
              <Typography>افزودن خودرو</Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={!!modalContent}
        maxWidth="sm"
        fullWidth
        classes={{
          paper: "matte-glass",
        }}
      >
        {modalContent}
      </Dialog>
    </>
  );
}
