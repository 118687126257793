import React from "react";
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import DataTable from "../../../components/data-table";
import CreateFormModalContent from "./create-form";
import InfoModalContent from "./info";
import requestService from "../../../services/request.service";
import ConfirmModalContent from "../../../components/confirm-modal-content";
import { Status, StatusMap } from "../../../constants/enums";
import moment from "moment-jalaali";

const headerCells = [
  { field: "code", title: "کد", mainField: true },
  {
    field: "car",
    title: "خودرو",
    renderer: (val, row, extra) => extra.cars[val],
  },
  {
    field: "status",
    title: "وضعیت",
    renderer: (val) => StatusMap[val],
  },
  {
    field: "date",
    title: "تاریخ ایجاد",
    date: true,
  },
  {
    field: "branch.receptionDate",
    title: "تاریخ پذیرش",
    renderer: (_, row) =>
      row.branch?.receptionDate
        ? moment(row.branch.receptionDate).format("jYYYY/jMM/jDD HH:mm")
        : "-",
  },
  {
    field: "branch.clearanceDate",
    title: "تاریخ ترخیص",
    renderer: (_, row) =>
      row.branch?.receptionDate
        ? moment(row.branch.clearanceDate).format("jYYYY/jMM/jDD HH:mm")
        : "-",
  },
  {
    field: "service",
    title: "خدمت",
    renderer: (val, row, extra) => extra.services[val],
  },
  {
    field: "subServices",
    title: "زیر خدمت",
    renderer: (val, row, extra) =>
      val.map((v) => extra.subServices[v]).join(", "),
  },
];

const filterForm = {
  form: [
    {
      _type: "autocomplete",
      name: "status",
      options: Object.entries(StatusMap).map(([value, title]) => ({
        title,
        value,
      })),
      label: "وضعیت",
      valueSelector: "value",
      labelSelector: "title",
    },
  ],
};
export default function CustomerRequests() {
  const deleteItem = (item) => {
    return requestService.deleteRequest(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        orderBy="date"
        backend={requestService.getRequestsUrl}
        rowActions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <VisibilityIcon />,
            modalContent: (props) => <InfoModalContent {...props} />,
          },
          {
            hidden: (row) => row.status !== Status.Coordination,
            modalProps: { maxWidth: "sm" },
            icon: <DeleteIcon />,
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.code}
                close={props.close}
              />
            ),
          },
        ]}
        actions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <AddIcon />,
            tooltip: "ایجاد درخواست",
            modalContent: (props) => <CreateFormModalContent {...props} />,
          },
        ]}
        title="درخواست های من"
        filterForm={filterForm}
      />
    </div>
  );
}
