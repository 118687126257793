import React from "react";
import { IconButton, Typography } from "@material-ui/core";
import {
  Remove as DecreaseIcon,
  Add as IncreaseIcon,
} from "@material-ui/icons";

function NumberButton(props) {
  const {
    form: { setFieldValue },
    field: { name, value },
    label,
    min,
    max,
    step = 1,
    disabled,
  } = props;

  const handleChange = (increase) => () => {
    let newValue = value + (increase ? +step : -step);
    if (min && newValue <= +min) {
      newValue = +min;
    }
    if (max && newValue >= +max) {
      newValue = +max;
    }
    setFieldValue(name, newValue);
  };
  return (
    <div className="MuiFormControlLabel-root full-width MuiFormControlLabel-labelPlacementStart">
      <div className="number-button">
        <IconButton
          disabled={disabled || value === +min}
          onClick={handleChange(false)}
        >
          <DecreaseIcon />
        </IconButton>
        <Typography component="span" className="value">
          {value}
        </Typography>
        <IconButton
          disabled={disabled || value === +max}
          onClick={handleChange(true)}
        >
          <IncreaseIcon />
        </IconButton>
      </div>
      <Typography component="span" className="MuiFormControlLabel-label">
        {label}
      </Typography>
    </div>
  );
}

NumberButton.defaultProps = {
  form: {},
  field: {},
};
export default NumberButton;
