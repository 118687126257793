import { createStyles, makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as Frame } from "../../../../assets/images/home/sp-frame.svg";

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: { textAlign: "center" },
    iconWrapper: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      maxWidth: 150,
      margin: "auto",
      marginBottom: 8,
    },
    frame: {
      width: "100%",
      height: "100%",
    },
    icon: {
      position: "absolute",
      top: "45%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      height: "60%",
    },
  });
});
export default function HomeSpecificationItem(props) {
  const { Icon, title } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <Frame className={classes.frame} />
        <Icon className={classes.icon} />
      </div>
      <Typography>{title}</Typography>
    </div>
  );
}
