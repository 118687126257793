import * as Yup from "yup";
import { MOBILE_REG, USERNAME_REG, CHASSIS_REG, LOCATION_REG } from "./regex";
import { toGregorian, toGregorianDate } from "../helpers/date-helpers";

const typeCreator = (
  name,
  {
    type,
    min,
    max,
    required,
    matches,
    trim,
    transform,
    uppercase,
    lessThan,
  } = {}
) => {
  let t = Yup[type || "string"]();
  if (trim) t = t.trim();
  if (uppercase) t = t.uppercase();
  const getMaxMessage = ({ max }) => {
    if (type === "number") {
      return `${name} نمیتواند بیشتر از ${max} باشد.`;
    }
    return `${name} میتواند حداکثر ${max} کارکتر باشد.`;
  };
  const getMinMessage = ({ min }) => {
    if (type === "number") {
      return `${name} نمیتواند کمتر از ${min} باشد.`;
    }
    return `${name} باید حداقل ${min} کارکتر باشد.`;
  };
  if (min) t = t.min(min, getMinMessage);
  if (max) t = t.max(max, getMaxMessage);
  if (lessThan)
    t = t.lessThan(lessThan, ({ less }) => `${name} باید کمتر از ${less} باشد`);
  if (matches) {
    if (matches.record) {
      t = t.matches(matches.reg, `${name} ${matches.record}`);
    } else {
      t = t.matches(matches, `${name} وارد شده صحیح نمیباشد.`);
    }
  }
  if (transform) t = t.transform(transform);
  if (required) {
    t = t.required(`${name} الزامی است.`);
  }
  return t;
};

const dateSchema = Yup.mixed();
const timeSchema = Yup.string().required("زمان را انتخاب کنید.");
const timesSchema = (s = timeSchema) =>
  Yup.array().of(s).min(1, "حد اقل یک زمان انتخاب کنید.");
const timeTest = timeSchema.test({
  test: (time) => {
    if (!time) return true;
    const [h, m] = time.split(":");
    const now = new Date();
    const passed =
      now.getHours() > +h || (now.getHours() === +h && now.getMinutes() >= +m);
    return !passed;
  },
  message: "ساعت نمیتواند گذشته باشد.",
});

const datePassedCallback = (date) => {
  // Same day
  const { year, month, day } = toGregorian(date);
  const now = new Date();
  if (
    year !== now.getFullYear() ||
    month !== now.getMonth() ||
    day !== now.getDate()
  )
    return;
  return true;
};

const schema = {
  captcha: Yup.object()
    .test("required", "کپچا الزامی است", (val) => val.text)
    .test(
      "length",
      "کپچا باید 6 کارکتر باشد.",
      (val) => val.text?.length === 6
    ),
  stringBirthDate: Yup.mixed()
    .transform((value) => {
      if (!value || typeof value !== "string") return;
      const [year, month, day] = value.split("-");
      // Invalid Date
      if (!year || !month || !day || +year < 1300) return NaN;
      return toGregorianDate({
        year: +year,
        month: +month,
        day: +day,
      });
    })
    .test(
      "string-birth-date",
      "تاریخ تولد اشتباه است.",
      (value) => !value || !isNaN(value)
    ),
  birthDate: Yup.date().required("تاریخ تولد الزامی است."),
  phoneNumber: typeCreator("شماره تلفن", {
    transform: (pn) => {
      if (pn[0] === "9") pn = "+98" + pn;
      if (pn[0] === "0") pn = "+98" + pn.slice(1);
      // pn = "+989" + pn;
      return pn;
    },
    matches: MOBILE_REG,
    required: true,
    trim: true,
  }),
  username: typeCreator("نام کاربری", {
    min: 3,
    max: 32,
    matches: USERNAME_REG,
    required: true,
    trim: true,
  }),
  branchCode: typeCreator("کد شعبه", {
    max: 32,
    required: true,
    trim: true,
  }),
  summery: typeCreator("خلاصه", {
    max: 250,
    trim: true,
  }),
  password: typeCreator("رمز عبور", { min: 8, required: true }),
  optionalPassword: typeCreator("رمز عبور", { min: 8 }),
  name: typeCreator("نام", { min: 1, max: 32, required: true }),
  firstName: typeCreator("نام", { min: 3, max: 32, required: true }),
  lastName: typeCreator("نام خانوادگی", { min: 3, max: 32, required: true }),
  manager: typeCreator("نام مدیر", { min: 3, max: 32, required: true }),
  branchName: typeCreator("نام شعبه", { min: 3, max: 32, required: true }),
  description: Yup.string(),
  requiredDescription: typeCreator("توضیحات", { required: true }),
  carCode: typeCreator("کد ارمغان", { required: true }),
  contact: Yup.object().shape({
    address: typeCreator("آدرس", { required: true }),
    instagram: typeCreator("اینستاگرام"),
    location: typeCreator("موقعیت", {
      transform: (val) => {
        const loc = val.split(",");
        if (loc.length !== 2) return val;
        for (let l of loc) {
          if (isNaN(+l)) return val;
        }
        return `${+loc[0]},${+loc[1]}`;
      },
      matches: LOCATION_REG,
      required: true,
      trim: true,
    }),
    phoneNumbers: Yup.array().of(
      typeCreator("شماره تلفن", { min: 8, required: true })
    ),
  }),
  benefits: Yup.array().of(
    Yup.object({
      service: typeCreator("خدمت", { required: true }),
      discountPercent: Yup.number()
        .min(0)
        .max(100)
        .required("فیلد الزامی است."),
    })
  ),
  optionalTitle: typeCreator("عنوان", { min: 3, max: 32 }),
  title: typeCreator("عنوان", { min: 3, max: 32, required: true }),
  title128: typeCreator("عنوان", { min: 3, max: 128, required: true }),
  image: Yup.mixed(),
  prePayment: typeCreator("پیش پرداخت", { type: "number", min: 0, max: 100 }),
  brand: typeCreator("برند", { required: true }),
  carClass: typeCreator("مدل", { required: true }),
  year: typeCreator("سال", {
    required: true,
    type: "number",
    min: 1300,
    max: new Date().getFullYear(),
  }),
  predictedPrice: typeCreator("قیمت پیش بینی شده", {
    required: true,
    type: "number",
    min: 0,
  }),
  prePaymentPrice: typeCreator("مبلغ پیش پرداخت", {
    required: true,
    type: "number",
    min: 0,
    max: Yup.ref("predictedPrice"),
  }),
  carItemBrand: typeCreator("برند", { min: 2, max: 32, required: true }),
  carItemModel: typeCreator("مدل", { min: 2, max: 32, required: true }),
  category: typeCreator("دسته", { required: true }),
  carType: Yup.number().required("تیپ الزامی است."),
  customerCode: typeCreator("کد", {}),
  chassisNumber: typeCreator("شماره شاسی", {
    uppercase: true,
    // required: true,
    trim: true,
    matches: CHASSIS_REG,
  }),
  mileage: (min = 0) =>
    typeCreator("کیلومتر", { type: "number", min, required: true }),
  color: typeCreator("رنگ", { min: 2, max: 16, required: true }),
  armaghanCode: typeCreator("کد ارمغان", {}),
  owner: typeCreator("مالک", {}),
  dates: Yup.array().of(dateSchema).min(1, "حد اقل یک تاریخ انتخاب کنید."),
  prices: Yup.array().of(
    Yup.object().shape({
      type: typeCreator("تیپ", { type: "number", min: 0, required: true }),
      price: typeCreator("قیمت", { type: "number", min: 0, required: true }),
    })
  ),
  times: timesSchema(),
  date: dateSchema,
  time: timeSchema,
  timesBOD: Yup.array().when("dates", {
    is: (dates) => {
      if ((dates || []).length !== 1) return;
      return datePassedCallback(dates[0]);
    },
    then: timesSchema(timeTest),
    otherwise: timesSchema(),
  }),
  timeBOD: Yup.string().when("date", {
    is: datePassedCallback,
    then: timeTest,
    otherwise: timeSchema,
  }),
  tags: Yup.array().of(typeCreator("تگ")),
  author: typeCreator("نویسنده"),
  content: Yup.mixed().required("محتوا الزامی است."),
  car: typeCreator("ماشین", { required: true }),
  service: typeCreator("خدمت", { required: true }),
  appointment: typeCreator("قرار ملاقات", { required: true }),
  subServices: Yup.array().of(typeCreator("زیر خدمت")),
  message: typeCreator("پیام"),
  messageRequired: typeCreator("پیام", { required: true }),
  images: Yup.array().of(Yup.mixed()),
  doneList: Yup.array().of(
    Yup.object().shape({
      title: typeCreator("عنوان", { max: 128, required: true }),
      price: typeCreator("قیمت", {
        required: true,
        type: "number",
        min: 0,
      }),
    })
  ),
  discounts: Yup.array().of(Yup.object()),
  reminders: Yup.array().of(
    Yup.object().shape({
      date: typeCreator("تاریخ مراجعه", {
        required: true,
        type: "number",
        min: 0,
      }),
      mileage: typeCreator("کیلومتر", {
        required: true,
        type: "number",
        min: 0,
      }),
      description: Yup.string(),
    })
  ),
};
export default schema;
