import React from "react";
import { makeStyles } from "@material-ui/core";
import HomeBanner from "./banner/banner";
import HomeCar from "./car";
import HomeCourses from "./courses";
import HomeShop from "./shop";
import HomeSpecification from "./specification";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
  },
}));

export default function HomePage() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <HomeBanner
        data={[
          { image: "https://i.ibb.co/yWwV5rX/banner.png", url: "", id: "1" },
          { image: "https://i.ibb.co/yWwV5rX/banner.png", url: "", id: "2" },
          { image: "https://i.ibb.co/yWwV5rX/banner.png", url: "", id: "3" },
        ]}
      />
      <HomeCar />
      <HomeCourses />
      <HomeShop
        data={[
          {
            image: "https://i.ibb.co/yWwV5rX/banner.png",
            url: "/",
            id: "1",
            title: "دوره آموزشی 1",
            description:
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است....",
          },
          {
            image: "https://i.ibb.co/yWwV5rX/banner.png",
            url: "/",
            id: "2",
            title: "دوره آموزشی 1",
            description:
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است....",
          },
          {
            image: "https://i.ibb.co/yWwV5rX/banner.png",
            url: "/",
            id: "3",
            title: "دوره آموزشی 1",
            description:
              "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است....",
          },
        ]}
      />
      <HomeSpecification />
    </div>
  );
}
