import React from "react";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../../components/data-table";
import FormModalContent from "./form";
import customerService from "../../../../services/customer.service";
import ConfirmModalContent from "../../../../components/confirm-modal-content";

const headerCells = [
  {
    field: "title",
    title: "عنوان",
    mainField: true,
  },
  {
    field: "id",
    title: "شناسه",
  },
];
const defaultKeys = [
  {
    field: "title",
    title: "عنوان",
    active: true,
  },
];

export default function CustomerGroupPage() {
  const deleteItem = (item) => {
    return customerService.deleteGroup(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        backend={customerService.getGroupsUrl}
        rowActions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <EditIcon />,
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            modalProps: { maxWidth: "sm" },
            icon: <DeleteIcon />,
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.title}
                close={props.close}
              />
            ),
          },
        ]}
        actions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <AddIcon />,
            tooltip: "ایجاد گروه مشتریان",
            modalContent: (props) => <FormModalContent {...props} />,
          },
        ]}
        searchKeys={defaultKeys}
        title="گروه مشتریان"
      />
    </div>
  );
}
