import React from "react";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import yupSchema from "../../../../constants/schema";
import customerService from "../../../../services/customer.service";
import serviceService from "../../../../services/service.service";
import DialogFormBuilder from "../../../../components/dialog-form-builder";

const initialValues = {
  title: "",
  description: "",
  benefits: [
    {
      service: undefined,
      discountPercent: "",
    },
  ],
};
const schema = Yup.object().shape({
  title: yupSchema.name,
  description: yupSchema.description,
  benefits: yupSchema.benefits,
});
const form = [
  {
    _type: "textField",
    name: "title",
    label: "عنوان",
    autoFocus: true,
  },
  {
    _type: "textField",
    name: "description",
    label: "توضیحات",
    multiline: true,
    rows: 4,
  },
  {
    _type: "array",
    label: "مزایا",
    emptyValue: {
      service: undefined,
      discountPercent: "",
    },
    name: "benefits",
    items: [
      {
        _type: "autocomplete",
        name: "service",
        backend: serviceService.servicesAC,
        valueSelector: "id",
        labelSelector: "title",
        label: "خدمت",
      },
      {
        _type: "textField",
        name: "discountPercent",
        type: "number",
        label: "درصد تخفیف %",
        inputProps: {
          min: 0,
          max: 100,
        },
      },
    ],
  },
];
export default function FormModalContent({ close, data }) {
  const newData = { ...data };
  if (newData.brand) {
    newData.brand = newData.brand.id;
  }
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "TitleTaken":
        message = "این عنوان ثبت شده است.";
        variant = "error";
        break;
      case "GroupNotFound":
        message = "گروه مشتریان مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async (model, { setSubmitting }) => {
    let op;
    if (data && data.id) {
      op = await customerService.editGroup(data.id, model);
    } else {
      op = await customerService.createGroup(model);
    }
    const { error } = op;
    setSubmitting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <DialogFormBuilder
      {...{
        close,
        name: "گروه مشتریان",
        data: newData,
        schema,
        initialValues,
        handleSubmit,
        form,
      }}
    />
  );
}
