import React from "react";
import { FastField, Field } from "formik";
import { Captcha } from "../../formik-material-ui";

export default function FCaptcha({ fast, ...other }) {
  const allProps = {
    component: Captcha,
    ...other,
  };

  if (fast !== false) {
    return <FastField {...allProps} />;
  }
  return <Field {...allProps} />;
}
