import React from "react";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../components/data-table";
import FormModalContent from "./form";
import carService from "../../../services/car.service";
import ConfirmModalContent from "../../../components/confirm-modal-content";

const headerCells = [
  {
    field: "brand",
    title: "برند",
    renderer: (val, row, extra) => extra.brands[val],
  },
  {
    field: "carClass",
    title: "مدل",
    renderer: (val, row, extra) => extra.carClasses[val],
  },
  {
    field: "owner",
    title: "شماره تلفن",
    renderer: (o) => <span className="en">{o.phoneNumber}</span>,
  },
  {
    field: "owner",
    title: "مالک",
    renderer: (owner) => (owner ? `${owner.firstName} ${owner.lastName}` : ""),
  },
  { field: "year", title: "سال" },
  { field: "code", title: "کد ارمغان" },
  { field: "chassisNumber", title: "شماره شاسی" },
  { field: "mileage", title: "کیلومتر" },
  { field: "color", title: "رنگ" },
];
const defaultKeys = [
  { field: "phoneNumber", title: "شماره تلفن" },
  { field: "year", title: "سال" },
  { field: "code", title: "کد ارمغان", active: true },
  { field: "chassisNumber", title: "شماره شاسی", active: true },
  { field: "color", title: "رنگ" },
];
const filterForm = {
  initialValues: { brand: undefined, carClass: undefined },
  form: ({ values, setFieldValue }) => {
    return [
      {
        _type: "autocomplete",
        _empty: undefined,
        name: "brand",
        backend: carService.brandsAC,
        valueSelector: "value",
        labelSelector: "title",
        label: "برند",
        onChange: () => setFieldValue("carClass", undefined),
      },
      {
        _type: "autocomplete",
        _empty: undefined,
        fast: false,
        name: "carClass",
        backend: values.brand
          ? carService.carClassAC + values.brand
          : undefined,
        disabled: !values.brand,
        valueSelector: "value",
        labelSelector: "title",
        label: "مدل",
      },
    ];
  },
};

export default function CarPage() {
  const deleteItem = (item) => {
    return carService.deleteCar(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        orderBy="verified"
        backend={carService.getCarsUrl}
        rowActions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <EditIcon />,
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            disabled: (row) => row.code,
            modalProps: { maxWidth: "sm" },
            icon: <DeleteIcon />,
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.title}
                close={props.close}
              />
            ),
          },
        ]}
        actions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <AddIcon />,
            tooltip: "تعریف خودرو",
            modalContent: (props) => <FormModalContent {...props} />,
          },
        ]}
        searchKeys={defaultKeys}
        title="خودروهای مشتریان"
        filterForm={filterForm}
      />
    </div>
  );
}
