import React, { useState } from "react";
import { useSnackbar } from "notistack";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import DataTable from "../../../components/data-table";
import ConfirmModalContent from "../../../components/confirm-modal-content";
import FormModalContent from "./form";
import subServiceService from "../../../services/sub-service.service";

const headerCells = [
  {
    field: "title",
    title: "عنوان",
    mainField: true,
  },
];

const defaultKeys = [
  {
    title: "عنوان",
    field: "title",
    active: true,
  },
];

export default function SubServicePage({ title, id, subServices }) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(subServices || []);

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "ServiceNotFound":
        message = "خدمت مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const onRefresh = async () => {
    const { data, error } = await subServiceService.getServiceSubServices(id);
    if (error) {
      handleError(error);
      return;
    }
    setData(data);
  };

  const visibilityIcon = (item) => {
    return item.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />;
  };

  const toggleHide = async (item, refresh) => {
    const { error, data } = await subServiceService.toggleHide(id, item.id);
    if (error) return;
    item.hidden = data;
    refresh();
  };
  const deleteItem = (item) => {
    return subServiceService.deleteSubService(id, item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        rowActions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <EditIcon />,
            modalContent: (props) => (
              <FormModalContent {...props} serviceId={id} />
            ),
          },
          {
            modalProps: { maxWidth: "sm" },
            icon: <DeleteIcon />,
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.title}
                close={props.close}
              />
            ),
          },
          {
            icon: visibilityIcon,
            onClick: toggleHide,
          },
        ]}
        actions={[
          {
            icon: <AddIcon />,
            tooltip: "ایجاد زیر خدمت",
            modalContent: (props) => (
              <FormModalContent {...props} serviceId={id} />
            ),
            modalProps: { maxWidth: "sm" },
          },
        ]}
        searchKeys={defaultKeys}
        data={data}
        onRefresh={onRefresh}
        title={"زیر خدمات " + title}
      />
    </div>
  );
}
