import axios from "axios";
import { store } from "../redux";
import config from "../config";
import { setToken, logOut } from "../redux/auth";
import { isSimpleObject } from "../helpers";
const baseUrl = config.get("endpoint") + "/api";

class HttpService {
  request = async (callback) => {
    try {
      const res = await callback();

      const { authorization } = res.headers || {};

      if (authorization) {
        store.dispatch(setToken(authorization));
      }
      if ((res.data?.status || res.status) === "Unauthorized") {
        store.dispatch(logOut());
      }
      return res.data;
    } catch (error) {
      const timeout =
        error.code === "ECONNABORTED" || error.code === "ETIMEDOUT";
      if (!timeout) {
        console.log(error);
      }
      return {
        status: timeout ? "InternetConnection" : "Failed",
        error,
        data: null,
      };
    }
  };
  addDefaultConfig = (config = {}) => {
    const { token } = store.getState().auth;
    Object.assign(config, { withCredentials: true });

    if (!token) return config;
    return {
      ...config,
      headers: {
        ...(config?.headers || {}),
        Authorization: `Bearer ${token}`,
      },
    };
  };
  updateProgressBarValue = (formName, progress) => {
    // TODO: connect this
    console.log(`${formName}: ${progress}%`);
  };

  post(url, params = {}, config = {}) {
    return this.request(() =>
      axios.post(baseUrl + url, params, this.addDefaultConfig(config))
    );
  }
  put(url, params = {}, config = {}, formName) {
    return this.request(() =>
      axios.put(baseUrl + url, params, this.addDefaultConfig(config))
    );
  }
  get(url, config = {}) {
    return this.request(() =>
      axios.get(baseUrl + url, this.addDefaultConfig(config))
    );
  }
  delete(url, config = {}) {
    return this.request(() =>
      axios.delete(baseUrl + url, this.addDefaultConfig(config))
    );
  }
  form(url, params, formName, config = {}, reqMethod) {
    let formData;
    if (params instanceof FormData) {
      formData = params;
    } else {
      formData = new FormData();
      const append = (key, value) => {
        if (isSimpleObject(value)) {
          append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      };
      for (const [key, value] of Object.entries(params)) {
        append(key, value);
      }
    }
    return this[reqMethod || "post"](url, formData, {
      ...config,
      onUploadProgress: (progressEvent) => {
        const totalLength = progressEvent.lengthComputable
          ? progressEvent.total
          : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );
        console.log("onUploadProgress", totalLength);
        if (totalLength !== null) {
          this.updateProgressBarValue(
            formName,
            Math.round((progressEvent.loaded * 100) / totalLength)
          );
        }
      },
    });
  }
}

const httpService = new HttpService();
export default httpService;
