import React from "react";
import moment from "moment-jalaali";
import { Grid, Typography } from "@material-ui/core";

function GridItemText({ value, label, valueClassName }) {
  return (
    <Grid item xs={12} sm={6}>
      <Typography>
        {label}: <span className={valueClassName}> {value} </span>
      </Typography>
    </Grid>
  );
}

export default function BranchDescriptionSection({ branch }) {
  if (!branch.description) return null;
  return (
    <Grid item xs={12}>
      <div className="simple-card">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">توضیحات شعبه</Typography>
          </Grid>
          <GridItemText
            label="تاریخ پذیرش"
            value={
              branch.receptionDate
                ? moment(branch.receptionDate).format("jYYYY/jMM/jDD HH:mm")
                : "-"
            }
          />
          <GridItemText
            label="تاریخ ترخیص"
            value={
              branch.clearanceDate
                ? moment(branch.clearanceDate).format("jYYYY/jMM/jDD HH:mm")
                : "-"
            }
          />
          {!!branch.expDate && (
            <GridItemText
              label="تاریخ رد کردن"
              value={moment(branch.expDate).format("jYYYY/jMM/jDD HH:mm")}
            />
          )}
          <Grid item xs={12}>
            <Typography>{branch.description}</Typography>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}
