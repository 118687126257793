import httpService from "./http.service";

class CarService {
  getBrandsUrl = "/cars/brand";
  getCarClassesUrl = "/cars/class";
  getCarsUrl = "/cars";
  brandsAC = "/cars/brand/ac";
  carClassAC = "/cars/class/ac/";
  carAC = "/cars/ac/";

  getCars = () => {
    return httpService.get(this.getCarsUrl);
  };
  createBrand = (model) => {
    return httpService.form("/cars/brand", model, "createCarBrand");
  };
  editBrand = (id, model) => {
    return httpService.form(
      "/cars/brand/" + id,
      model,
      "editCarBrand",
      {},
      "put"
    );
  };
  deleteBrand = (id) => {
    return httpService.delete("/cars/brand/" + id);
  };

  createCarClass = (model) => {
    return httpService.form("/cars/class", model, "createCarClass");
  };
  editCarClass = (id, model) => {
    return httpService.form(
      "/cars/class/" + id,
      model,
      "editCarClass",
      {},
      "put"
    );
  };
  deleteCarClass = (id) => {
    return httpService.delete("/cars/class/" + id);
  };

  createCar = (model) => {
    return httpService.form("/cars", model, "createCar");
  };
  editCar = (id, model) => {
    return httpService.form("/cars/" + id, model, "editCar", {}, "put");
  };
  updateMileage = (id, mileage) => {
    return httpService.put("/cars/mileage/" + id, { mileage });
  };
  deleteCar = (id) => {
    return httpService.delete("/cars/" + id);
  };
  getCarItems = (id) => {
    return httpService.get("/cars/class/items/" + id);
  };
  createCarItem = (id, model) => {
    return httpService.post(`/cars/class/items/${id}`, model);
  };
  addCarItem = (id, itemId) => {
    return httpService.put(`/cars/class/items/${id}/${itemId}`);
  };
  deleteCarItem = (id, itemId) => {
    return httpService.delete(`/cars/class/items/${id}/${itemId}`);
  };
}

const carService = new CarService();
export default carService;
