import React, { useState, useMemo } from "react";
import {
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
  TextField,
} from "@material-ui/core";
import { DiscountTypes } from "../../../constants/enums";
import CurrencyInput from "../../../components/currency-input";

export default function DiscountComponent(props) {
  const { customer, service, date, extra, onChange } = props;
  const [manual, setManual] = useState(0);
  const [state, setState] = useState({});
  const [value, setValue] = useState([]);
  const customerGroup = useMemo(() => {
    if (!customer.account.group) return;
    return customer.account.group.benefits.find((b) => b.service === service);
  }, [customer.account.group, service]);
  const isBirthday = useMemo(() => {
    let birthDate = customer.account.birthDate;
    if (!birthDate) return;
    const reqDate = new Date(date);
    birthDate = new Date(birthDate);
    birthDate.setFullYear(reqDate.getFullYear());
    birthDate.setDate(birthDate.getDate() - 1);
    if (reqDate < birthDate) return;

    const end = new Date(birthDate);
    end.setDate(end.getDate() + 11);
    if (reqDate > end) return;
    return true;
  }, [customer.account.birthDate, date]);
  const birthdayDiscount = useMemo(() => {
    return extra.services[service]?.birthdayDiscount;
  }, [extra, service]);
  const serviceDiscount = useMemo(() => {
    return extra.services[service]?.discount;
  }, [extra, service]);

  const handleChange = (e, percentage, amount) => {
    const { name, checked } = e.target;
    setState({ ...state, [name]: checked });
    const newValue = value;
    if (checked) {
      newValue.push({ type: name, percentage, amount });
    } else {
      const idx = newValue.findIndex((v) => v.type === name);
      if (idx !== -1) newValue.splice(idx, 1);
    }
    setValue(newValue);
    onChange(newValue);
  };
  const handleManualChange = (e) => {
    setManual(e.target.value);
    const idx = value.findIndex((v) => v.type === DiscountTypes.Manual);
    if (idx === -1) return;
    const newValue = [...value];
    newValue[idx].amount = e.target.value || 0;
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <>
      <Typography variant="h6">تخفیف</Typography>
      {Boolean(customerGroup) && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.CustomerGroup || false}
                onChange={(e) => handleChange(e, customerGroup.discountPercent)}
                name={DiscountTypes.CustomerGroup}
                color="primary"
              />
            }
            label={`باشگاه مشتریان: ${customer.account.group.title} (${customerGroup.discountPercent}%)`}
          />
        </div>
      )}
      {Boolean(serviceDiscount) && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.Service || false}
                onChange={(e) => handleChange(e, serviceDiscount)}
                name={DiscountTypes.Service}
                color="primary"
              />
            }
            label={`سرویس (${serviceDiscount}%)`}
          />
        </div>
      )}
      {Boolean(isBirthday && birthdayDiscount) && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.Birthday || false}
                onChange={(e) => handleChange(e, birthdayDiscount)}
                name={DiscountTypes.Birthday}
                color="primary"
              />
            }
            label={`تولد (${birthdayDiscount}%)`}
          />
        </div>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.Manual || false}
                onChange={(e) => handleChange(e, undefined, manual)}
                name={DiscountTypes.Manual}
                color="primary"
              />
            }
            label="تخفیف شعبه:"
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            disabled={!state.Manual}
            value={manual}
            inputProps={{ min: props.mileage, className: "text-center" }}
            onChange={handleManualChange}
            InputProps={{
              inputComponent: CurrencyInput,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
