import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import DialogTitle from "../dialog-title";

export default function ImportConfirmModal(props) {
  const { data, onClose, onConfirm } = props;
  if (!data) return null;

  return (
    <Dialog
      open
      maxWidth={"md"}
      fullWidth
      classes={{
        paper: "matte-glass",
      }}
      scroll="paper"
    >
      <DialogTitle onClose={onClose}>اطلاعات</DialogTitle>
      <DialogContent>
        {!!data.errors.length && (
          <Paper className="p-2 mb-2" variant="outlined">
            <Typography variant="h6">خطاها: </Typography>
            {/* <div>
              {data.errors.map((error, idx) => (
                <div key={idx}>
                  <Divider className="my-1" />
                  <Typography>ردیف {error.rowIndex}</Typography>
                  <Typography>{error.arr.join(", ")}</Typography>
                  <Typography>{error.error}</Typography>
                </div>
              ))}
            </div> */}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableCell>ردیف</TableCell>
                  {data.header.map((h, i) => (
                    <TableCell key={i}>{h}</TableCell>
                  ))}
                  <TableCell>توضیحات</TableCell>
                </TableHead>
                <TableBody>
                  {data.errors.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell>{row.rowIndex}</TableCell>
                      {data.header.map((h, i) => (
                        <TableCell key={i}>
                          {JSON.stringify(row.row[h])}
                        </TableCell>
                      ))}
                      <TableCell>{JSON.stringify(row.error)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}

        {!data.validatedData.length ? (
          <Paper className="p-2" variant="outlined">
            <Typography>هیچ داده ای یافت نشد.</Typography>
          </Paper>
        ) : (
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableCell>ردیف</TableCell>
                {data.header.map((h, i) => (
                  <TableCell key={i}>{h}</TableCell>
                ))}
              </TableHead>
              <TableBody>
                {data.validatedData.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{idx + 1}</TableCell>
                    {data.header.map((h, i) => (
                      <TableCell key={i}>{JSON.stringify(row[h])}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          لغو
        </Button>
        <Button color="primary" variant="contained" onClick={onConfirm}>
          ارسال
        </Button>
      </DialogActions>
    </Dialog>
  );
}
