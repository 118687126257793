import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  Tooltip,
  IconButton,
  AppBar,
  Toolbar,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import TableToolbar from "./toolbar";
import TableHeader from "./table-header";
import LoadingComponent from "../loading";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment-jalaali";
import { useForceUpdate } from "../../hooks";

export default function DataTable(props) {
  const {
    actions,
    headCells,
    renderHeader,
    renderRow,
    title,
    rowActions,
    loading,
    searchKeys,
    searchKeysChange,
    headCellsChange,
    setSearchText,
    rows,
    sort,
    setSort,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    totalRows,
    onRefresh,
    extra,
    filterForm,
    importOptions,
    exportOptions,
  } = props;
  const [emptyRows, setEmptyRows] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState();
  const rowHeight = actions ? 41.25 : 33;

  const forceUpdate = useForceUpdate();
  useEffect(() => {
    setEmptyRows(rowsPerPage - rows.length);
  }, [rows, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = sort.orderBy === property && sort.order === "asc";
    setSort({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleCloseModal = (refresh) => {
    setModalOpen(false);
    if (refresh === true || currentAction?.action.refreshOnClose) {
      setTimeout(() => onRefresh(), 500);
    }
  };
  const handleOpenRowModal = (action, data) => {
    if (action.onClick) {
      const refresh = () => {
        forceUpdate();
      };

      action.onClick(data, refresh);
    }
    setCurrentAction({ action, data });
    setModalOpen(true);
  };
  const handleOpenModal = (action) => {
    if (action.onClick) {
      action.onClick();
    }
    setCurrentAction({ action });
    setModalOpen(true);
  };
  const handleSearchTextChange = (val) => {
    setSearchText(val);
  };
  const getDisabled = (action, row) => {
    if (!("disabled" in action)) return;
    if (typeof action.disabled === "function") return !!action.disabled(row);
    return !!action.disabled;
  };
  const getHidden = (action, row) => {
    if (!("hidden" in action)) return;
    if (typeof action.hidden === "boolean") return action.hidden;
    return action.hidden(row);
  };
  const renderActionCell = (row) => {
    if (!rowActions) return null;
    return (
      <TableCell className="w-0">
        <div className="d-flex flex-nowrap">
          {rowActions.map((action, idx) => (
            <Tooltip key={idx} title={action.tooltip || ""}>
              <IconButton
                onClick={() => handleOpenRowModal(action, row)}
                disabled={getDisabled(action, row)}
                hidden={getHidden(action, row)}
                aria-label={action.tooltip}
                size="small"
              >
                {typeof action.icon === "object"
                  ? action.icon
                  : action.icon(row)}
              </IconButton>
            </Tooltip>
          ))}
        </div>
      </TableCell>
    );
  };
  const searchKeyChange = (key) => {
    const keys = JSON.parse(JSON.stringify(searchKeys));
    const sKey = keys.find((k) => k.field === key);
    sKey.active = !sKey.active;
    searchKeysChange(keys);
  };

  return (
    <>
      <Paper className="mt-2 glass">
        <TableToolbar
          title={title}
          actions={actions}
          handleOpenModal={handleOpenModal}
          searchTextChange={handleSearchTextChange}
          searchKeys={searchKeys}
          searchKeyChange={searchKeyChange}
          onRefresh={onRefresh}
          filterForm={filterForm}
          headCellsChange={headCellsChange}
          headCells={headCells}
          importOptions={importOptions}
          exportOptions={exportOptions}
        />
        <TableContainer>
          <Table size="small">
            {renderHeader ? (
              renderHeader()
            ) : (
              <TableHeader
                headCells={headCells}
                order={sort.order}
                orderBy={sort.orderBy}
                onRequestSort={handleRequestSort}
                hasActions={!!rowActions}
              />
            )}
            <TableBody>
              {loading ? (
                <TableRow style={{ height: rowHeight * rowsPerPage }}>
                  <TableCell colSpan={headCells.length + +!!rowActions}>
                    <LoadingComponent />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {rows.map((row) => {
                    if (renderRow) {
                      return renderRow(row, renderActionCell(row));
                    }
                    return (
                      <TableRow key={row.id}>
                        {headCells.map((cell) =>
                          cell.hidden ? null : (
                            <TableCell
                              key={cell.title}
                              component={cell.mainField ? "th" : undefined}
                            >
                              {cell.renderer
                                ? cell.renderer(row[cell.field], row, extra)
                                : cell.date
                                ? row[cell.field]
                                  ? moment(row[cell.field]).format(
                                      "jYYYY/jMM/jDD HH:mm"
                                    )
                                  : undefined
                                : row[cell.field]}
                            </TableCell>
                          )
                        )}
                        {renderActionCell(row)}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: rowHeight * emptyRows }}>
                      <TableCell colSpan={headCells.length + +!!rowActions} />
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 30, 50]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage="تعداد سطرها"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} از ${totalRows}`
          }
        />
      </Paper>
      {currentAction?.action.modalContent ? (
        <Dialog
          open={modalOpen}
          // onClose={handleCloseModal}
          // onClose={(...ar) => console.log(ar)}
          maxWidth={"md"}
          fullWidth
          classes={{
            paper: "matte-glass",
          }}
          {...(currentAction.action.modalProps || {})}
        >
          {currentAction.action.modalProps?.fullScreen && (
            <AppBar className="relative">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleCloseModal}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          )}
          {currentAction?.action.modalContent({
            data: currentAction?.data,
            close: handleCloseModal,
            extra,
          })}
        </Dialog>
      ) : null}
    </>
  );
}
