import React from "react";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import serviceService from "../../../services/service.service";
import yupSchema from "../../../constants/schema";
import DialogFormBuilder from "../../../components/dialog-form-builder";

const schema = Yup.object().shape({
  title: yupSchema.title,
  description: yupSchema.description,
  image: yupSchema.image,
  prePayment: yupSchema.prePayment,
  prices: yupSchema.prices,
});
const emptyValues = {
  title: "",
  description: "",
  image: undefined,
  prices: [],
};

const form = [
  {
    _type: "textField",
    name: "title",
    label: "عنوان",
    autoFocus: true,
  },
  {
    _type: "textField",
    name: "description",
    label: "توضیحات",
    rows: 4,
    multiline: true,
  },
  {
    _type: "textField",
    name: "prePayment",
    type: "number",
    label: "درصد پیش پرداخت %",
    inputProps: {
      min: 0,
      max: 100,
    },
  },
  {
    _type: "textField",
    name: "discount",
    type: "number",
    label: "درصد تخفیف سرویس %",
    inputProps: {
      min: 0,
      max: 100,
    },
  },
  {
    _type: "textField",
    name: "birthdayDiscount",
    type: "number",
    label: "درصد تخفیف تولد %",
    inputProps: {
      min: 0,
      max: 100,
    },
  },
  {
    _type: "filePicker",
    name: "image",
    label: "عکس",
    isImage: true,
  },
  {
    _type: "switch",
    name: "needsAppointment",
    label: "نیاز به نوبت دهی",
  },
  {
    _type: "switch",
    name: "needsCoordination",
    label: "نیاز به هماهنگی",
  },
  {
    _type: "array",
    label: "قیمت ها",
    emptyValue: {
      type: "",
      price: "",
    },
    name: "prices",
    minLength: 0,
    items: [
      {
        _type: "textField",
        name: "type",
        type: "number",
        label: "تیپ",
        inputProps: {
          min: 0,
        },
      },
      {
        _type: "textField",
        name: "price",
        type: "currency",
        label: "قیمت",
      },
    ],
  },
];

export default function FormModalContent({ close, data }) {
  const { id, ...initialValues } = data || {};
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "DuplicateTitle":
        message = "این عنوان تکراری است.";
        variant = "error";
        break;
      case "ServiceNotFound":
        message = "خدمت مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async (model) => {
    let op;
    if (id) {
      op = await serviceService.editService(id, model);
    } else {
      op = await serviceService.createService(model);
    }
    const { error } = op;
    if (error) {
      handleError(error);
    }
    close(true);
  };
  return (
    <DialogFormBuilder
      {...{
        close,
        name: "خدمت",
        data,
        schema,
        initialValues: id ? initialValues : emptyValues,
        handleSubmit,
        form,
      }}
    />
  );
}
