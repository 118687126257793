import React, { useState } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  Container,
  IconButton,
  Hidden,
  Drawer,
  Divider,
  Typography,
  Link as MuiLink,
} from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link, useLocation } from "react-router-dom";
import LOGO from "../../assets/images/armaghan-logo-sm.png";
import MenuIcon from "@material-ui/icons/Menu";
import useStyles from "./styles";
import NestedList from "../nested-list";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Role } from "../../constants/enums";
import ThemeSection from "../theme-section";

const defaultMenuItems = [
  { title: "خانه", route: "/home" },
  { title: "درباره ما", route: "/about-us" },
  { title: "تماس با ما", route: "/contact-us" },
];

export default function Header({ window, blogs }) {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);
  const [drawerOpen, setDrawerOpen] = useState();
  let location = useLocation();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const menuItems = [...defaultMenuItems, ...(blogs || [])];

  useEffect(() => {
    console.log(location);
    setDrawerOpen((o) => false);
  }, [location]);

  const toggleDrawer = () => {
    setDrawerOpen((o) => !o);
  };
  const panelLink = () => {
    switch (account.role) {
      case Role.Admin:
        return "/panel/admin";
      case Role.Coordinator:
        return "/panel/coordinator";
      case Role.Branch:
        return "/panel/branch";
      default:
        return "/panel";
    }
  };

  return (
    <>
      <div className={classes.topBar}>
        <Container>
          <ThemeSection dark noParent>
            <div className={classes.topBarContent}>
              <Link to="/" className={classes.logoSection}>
                <span className={classes.logoContainer}>
                  <img
                    className={classes.logo}
                    src={LOGO}
                    alt="Armaghan Logo"
                  />
                </span>
                <Hidden xsDown>
                  <span className="ms-1">
                    <Typography component="h1" variant="h5">
                      ارمغان
                    </Typography>
                  </span>
                </Hidden>
              </Link>
              <div className="ms-auto">
                <Typography component="div">
                  <Hidden xsDown>
                    <span className="hidden">شماره تماس:</span>{" "}
                  </Hidden>
                  <MuiLink
                    href="tel:09123355103"
                    className="en"
                    // underline={false}
                    color="inherit"
                  >
                    0912 3355 103
                  </MuiLink>
                </Typography>
              </div>
              <ul className={classes.socialBox}>
                <li>
                  <IconButton
                    component="a"
                    href="whatsapp://send?text=Hi!&phone=+989981201100"
                    target="_blank"
                    size="small"
                  >
                    <WhatsAppIcon />
                  </IconButton>
                </li>
                <li>
                  <IconButton
                    component="a"
                    href="https://www.instagram.com/armaghancc"
                    target="_blank"
                    size="small"
                  >
                    <InstagramIcon />
                  </IconButton>
                </li>
              </ul>
              <IconButton size="small">
                <CartIcon />
              </IconButton>
            </div>
          </ThemeSection>
        </Container>
      </div>
      <AppBar
        position="sticky"
        className={classes.stickyHeader}
        color="inherit"
        variant="outlined"
      >
        <Container>
          <Toolbar>
            <Button
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              className="menu-btn"
              startIcon={<MenuIcon />}
            >
              منو
            </Button>
            <Drawer
              container={container}
              variant="temporary"
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div className={classes.drawerContainer}>
                <Toolbar>
                  <div className={classes.drLogoContainer}>
                    <img
                      className={classes.drLogo}
                      src={LOGO}
                      alt="Armaghan Logo"
                    />
                  </div>
                  <div className="ms-1">
                    <Typography component="h1" variant="h5">
                      ارمغان
                    </Typography>
                  </div>
                </Toolbar>
                <Divider />
                <div className={classes.content}>
                  <NestedList data={menuItems} />
                </div>
              </div>
            </Drawer>

            <div className="ms-auto">
              {!account ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  component={Link}
                  to="/login"
                >
                  ورود / عضویت
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="inherit"
                  component={Link}
                  to={panelLink()}
                >
                  پنل کاربری
                </Button>
              )}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
