import { Container, createStyles, Grid, makeStyles } from "@material-ui/core";
import { ReactComponent as Original } from "../../../../assets/images/home/original-products.svg";
import { ReactComponent as Support } from "../../../../assets/images/home/support.svg";
import { ReactComponent as Express } from "../../../../assets/images/home/express-delivery.svg";
import { ReactComponent as Delivery } from "../../../../assets/images/home/cash-on-delivery.svg";
import HomeSpecificationItem from "./item";

const useStyles = makeStyles((theme) => {
  return createStyles({
    root: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(17),
      position: "relative",
      borderBottom: "2px solid #970B0B",
      marginBottom: 16,

      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(10),
      },

      "&::after": {
        content: "''",
        position: "absolute",
        width: "50%",
        maxHeight: theme.spacing(9),
        height: "10vw",
        backgroundColor: theme.palette.background.default,
        bottom: -2,
        right: "66%",
        transform: "skewX(-40deg)",
        borderRight: "2px solid #970B0B",
        borderTop: "2px solid #970B0B",
      },
    },
    title: {
      color: "#222F3D",
      marginBottom: theme.spacing(6),
      fontWeight: 700,
    },
    swiper: {},
    card: {
      padding: 16,
      flexDirection: "column",
      backgroundColor: "#660808cc",
      color: "#fff",
      alignItems: "stretch",
      borderRadius: 16,
      display: "flex",
    },
    imageWrapper: {
      borderRadius: 8,
    },
    cardTitle: {
      fontWeight: 700,
      marginTop: 16,
      marginBottom: 8,
    },
    cardDesk: {},
  });
});
export default function HomeSpecification() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <HomeSpecificationItem
              Icon={Original}
              title="ضمانت اصالت و ضمانت کالا"
            />
          </Grid>
          <Grid item xs={3}>
            <HomeSpecificationItem Icon={Express} title="ارسال سریع" />
          </Grid>
          <Grid item xs={3}>
            <HomeSpecificationItem Icon={Delivery} title="پرداخت امن" />
          </Grid>
          <Grid item xs={3}>
            <HomeSpecificationItem Icon={Support} title="پشتیبانی آنلاین" />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
