import { createStyles, makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) =>
  createStyles({
    topBar: {
      // backgroundColor: theme.palette.background.default,
      backgroundColor: "#222F3D",
      color: "#fff",
      padding: `${theme.spacing(2)}px 0`,
    },
    topBarContent: {
      display: "flex",
      alignItems: "center",
    },
    logoSection: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "inherit !important",
    },
    logoContainer: {
      position: "relative",
      alignSelf: "stretch",
      display: "flex",
      alignItems: "center",
      // marginRight: theme.spacing(9),
    },
    logo: {
      maxWidth: "100%",
      width: theme.spacing(6),
    },
    socialBox: {
      padding: `0 ${theme.spacing(1)}px`,
      margin: `0 ${theme.spacing(1)}px`,
      display: "flex",
      listStyle: "none",
      justifyContent: "flex-end",
      borderRight: "1px solid #fff3",
      borderLeft: "1px solid #fff3",
    },
    stickyHeader: {
      // backgroundColor: "#fff",
      // color: "#000",
    },
    toolbar: {},

    drawerContainer: {
      width: 280,
    },
    drLogoContainer: {
      // margin: "auto",
      transform: "translateY(8%)",
      padding: "8px 0",
    },
    drLogo: {
      // maxWidth: "100%",
      width: 50,
    },
    navContainer: {
      // marginLeft: "auto",
      display: "flex",
      alignItems: "center",
      // "& > *": {
      //   marginLeft: theme.spacing(1),
      // },
    },
  })
);
export default useStyles;
