import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fullPage: {
    height: "100vh",
  },
  overlayContainer: {
    position: "relative",
  },
  overlayProgress: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor:
      theme.type === "dark" ? "rgba(0,0,0,.3)" : "rgba(255,255,255,.3)",
    backdropFilter: theme.type === "dark" ? "blur(3px)" : "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
export default function LoadingComponent({
  fullPage,
  children,
  isLoading,
  className,
  overlay,
}) {
  const classes = useStyles();

  const classNameStr = React.useMemo(() => {
    let str = classes.root;
    if (fullPage) {
      str = str + " " + classes.fullPage;
    }
    if (className) return `${str} ${className}`;
    return str;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullPage, className]);
  if (children && !isLoading) {
    return children;
  }
  if (overlay) {
    return (
      <div className={classes.overlayContainer}>
        {children}
        <div className={classes.overlayProgress}>
          <CircularProgress />
        </div>
      </div>
    );
  }
  return (
    <div className={classNameStr}>
      <CircularProgress />
    </div>
  );
}
