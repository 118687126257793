import React from "react";
import {
  Edit as EditIcon,
  Add as AddIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import DataTable from "../../../components/data-table";
import CreateFormModalContent from "./create-form";
import AnswerFormModalContent from "./answer-form";
import requestService from "../../../services/request.service";
import { Status, StatusMap } from "../../../constants/enums";
import RequestInfo from "../../_shared/request-info";

const headerCells = [
  { field: "code", title: "کد", mainField: true },
  { field: "date", title: "تاریخ", date: true },
  {
    field: "status",
    title: "وضعیت",
    renderer: (val) => StatusMap[val],
  },
  {
    field: "car",
    title: "خودرو",
    renderer: ({ brand, carClass }) => `${brand.name} ${carClass.name}`,
  },
  {
    field: "customer",
    title: "مشتری",
    renderer: ({ account: { firstName, lastName } }) =>
      `${firstName} ${lastName}`,
  },
  {
    field: "service",
    title: "خدمت",
    renderer: (val, row, extra) => extra.services[val]?.title,
  },
  {
    field: "subServices",
    title: "زیر خدمت",
    renderer: (val, row, extra) =>
      val.map((v) => extra.subServices[v]).join(", "),
  },
];

const defaultKeys = [
  { field: "code", title: "کد", active: true },
  { field: "customer.account.phoneNumber", title: "شماره تلفن", active: true },
];

const filterForm = {
  form: [
    {
      _type: "autocomplete",
      _empty: Status.Coordination,
      name: "status",
      options: [
        { title: StatusMap[Status.Coordination], value: Status.Coordination },
        { title: StatusMap[Status.Coordinated], value: Status.Coordinated },
        { title: StatusMap[Status.Rejected], value: Status.Rejected },
        { title: "درخواستهای من", value: "CreatedByMe" },
      ],
      label: "وضعیت",
      disableClearable: true,
      valueSelector: "value",
      labelSelector: "title",
    },
  ],
};

export default function CoordinatorRequests() {
  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        backend={requestService.getRequestsUrl}
        order={{
          order: "asc",
          orderBy: "date",
        }}
        rowActions={[
          {
            hidden: (row) => row.status !== Status.Coordination,
            modalProps: { maxWidth: "sm" },
            icon: <EditIcon />,
            modalContent: (props) => <AnswerFormModalContent {...props} />,
          },
          {
            hidden: (row) => row.status === Status.Coordination,
            modalProps: { maxWidth: "sm" },
            icon: <VisibilityIcon />,
            modalContent: (props) => <RequestInfo {...props} />,
          },
        ]}
        actions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <AddIcon />,
            tooltip: "ایجاد درخواست",
            modalContent: (props) => <CreateFormModalContent {...props} />,
          },
        ]}
        searchKeys={defaultKeys}
        filterForm={filterForm}
        title="درخواست ها"
      />
    </div>
  );
}
