import React, { useMemo } from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Role } from "../../constants/enums";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

export default function AppPanelHeader(props) {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);

  const username = useMemo(() => {
    switch (account.role) {
      case Role.Branch:
        return account.branchName;
      case Role.Admin:
      case Role.Coordinator:
        return account.username;
      case Role.Customer:
        return `${account.firstName} ${account.lastName}`;
      default:
        return "ارمغان";
    }
  }, [account]);

  return (
    <AppBar
      position="sticky"
      className={classes.appBar + " glass"}
      color="inherit"
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={props.toggleDrawer}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Typography className="flex-1" variant="h6" noWrap>
          {username}
        </Typography>
        <IconButton component={Link} to="/home">
          <HomeIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
