import React, { useMemo, useState } from "react";
import { getIn } from "formik";
import {
  TextField,
  Dialog,
  InputAdornment,
  IconButton,
  DialogActions,
  Button,
} from "@material-ui/core";
import DateIcon from "@material-ui/icons/DateRange";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import DateInput from "../../date-input";
import { toGregorianDate, toJalaali } from "../../../helpers/date-helpers";
import { zeroPad } from "../../../helpers";

function DatePicker(props) {
  const {
    form: { errors, setFieldValue = () => {}, submitCount } = {},
    field: { name, value: _value } = {},
    disabled,
    birthDate,
    label,
    onChange,
    ...other
  } = props;
  const value = useMemo(() => {
    if (!_value || _value instanceof Date) return _value;
    return new Date(_value);
  }, [_value]);

  const birthDateRange = useMemo(() => {
    const { year, ...other } = utils("fa").getToday();
    return {
      minimumDate: { ...other, year: year - 100 },
      maximumDate: { ...other, year: year - 15 },
      defaultDate: { ...other, year: year - 27 },
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [objectValue, setObjectValue] = useState(() => {
    if (value) return toJalaali(value);
    if (birthDate) return birthDateRange.defaultDate;
  });

  const valueText = useMemo(() => {
    if (!value) return "";
    const v = toJalaali(value);

    return `${v.year}${zeroPad(v.month)}${zeroPad(v.day)}`;
  }, [value]);

  const handleConfirm = () => {
    setFieldValue(name, toGregorianDate(objectValue));

    setIsOpen(false);
  };
  const handleChange = (v) => {
    if (onChange) onChange(v);
    setObjectValue(v);
  };
  const errorText = getIn(errors, name);
  const hasError = Boolean(errorText && submitCount);
  return (
    <>
      <TextField
        error={hasError}
        helperText={hasError ? errorText : ""}
        onClick={() => setIsOpen(true)}
        margin="dense"
        label={label}
        fullWidth
        value={valueText}
        inputProps={{ className: "en text-center", disabled: true }}
        variant="outlined"
        disabled={disabled}
        InputProps={{
          inputComponent: DateInput,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={disabled} onClick={() => setIsOpen(true)}>
                <DateIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Dialog open={isOpen && !disabled}>
        <Calendar
          {...other}
          value={objectValue}
          locale="fa"
          {...(birthDate ? birthDateRange : {})}
          onChange={handleChange}
          shouldHighlightWeekends
          renderFooter={() => (
            <DialogActions>
              <Button onClick={() => setIsOpen(false)} color="primary">
                لغو
              </Button>
              <Button onClick={handleConfirm} color="primary">
                ذخیره
              </Button>
            </DialogActions>
          )}
        />
      </Dialog>
    </>
  );
}

export default DatePicker;
