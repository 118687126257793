function _arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  bytes.forEach((_, i) => {
    binary += String.fromCharCode(bytes[i]);
  });
  return window.btoa(binary);
}
function getDimensions(imgWidth, imgHeight, options) {
  let width = options.width || imgWidth;
  let height = options.height || imgHeight;
  let ratio = width / height;
  let sx = 0,
    sy = 0,
    sh = imgHeight,
    sw = imgWidth;
  if (options.minRatio && ratio < options.minRatio) {
    ratio = options.minRatio;
    height = width / ratio;
  } else if (options.maxRatio && ratio > options.maxRatio) {
    ratio = options.maxRatio;
    width = height * ratio;
  }
  if (options.maxWidth && width > options.maxWidth) {
    width = options.maxWidth;
    height = width / ratio;
  }
  if (options.maxHeight && height > options.maxHeight) {
    height = options.maxHeight;
    width = height * ratio;
  }
  const changed = imgWidth !== width || imgHeight !== height;
  if (ratio !== imgWidth / imgHeight) {
    if (ratio > imgWidth / imgHeight) {
      sh = imgWidth / ratio;
      sy = (imgHeight - sh) / 2;
    } else {
      sw = imgHeight * ratio;
      sx = (imgWidth - sw) / 2;
    }
  }
  return { width, height, changed, sx, sy, sh, sw };
}
function changeExtension(name, ext) {
  if (!ext) return name;
  const pos = name.lastIndexOf(".");
  return name.substr(0, pos < 0 ? name.length : pos) + "." + ext;
}
export function compressImage(file, options = {}) {
  const fileName = changeExtension(file.name, options.type);
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      if (!event.target || !event.target.result) {
        //TODO: return error;
        reject();
        return;
      }
      let { result } = event.target;
      if (typeof result !== "string") {
        result = _arrayBufferToBase64(result);
      }
      img.src = result;
      img.onload = () => {
        const { width, height, changed, sh, sw, sx, sy } = getDimensions(
          img.width,
          img.height,
          options
        );
        if (
          !changed &&
          (!options.type || "image/" + options.type === file.type)
        ) {
          resolve({ newFile: file, src: result });
          return;
        }
        const elem = document.createElement("canvas");
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext("2d");
        if (!ctx) {
          //TODO: return error;
          reject();
          return;
        }
        ctx.drawImage(img, sx, sy, sw, sh, 0, 0, width, height);
        const type = options.type ? `image/${options.type}` : file.type;
        const src = ctx.canvas.toDataURL(type);
        ctx.canvas.toBlob(
          (blob) => {
            if (!blob) {
              //TODO: return error;
              reject();
              return;
            }
            const newFile = new File([blob], fileName, {
              type,
              lastModified: Date.now(),
            });
            resolve({ newFile, src });
          },
          file.type,
          1
        );
      };
      reader.onerror = (error) => {
        console.log(error);
        reject(error);
      };
    };
  });
}
