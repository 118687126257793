import React, { useEffect, useState } from "react";
import httpService from "../../services/http.service";
import { useDebouncedEffect, useDidUpdateEffect } from "../../hooks";
import Table from "./table";
import { stableSort, deepFind } from "./sort-helpers";
/**
 * @param {Object} props
 *
 * @param {Object[]} props.actions
 * @param {string} props.actions[].tooltip
 * @param {Object} props.actions[].icon
 * @param {requestCallback} props.actions[].modalContent
 * @param {requestCallback} props.actions[].onClick
 *
 * @param {Object[]} props.headCells
 * @param {string} props.headCells[].field
 * @param {string} props.headCells[].title
 * @param {boolean} props.headCells[].mainField
 * @param {requestCallback} props.headCells[].renderer
 *
 * @param {Object[]} props.rowActions
 * @param {string} props.rowActions[].tooltip
 * @param {Object | requestCallback} props.rowActions[].icon
 * @param {requestCallback} props.rowActions[].modalContent
 * @param {requestCallback} props.rowActions[].onClick
 *
 * @param {Object[]} props.searchKeys
 * @param {string} props.searchKeys[].title
 * @param {string} props.searchKeys[].field
 * @param {boolean} props.searchKeys[].active
 *
 * @params {Object[]} props.filterForm
 * @params {string} props.filterForm[].title
 * @params {string} props.filterForm[]._type
 * @params {any} props.filterForm[]._wrapper
 * @params {boolean} props.filterForm[]._hidden
 *
 * @param {requestCallback} props.renderHeader
 * @param {requestCallback} props.renderRow
 * @param {string} props.title
 *
 * @param {string} props.backend
 * @param {requestCallback} props.handleBackendError
 *
 * @param {requestCallback} props.onRefresh
 * @param {Object[]} props.data
 */
export default function DataTable(props) {
  const {
    headCells: defaultHeadCells,
    backend,
    handleBackendError,
    data = [],
    onRefresh,
    searchKeys: defaultSearchKeys,
    orderBy,
    order,
    ...other
  } = props;
  const [loading, setLoading] = useState();
  const [searchKeys, setSearchKeys] = useState(defaultSearchKeys);
  const [headCells, setHeadCells] = useState(defaultHeadCells);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [sort, setSort] = useState(
    order || {
      order: "desc",
      orderBy: orderBy || headCells[0].field,
    }
  );

  const generalProps = {
    searchKeys,
    searchKeysChange: (keys) => {
      setSearchKeys(keys);
    },
    headCellsChange: setHeadCells,
    headCells,
    loading,
    setLoading,
    sort,
    setSort,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    searchText,
    setSearchText,
    rows,
    setRows,
    ...other,
  };
  const refresh = async () => {
    setLoading(true);
    await onRefresh(setLoading);
    setLoading(false);
  };
  if (backend) {
    return (
      <Online
        backend={backend}
        handleBackendError={handleBackendError}
        {...generalProps}
      />
    );
  }
  return (
    <Offline
      data={data}
      onRefresh={onRefresh ? refresh : undefined}
      {...generalProps}
    />
  );
}

function Online(props) {
  const {
    backend,
    handleBackendError,
    setLoading,
    sort,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    searchText,
    setSearchText,
    setRows,
    searchKeys,
    filterForm,
    ...other
  } = props;
  const [extra, setExtra] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [_searchText, _setSearchText] = useState(searchText || "");
  const [filterFormValues, setFilterFormValues] = useState(() => {
    if (filterForm && !filterForm.initialValues) {
      filterForm.initialValues = {};
      filterForm.form.forEach((el) => {
        const val = el._empty || "";
        delete el._empty;
        filterForm.initialValues[el.name] = val;
      });
    }
    return filterForm?.initialValues;
  });

  useDebouncedEffect(() => _setSearchText(searchText), 500, [searchText]);

  useDidUpdateEffect(() => {
    getData(true);
  }, [
    sort,
    page,
    rowsPerPage,
    searchKeys,
    _searchText,
    backend,
    filterFormValues,
  ]);
  useEffect(() => {
    if (filterForm) {
      filterForm.handleSubmit = (values) => {
        setFilterFormValues(values);
      };
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (skipExtra) => {
    if (!backend) return;
    setLoading(true);
    const { order, orderBy } = sort;
    const filterFields = [];
    if (searchKeys) {
      searchKeys.forEach((k) => {
        if (k.active) filterFields.push(k.field);
      });
    }
    const { data, error } = await httpService.get(backend, {
      params: {
        page,
        rowsPerPage,
        orderBy,
        order,
        filterText: _searchText,
        filterFields,
        filter: filterFormValues,
        getExtra: skipExtra !== true,
      },
    });
    if (error) {
      setLoading(false);
      if (!handleBackendError) return;
      return handleBackendError(error, data);
    }
    setRows(data.rows);
    if (data.extra) setExtra(data.extra);
    setPage(data.page);
    setRowsPerPage(data.rowsPerPage);
    setTotalRows(data.totalRows);
    setLoading(false);
  };

  return (
    <Table
      {...{
        setSearchText,
        sort,
        page,
        setPage,
        rowsPerPage,
        setRowsPerPage,
        totalRows,
        onRefresh: getData,
        searchKeys,
        extra,
        filterForm,
        ...other,
      }}
    />
  );
}
function Offline(props) {
  const {
    data,
    sort,
    page,
    setPage,
    rowsPerPage,
    searchText,
    rows,
    setRows,
    searchKeys,
    onRefresh,
    ...other
  } = props;
  const [filteredRows, setFilteredRows] = useState([]);
  const [sortedRows, setSortedRows] = useState([]);

  useEffect(() => {
    if (!searchText) {
      setFilteredRows(data);
      return;
    }
    const newData = data.filter((item) => {
      if (!searchKeys || !searchKeys.length) {
        return JSON.stringify(item).indexOf(searchText) !== -1;
      }
      return (
        searchKeys
          .filter((k) => k.active)
          .findIndex((key) => {
            let value = deepFind(item, key.field);
            if (typeof value !== "string") {
              value = JSON.stringify(value) || "";
            }
            return value.indexOf(searchText) !== -1;
          }) !== -1
      );
    });
    if (
      newData.length !== rows.length ||
      JSON.stringify(newData) !== JSON.stringify(rows)
    ) {
      setFilteredRows(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchKeys, searchText]);

  useEffect(() => {
    if (!filteredRows.length && !sortedRows.length) return;
    const newRows = stableSort(filteredRows, sort.order, sort.orderBy);
    setPage(1);
    setSortedRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRows, sort]);

  useEffect(() => {
    if (!sortedRows.length && !rows.length) return;
    const start = (page - 1) * rowsPerPage;
    const newRowsInPage = sortedRows.slice(start, start + rowsPerPage);
    setRows(newRowsInPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedRows, page, rowsPerPage]);

  return (
    <Table
      {...{
        searchText,
        rows,
        sort,
        page,
        setPage,
        rowsPerPage,
        totalRows: data.length,
        onRefresh,
        searchKeys,
        ...other,
      }}
    />
  );
}
