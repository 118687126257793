import {
  ButtonBase,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CAR from "../../../../assets/images/home/car.png";
import WHEEL from "../../../../assets/images/home/car-wheel.png";
import { ReactComponent as Carwash } from "../../../../assets/images/home/carwash.svg";
import { ReactComponent as AutoService } from "../../../../assets/images/home/autoservice.svg";
import { ReactComponent as Detailing } from "../../../../assets/images/home/detailing.svg";
import { ReactComponent as Mechanic } from "../../../../assets/images/home/mechanic.svg";
import { ReactComponent as Smoothing } from "../../../../assets/images/home/smoothing.svg";
import clsx from "clsx";
import { useLayoutEffect, useState } from "react";
import { Link } from "react-router-dom";

const services = [
  {
    icon: AutoService,
    title: "اتوسرویس",
    // url: "/blog/اتوسرویس",
    url: "/panel/my-cars",
  },
  {
    icon: Smoothing,
    title: "صافکاری نقاشی",
    // url: "/blog/صافکاری%20نقاشی",
    url: "/panel/my-cars",
  },
  {
    icon: Carwash,
    title: "کارواش",
    // url: "/blog/کارواش",
    url: "/panel/my-cars",
  },
  {
    icon: Mechanic,
    title: "مکانیکی",
    // url: "/blog/مکانیکی",
    url: "/panel/my-cars",
  },
  {
    icon: Detailing,
    title: "دیتیلینگ",
    // url: "/blog/دیتیلینگ",
    url: "/panel/my-cars",
  },
];

const useStyles = makeStyles((theme) => {
  const width = 692;
  const height = 324;
  const wheelSize = 95;
  const frontLeft = 60;
  const backLeft = 500;
  const bottom = 67;

  return createStyles({
    root: {
      margin: `${theme.spacing(14)}px ${theme.spacing(2)}px ${theme.spacing(
        25
      )}px`,
      [theme.breakpoints.down("sm")]: {
        margin: `19% ${theme.spacing(2)}px 31%`,
      },
    },
    mainContent: {
      width: 692,
      maxWidth: "62%",
      margin: "auto",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "90%",
      },
    },
    mainCar: {
      paddingTop: `${(height * 100) / width}%`,
      position: "relative",
      transition: "all 1s ease 1s",
      transform: "translateX(-100vw)",
      "& img": {
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      },
    },
    wheel: {
      width: `${(wheelSize * 100) / width}%`,
      height: `${(wheelSize * 100) / height}%`,
      bottom: `${(bottom * 100) / height}%`,
      position: "absolute",
      transition: "all 1s ease 1s",
      transform: "rotate(-560deg)",
    },
    frontWheel: { right: `${(frontLeft * 100) / width}%` },
    backWheel: { right: `${(backLeft * 100) / width}%` },

    serviceBtnContainer: {
      position: "absolute",
      transition: "all 300ms",
      opacity: 0,
      width: 150,
      maxWidth: "22%",
      "&::before": {
        content: "''",
        position: "absolute",
        width: "66%",
        height: 1,

        backgroundImage:
          "linear-gradient(to right, #6186B0 50%, rgba(255,255,255,0) 0%)",
        backgroundPosition: "bottom",
        backgroundSize: "13px 1px",
        backgroundRepeat: "repeat-x",
      },
      "&:nth-child(1)": {
        bottom: "100%",
        right: "100%",
        transform: "translateY(50%)",
        "&::before": {
          top: "55%",
          left: "80%",
          transform: "rotate(27deg)",
          transformOrigin: "left",
        },
      },
      "&:nth-child(2)": {
        bottom: "100%",
        left: "100%",
        transform: "translateY(50%)",
        "&::before": {
          top: "55%",
          right: "80%",
          transform: "rotate(-27deg)",
          transformOrigin: "right",
        },
      },
      "&:nth-child(3)": {
        top: "100%",
        right: "100%",
        transform: "translate(-30%, -50%)",
        "&::before": {
          top: "45%",
          left: "80%",
          transform: "rotate(-27deg)",
          transformOrigin: "left",
        },
      },
      "&:nth-child(4)": {
        top: "100%",
        left: "50%",
        transform: "translate(-50%, 10%)",
        "&::before": {
          bottom: "80%",
          right: "50%",
          transform: "rotate(90deg)",
          transformOrigin: "right",
        },
      },
      "&:nth-child(5)": {
        top: "100%",
        left: "100%",
        transform: "translate(30%, -50%)",
        "&::before": {
          top: "45%",
          right: "80%",
          transform: "rotate(27deg)",
          transformOrigin: "right",
        },
      },
      [theme.breakpoints.down("sm")]: {
        "&:nth-child(1)": {
          transform: "translate(120%, 10%)",
          "&::before": {
            transform: "rotate(40deg) !important",
          },
        },
        "&:nth-child(2)": {
          transform: "translate(-120%, 10%)",
          "&::before": {
            transform: "rotate(-40deg) !important",
          },
        },
        "&:nth-child(3)": {
          transform: "translate(100%, -10%)",
          "&::before": {
            transform: "rotate(-40deg) !important",
          },
        },
        "&:nth-child(4)": {
          transform: "translate(-50%, 30%)",
        },
        "&:nth-child(5)": {
          transform: "translate(-100%, -10%)",
          "&::before": {
            transform: "rotate(40deg) !important",
          },
        },
      },
    },

    serviceBtnWrapper: {
      paddingTop: "100%",
    },
    serviceBtn: {
      border: "1px solid #6186B0",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      flexDirection: "column",
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      textAlign: "center",
      transition: "all 200ms",
      background: "#fff",
      position: "absolute",
      top: 0,
      left: 0,
      "&:hover": {
        transform: "scale(1.2)",
      },
    },
    btnText: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.5vw",
        lineHeight: 1,
        marginTop: 4,
      },
    },
    animate: {
      "& $mainCar": {
        transform: "translateX(0)",
      },
      "& $wheel": {
        transform: "rotate(0)",
      },
      "& $serviceBtnContainer": {
        opacity: 1,
        "&:nth-child(1)": {
          transitionDelay: "2900ms",
        },
        "&:nth-child(2)": {
          transitionDelay: "2000ms",
        },
        "&:nth-child(3)": {
          transitionDelay: "2300ms",
        },
        "&:nth-child(4)": {
          transitionDelay: "3200ms",
        },
        "&:nth-child(5)": {
          transitionDelay: "2600ms",
        },
      },
    },
  });
});
export default function HomeCar() {
  const classes = useStyles();
  const [animate, setAnimate] = useState();

  useLayoutEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <div className={classes.root}>
      <div
        className={clsx({
          [classes.mainContent]: true,
          [classes.animate]: animate,
        })}
      >
        <div className={classes.mainCar}>
          <img src={CAR} alt="" />
          <div className={clsx(classes.wheel, classes.frontWheel)}>
            <img src={WHEEL} alt="" />
          </div>
          <div className={clsx(classes.wheel, classes.backWheel)}>
            <img src={WHEEL} alt="" />
          </div>
        </div>
        <div>
          {services.map(({ icon: Icon, url, title }, idx) => (
            <div className={classes.serviceBtnContainer} key={idx}>
              <div className={classes.serviceBtnWrapper}>
                <ButtonBase
                  className={classes.serviceBtn}
                  component={Link}
                  to={url}
                >
                  <Icon />
                  <Typography className={classes.btnText}>{title}</Typography>
                </ButtonBase>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
