import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Container,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableFooter,
  Divider,
  Grid,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useParams, useLocation, Link } from "react-router-dom";
import requestService from "../../services/request.service";
import ThemeSection from "../../components/theme-section";
import Loading from "../../components/loading";
import LOGO from "../../assets/images/armaghan-logo.png";
import moment from "moment-jalaali";
import { useSelector } from "react-redux";
import { currencyFormat } from "../../helpers";
import NotFoundPage from "../../components/404";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    logoContainer: {
      width: 200,
    },
    img: {
      width: 400,
    },
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
    },
    divider: {
      margin: `${theme.spacing(2)}px -${theme.spacing(2)}px`,
    },
  })
);

function GridItemText({ value, label, valueClassName }) {
  return (
    <Grid className="col-print-3" item xs={12} sm={6} md={3}>
      <Typography>
        {label}: <span className={valueClassName}> {value} </span>
      </Typography>
    </Grid>
  );
}
function GridContainer({ children }) {
  return (
    <Grid container spacing={2}>
      {children}
    </Grid>
  );
}
export default function InvoicePage() {
  const classes = useStyles();
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const [waiting, setWaiting] = useState(true);
  const [data, setData] = useState();
  const location = useLocation();
  const { field, code } = useParams();
  const { account } = useSelector((state) => state.auth);

  useEffect(() => {
    if (location.state && location.state.code === code) {
      setData(location.state);
      setWaiting(false);
      return;
    }
    setWaiting(true);
    switch (field) {
      case "request":
        requestService
          .getRequest(code)
          .then(({ data }) => {
            if (data) setData(data);
            setWaiting(false);
          })
          .catch(() => setWaiting(false));
        break;
      default:
        setWaiting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);
  return (
    <Loading isLoading={waiting} fullPage>
      {data ? (
        <Container>
          <ThemeSection noParent>
            <Paper className="my-5 p-2" ref={printRef}>
              <div>
                <div className={classes.header}>
                  <div className={classes.logoContainer}>
                    <img src={LOGO} alt="armaghan-logo" />
                  </div>
                  <Typography>
                    تاریخ:{" "}
                    {moment(data.invoice.transaction.date).format(
                      "jYYYY/jMM/jDD HH:mm"
                    )}
                  </Typography>
                </div>

                <Divider className={classes.divider} />
                <div className="simple-card">
                  <GridContainer>
                    <Grid item xs={12}>
                      <Typography variant="h6">مشخصات درخواست</Typography>
                    </Grid>

                    <GridItemText
                      label="کد"
                      value={data.code}
                      valueClassName="en"
                    />
                    {data.service.id ? (
                      <>
                        <GridItemText
                          label="تاریخ"
                          value={moment(data.date).format(
                            "jYYYY/jMM/jDD HH:mm"
                          )}
                        />
                        <GridItemText label="خدمت" value={data.service.title} />
                        <GridItemText
                          label="زیر خدمت ها"
                          value={data.subServices
                            .map(
                              (s) =>
                                data.service.subServices.find(
                                  ({ id }) => id === s
                                )?.title
                            )
                            .join(", ")}
                        />
                      </>
                    ) : null}
                  </GridContainer>
                </div>
                <Divider className={classes.divider} />
                <div className="simple-card">
                  <GridContainer>
                    <Grid item xs={12}>
                      <Typography variant="h6">مشخصات مالک</Typography>
                    </Grid>
                    <GridItemText label="کد" value={account.code} />
                    <GridItemText
                      label="شماره تلفن"
                      value={account.phoneNumber}
                      valueClassName="en"
                    />
                    <GridItemText label="نام" value={account.firstName} />
                    <GridItemText
                      label="نام خانوادگی"
                      value={account.lastName}
                    />
                  </GridContainer>
                </div>
                <Divider className={classes.divider} />
                <div className="simple-card">
                  <GridContainer>
                    <Grid item xs={12}>
                      <Typography variant="h6">مشخصات خودرو</Typography>
                    </Grid>
                    <GridItemText label="برند" value={data.car.brand.name} />
                    <GridItemText label="مدل" value={data.car.carClass.name} />
                    <GridItemText
                      label="شماره شاسی"
                      value={data.car.chassisNumber}
                    />
                    <GridItemText label="رنگ" value={data.car.color} />
                    <GridItemText label="کیلومتر" value={data.car.mileage} />
                    <GridItemText label="سال" value={data.car.year} />
                  </GridContainer>
                </div>

                {data.branch?.account?.contact && (
                  <>
                    <Divider className={classes.divider} />
                    <div className="simple-card">
                      <GridContainer>
                        <Grid item xs={12}>
                          <Typography variant="h6">شعبه و زمان</Typography>
                        </Grid>
                        <GridItemText
                          label="مدیریت"
                          value={data.branch.account.manager}
                        />
                        <GridItemText
                          label="شماره تلفن"
                          value={data.branch.account.contact.phoneNumbers.join(
                            ", "
                          )}
                          valueClassName="en"
                        />
                        <GridItemText
                          sm={12}
                          label="آدرس"
                          value={data.branch.account.contact.address}
                        />

                        {data.appointment && (
                          <GridItemText
                            sm={12}
                            label="زمان"
                            value={moment(data.appointment.time).format(
                              data.appointment.title
                            )}
                          />
                        )}
                      </GridContainer>
                    </div>
                  </>
                )}
                <Divider className={classes.divider} />
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ردیف</TableCell>
                        <TableCell>عنوان</TableCell>
                        <TableCell>مبلغ</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.invoice.items.map((item, index) => (
                        <TableRow key={item.id}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.item}</TableCell>
                          <TableCell>
                            {currencyFormat(item.amount, {
                              rials: true,
                            })}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={1} />
                        <TableCell>مبلغ کل</TableCell>
                        <TableCell>
                          {currencyFormat(data.invoice.total, {
                            rials: true,
                          })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={1} />
                        <TableCell>مبلغ پیش پرداخت</TableCell>
                        <TableCell>
                          <Typography variant="h6" color="textPrimary">
                            {currencyFormat(data.invoice.prePayment, {
                              rials: true,
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                  <Button
                    className="mt-2 no-print"
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/panel/requests"
                  >
                    بازگشت به پنل
                  </Button>
                  <Button
                    className="mt-2 mx-2 no-print"
                    variant="contained"
                    color="primary"
                    onClick={handlePrint}
                  >
                    پرینت
                  </Button>
                </div>
              </div>
            </Paper>
          </ThemeSection>
        </Container>
      ) : (
        <ThemeSection noParent>
          <NotFoundPage fixed />
        </ThemeSection>
      )}
    </Loading>
  );
}
