import React from "react";
import AppPanel from "../../components/app-panel";
import {
  People as CustomersIcon,
  DirectionsCar as CarsIcon,
  EmojiTransportation as RequestsIcon,
} from "@material-ui/icons";

const menuItems = [
  {
    title: "درخواست ها",
    route: "/panel/coordinator/requests",
    icon: <RequestsIcon />,
  },
  {
    title: "مشتریان",
    route: "/panel/coordinator/customers",
    icon: <CustomersIcon />,
  },
  {
    title: "خودرو مشتریان",
    route: "/panel/coordinator/cars",
    icon: <CarsIcon />,
  },
];

const CoordinatorPanel = ({ children }) => {
  return <AppPanel menuItems={menuItems}>{children}</AppPanel>;
};

export default CoordinatorPanel;
