import React from "react";
import { makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { lightTheme, theme as darkTheme } from "../../theme";

const useStyles = makeStyles((theme) => ({
  light: {
    backgroundColor: lightTheme.palette.background.default,
    color: lightTheme.palette.text.primary,
  },
  dark: {
    backgroundColor: darkTheme.palette.background.default,
    color: darkTheme.palette.text.primary,
  },
}));
export default function ThemeSection({
  children,
  className,
  noParent,
  dark,
  component: Component = "div",
}) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      {noParent ? (
        children
      ) : (
        <Component
          className={`${dark ? classes.dark : classes.light} ${
            className || ""
          }`}
        >
          {children}
        </Component>
      )}
    </ThemeProvider>
  );
}
