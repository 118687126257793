import httpService from "./http.service";

class BlogService {
  getBlogsUrl = "/blogs";

  createBlog = (model) => {
    return httpService.form("/blogs", model, "createBlog");
  };
  editBlog = (id, model) => {
    return httpService.form("/blogs/" + id, model, "editBlog", {}, "put");
  };
  deleteBlog = (id) => {
    return httpService.delete("/blogs/" + id);
  };
  toggleHide = (id) => {
    return httpService.put("/blogs/toggle-hide/" + id);
  };
  getCategorizedBlogs = () => {
    return httpService.get("/blogs/categorized");
  };
  getContent = (title) => {
    return httpService.get("/blogs/content/" + title);
  };
  getLatests = () => {
    return httpService.get("/blogs/latests");
  };
}
const blogService = new BlogService();
export default blogService;
