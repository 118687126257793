import React, { useMemo, useState } from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import DataTable from "../../../components/data-table";
import CreateFormModalContent from "./create-form";
import EditFormModalContent from "./edit-form";
import appointmentService from "../../../services/appointment.service";
import ConfirmModalContent from "../../../components/confirm-modal-content";
import { Autocomplete } from "../../../components/formik-material-ui";
import { useSelector } from "react-redux";
import moment from "moment-jalaali";

const headerCells = [
  {
    field: "title",
    title: "عنوان",
    renderer: (title, { time }) => moment(time).format(title),
    mainField: true,
  },
  { field: "time", title: "زمان", date: true },
];

export default function AppointmentsPage() {
  const { account } = useSelector((state) => state.auth);
  const services = useMemo(() => {
    return account.services
      .filter((s) => s.needsAppointment)
      .map(({ title, id }) => ({ title, value: id }));
  }, [account]);
  const [service, setService] = useState(
    services.length > 1 ? undefined : services[0]
  );

  const deleteItem = (item) => {
    return appointmentService.deleteAppointment(item.id);
  };
  return (
    <div className="p-3">
      {services.length > 1 && (
        <Grid container className="pb-2">
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              valueSelector="value"
              labelSelector="title"
              label="خدمت"
              onChange={setService}
              value={service}
              options={services}
            />
          </Grid>
        </Grid>
      )}
      {service && (
        <DataTable
          headCells={headerCells}
          orderBy="time"
          backend={
            service
              ? appointmentService.getAppointmentsUrl + service.value
              : undefined
          }
          rowActions={[
            {
              modalProps: { maxWidth: "sm" },
              icon: <EditIcon />,
              modalContent: (props) => <EditFormModalContent {...props} />,
            },
            {
              modalProps: { maxWidth: "sm" },
              icon: <DeleteIcon />,
              modalContent: (props) => (
                <ConfirmModalContent
                  onConfirm={() => deleteItem(props.data)}
                  description="برای حذف تایید کنید."
                  close={props.close}
                />
              ),
            },
          ]}
          actions={[
            {
              icon: <AddIcon />,
              tooltip: "ایجاد ساعت کاری",
              modalContent: (props) => (
                <CreateFormModalContent
                  subServiceId={service.value}
                  {...props}
                />
              ),
            },
          ]}
          title="ساعت کاری"
        />
      )}
    </div>
  );
}
