import React, { useState } from "react";
import {
  DirectionsCar as CarIcon,
  PermIdentity as ProfileIcon,
  EmojiTransportation as RequestsIcon,
} from "@material-ui/icons";
import Reminders from "../../components/reminders";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import BACKGROUND_IMG from "../../assets/images/login-background.jpg";
import { AppBar, IconButton, Toolbar, Button, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import AccountIcon from "@material-ui/icons/AccountCircle";
import LOGO from "../../assets/images/armaghan-logo.png";
import { useSelector } from "react-redux";
import AppPanelSideBar from "../../components/app-panel-side-bar";

const menuItems = [
  {
    title: "خودروهای من",
    route: "/panel/my-cars",
    icon: <CarIcon />,
  },
  {
    title: "درخواست ها",
    route: "/panel/requests",
    icon: <RequestsIcon />,
  },
  {
    title: "پروفایل",
    route: "/panel/profile",
    icon: <ProfileIcon />,
  },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: `url(${BACKGROUND_IMG}) center no-repeat fixed`,
      backgroundSize: "cover",
    },
    overlay: {
      display: "flex",
      backgroundColor: theme.palette.panelBg.main,
      minHeight: "100vh",
    },
    content: {
      flexGrow: 1,
      minWidth: 0,
    },
    logo: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& img": {
        maxWidth: "100%",
        maxHeight: 45,
      },
    },
  })
);
const CustomerPanel = ({ children }) => {
  const classes = useStyles();
  const { account } = useSelector((state) => state.auth);
  const [drawerOpen, setDrawerOpen] = useState();

  return (
    <div className={classes.root}>
      <div className={classes.overlay}>
        <Hidden mdUp implementation="js">
          <AppPanelSideBar
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            menuItems={menuItems}
          />
        </Hidden>
        <main className={classes.content}>
          <AppBar
            position="sticky"
            className={classes.appBar + " glass"}
            color="inherit"
          >
            <Toolbar>
              <Hidden smDown>
                <div className={classes.logo}>
                  <img src={LOGO} alt="" />
                </div>
                <Button className="ms-2" to="/panel/my-cars" component={Link}>
                  خودروهای من
                </Button>
                <Button className="ms-2" to="/panel/requests" component={Link}>
                  درخواست ها
                </Button>
              </Hidden>
              <Hidden mdUp>
                <IconButton onClick={() => setDrawerOpen(true)}>
                  <MenuIcon />
                </IconButton>
              </Hidden>

              <Button
                variant="outlined"
                className="ms-auto me-2"
                component={Link}
                to="/panel/profile"
                endIcon={<AccountIcon />}
              >
                <span>
                  {account.firstName} {account.lastName}
                </span>
              </Button>
              {/* <IconButton component={Link} to="/panel/profile">
                <AccountIcon />
              </IconButton> */}
              <IconButton component={Link} to="/home">
                <HomeIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {children}
          <Reminders />
        </main>
      </div>
    </div>
  );
};

export default CustomerPanel;
