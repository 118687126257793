import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { NavLink, useHistory } from "react-router-dom";

const findRoute = (tree, route) => {
  let arr = undefined;
  const find = (node, routeArr) => {
    for (let i = 0; i < node.length; i++) {
      const r = node[i];

      if (r.subList) {
        find(r.subList, [...routeArr, i]);
      } else if (r.route === route) {
        arr = [...routeArr, i];
        break;
      }
    }
  };
  find(tree, []);
  return arr;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

export default function NestedList({ data, notRoot, level, initialOpen }) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState();
  const [childInitialOpen, setChildInitialOpen] = React.useState([]);
  useEffect(() => {
    const arr =
      (notRoot ? initialOpen : findRoute(data, history.location.pathname)) ||
      [];
    const [thisChild, ...child] = arr;
    setOpen(thisChild);
    setChildInitialOpen(child);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClick = ({ subList, onClick }, index) => {
    if (subList) {
      setOpen(index === open ? undefined : index);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <List component={notRoot ? "div" : "nav"} disablePadding={notRoot}>
      {data.map((item, index) => {
        if (item.divider) {
          return <Divider key={`${index}`} className="my-1" />;
        }
        const otherProps = item.route
          ? {
              component: NavLink,
              to: item.route,
              activeClassName: "Mui-selected",
            }
          : {};
        return (
          <React.Fragment key={item.title}>
            <ListItem
              button
              onClick={() => handleClick(item, index)}
              {...otherProps}
              className={level ? classes.nested : ""}
            >
              {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : null}
              <ListItemText primary={item.title} />
              {item.subList ? (
                open === index ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItem>
            {item.subList ? (
              <Collapse in={open === index} timeout="auto">
                <NestedList
                  data={item.subList}
                  level={(level || 0) + 1}
                  notRoot
                  initialOpen={open === index ? childInitialOpen : undefined}
                />
              </Collapse>
            ) : null}
          </React.Fragment>
        );
      })}
    </List>
  );
}
