import React from "react";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import yupSchema from "../../../../constants/schema";
import customerService from "../../../../services/customer.service";
import DialogFormBuilder from "../../../../components/dialog-form-builder";

const initialValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  code: "",
  group: "",
};
const schema = Yup.object().shape({
  firstName: yupSchema.firstName,
  lastName: yupSchema.lastName,
  phoneNumber: yupSchema.phoneNumber,
  code: yupSchema.customerCode,
});
const form = [
  {
    _type: "textField",
    name: "firstName",
    label: "نام",
    autoFocus: true,
  },
  {
    _type: "textField",
    name: "lastName",
    label: "نام خانوادگی",
  },
  {
    _type: "textField",
    name: "phoneNumber",
    label: "شماره تلفن",
    type: "phone",
    className: "code-text-field",
  },
  {
    _type: "textField",
    name: "code",
    label: "کد",
  },
  {
    _type: "datePicker",
    name: "birthDate",
    label: "تاریخ تولد",
    birthDate: true,
  },
  {
    _type: "autocomplete",
    name: "group",
    backend: customerService.customerGroupsAC,
    valueSelector: "value",
    labelSelector: "title",
    label: "گروه مشتریان",
  },
];
export default function FormModalContent({ close, data }) {
  const newData = { ...data };
  if (newData.group) {
    newData.group = newData.group.id;
  }
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "PhoneNumberTaken":
        message = "این شماره تلفن ثبت شده است.";
        variant = "error";
        break;
      case "CodeTaken":
        message = "این کد تکراری است.";
        variant = "error";
        break;
      case "CustomerNotFound":
        message = "مشتری مورد نظر یافت نشد.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async (model, { setSubmitting }) => {
    model = schema.cast(model);
    let op;
    if (data && data.id) {
      op = await customerService.editCustomer(data.id, model);
    } else {
      op = await customerService.createCustomer(model);
    }
    const { error } = op;
    setSubmitting(false);
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };
  return (
    <DialogFormBuilder
      {...{
        close,
        name: "مشتری",
        data: newData,
        schema,
        initialValues,
        handleSubmit,
        form,
      }}
    />
  );
}
