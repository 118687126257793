import httpService from "./http.service";

class ServiceService {
  getServicesUrl = "/services";
  servicesAC = "/services/ac";

  createService = (model) => {
    return httpService.form("/services", model, "createService");
  };
  editService = (id, model) => {
    return httpService.form("/services/" + id, model, "editService", {}, "put");
  };
  deleteService = (id) => {
    return httpService.delete("/services/" + id);
  };
  toggleHide = (id) => {
    return httpService.put("/services/toggle-hide/" + id);
  };
}

const serviceService = new ServiceService();
export default serviceService;
