import React from "react";
import { FastField, Field } from "formik";
import { Autocomplete } from "../../formik-material-ui";

export default function FAutocomplete({ onChange, fast, ...other }) {
  const allProps = {
    component: Autocomplete,
    onChange: onChange,
    ...other,
  };

  if (fast !== false) {
    return <FastField {...allProps} />;
  }
  return <Field {...allProps} />;
}
