import React from "react";
import { useSelector } from "react-redux";
import AppPanel from "../../components/app-panel";
import {
  EventNote as DateIcon,
  People as CustomersIcon,
  EmojiTransportation as RequestsIcon,
  DirectionsCar as CarsIcon,
} from "@material-ui/icons";

const BranchPanel = ({ children }) => {
  const { account } = useSelector((state) => state.auth);
  const menuItems = React.useMemo(() => {
    const items = [
      {
        title: "درخواست ها",
        route: "/panel/branch/requests",
        icon: <RequestsIcon />,
      },
      {
        title: "مشتریان",
        route: "/panel/branch/customers",
        icon: <CustomersIcon />,
      },
      {
        title: "خودروها",
        route: "/panel/branch/cars",
        icon: <CarsIcon />,
      },
    ];
    if (account.hasAppointment) {
      items.push({
        title: "ساعات کاری",
        route: "/panel/branch/appointments",
        icon: <DateIcon />,
      });
    }
    return items;
  }, [account]);
  return <AppPanel menuItems={menuItems}>{children}</AppPanel>;
};

export default BranchPanel;
