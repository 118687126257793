import React, { useState, useEffect, useMemo } from "react";
import {
  DialogContent,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import DialogTitle from "../dialog-title";
import carService from "../../services/car.service";
import LoadingComponent from "../loading";
import { Fragment } from "react";
import config from "../../config";

const useStyles = makeStyles((theme) =>
  createStyles({
    image: { width: "100%", maxWidth: 250 },
  })
);

export default function CarItemsInfo({
  data: { brand, carClass },
  close,
  extra,
  brandName,
  className,
}) {
  const [itemCategories, setItemCategories] = useState([]);
  const [loading, setLoading] = useState();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { error, data } = await carService.getCarItems(carClass);
      if (error) {
        // TODO: handle errors
        setLoading(false);
        return;
      }
      const categories = [];
      data.forEach((item) => {
        let category = categories.find((c) => c.name === item.category.name);
        if (!category) {
          category = { name: item.category.name, items: [] };
          categories.push(category);
        }
        category.items.push(item);
      });
      setItemCategories(categories);
      setLoading(false);
    })();
  }, [carClass]);

  const title = useMemo(() => {
    return `${brandName || extra.brands[brand]} ${
      className || extra.carClasses[carClass]
    }`;
  }, [extra, brand, carClass, brandName, className]);

  return (
    <>
      <DialogTitle divider onClose={close}>
        {title}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <LoadingComponent />
        ) : !itemCategories.length ? (
          <Typography className="text-center my-5">
            برای این خودرو موردی ثبت نشده
          </Typography>
        ) : (
          itemCategories.map((c) => (
            <Fragment key={c.name}>
              <Typography className="mt-2" component="h3" variant="h5">
                {c.name}
              </Typography>
              <Paper className="p-2 mt-2 mb-3" variant="outlined">
                {c.items.map((item, idx) => (
                  <Fragment key={item.id}>
                    {!!idx && <Divider className="my-2" />}
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={item.image ? 7 : 12}
                        md={item.image ? 8 : 12}
                      >
                        <Typography component="h4" variant="h6">
                          {item.brand} {item.model}
                        </Typography>
                        <Typography>{item.description}</Typography>
                      </Grid>
                      {!!item.image && (
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          md={4}
                          className="text-center"
                        >
                          {/* TODO: REMOVE THIS */}
                          <img
                            className={classes.image}
                            src={`${config.get("endpoint")}${item.image}`}
                            alt={`${item.brand} ${item.model}`}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Fragment>
                ))}
              </Paper>
            </Fragment>
          ))
        )}
      </DialogContent>
    </>
  );
}
