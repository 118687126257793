import React, { useState, Fragment, useEffect, useMemo } from "react";
import { useSnackbar } from "notistack";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import yupSchema from "../../../constants/schema";
import { FTextField } from "../../../components/form-elements";
import appointmentService from "../../../services/appointment.service";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import {
  toGregorian,
  toJalaali,
  getTimeFormat,
} from "../../../helpers/date-helpers";
import moment from "moment-jalaali";
import DialogTitle from "../../../components/dialog-title";

const formSchema = Yup.object().shape({
  title: yupSchema.title128,
  date: yupSchema.date,
  time: yupSchema.timeBOD,
});
export default function FormModalContent({ data, close }) {
  const { enqueueSnackbar } = useSnackbar();
  const initialValues = useMemo(() => {
    return {
      title: data.title,
      date: toJalaali(data.time),
      time: getTimeFormat(data.time),
    };
  }, [data]);
  const [formValue, setFormValue] = useState(initialValues);
  const [date, setDate] = useState();

  useEffect(() => {
    const { date, time } = formValue;
    if (!date || !time) {
      setDate(undefined);
      return;
    }
    const [h, m] = time.split(":");
    const { year, month, day } = toGregorian(date);

    setDate(new Date(year, month, day, h, m));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue.date, formValue.time]);

  const handleError = (error) => {
    let message;
    let variant;
    switch (error) {
      case "AppointmentNotFound":
        message = "ساعت کاری مورد نظر یافت نشد.";
        variant = "error";
        break;
      case "DuplicateTime":
        message = "زمان انتخاب شده تکراری است.";
        variant = "error";
        break;
      case "PassedDate":
        message = "زمان انتخاب شده گذشته است.";
        variant = "error";
        break;
      default:
        message = "اتفاقی افتاده، لطفا بعدا تلاش کنید.";
        variant = "error";
    }
    enqueueSnackbar(message, { variant });
  };
  const handleSubmit = async ({ title }) => {
    const op = await appointmentService.editAppointment(data.id, {
      title,
      time: date,
    });
    const { error } = op;
    if (error) {
      handleError(error);
      return;
    }
    close(true);
  };

  return (
    <>
      <DialogTitle onClose={() => close()}>ویرایش ساعت کاری</DialogTitle>
      <Divider />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting, values, setFieldValue, errors, submitCount }) => {
          setFormValue(values);
          return (
            <Form autoComplete="off">
              <DialogContent>
                <div className="d-flex justify-content-center mt-1 mb-2">
                  <Calendar
                    value={values.date}
                    locale="fa"
                    minimumDate={utils("fa").getToday()}
                    onChange={(v) => setFieldValue("date", v)}
                    shouldHighlightWeekends
                  />
                </div>
                {Boolean(errors.date && submitCount) && (
                  <FormHelperText error>{errors.date}</FormHelperText>
                )}

                <FTextField type="time" name="time" />
                <FTextField name="title" label="عنوان" autoFocus />
                {values.title && date && (
                  <Typography className="mt-2">
                    {moment(date).format(values.title)}
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={close} color="primary" disabled={isSubmitting}>
                  لغو
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                >
                  ذخیره
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
