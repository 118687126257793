import React from "react";
import { makeStyles, Typography, Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(12),
    "& p": {
      textIndent: "6ch",
      textAlign: "justify",
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
}));
export default function AboutPage() {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Typography className={classes.title} variant="h3" component="h2">
        تاریخچه و ماموریت
      </Typography>
      <Typography>
        ارمغان، موسسه ای است پیشرو در زمینه ارائه انواع خدمات خودرو شامل تعویض
        روغن سریع انواع خودرو های سبک، تون آپ و مکانیکی، کارواش، احیای(ریکاوری)
        رنگ و داخل، صافکاری و نقاشی، اجرای پوشش های سرامیکی بدنه و داخل، کاور
        رنگ بدنه و فروش انواع محصولات مراقبت از بدنه، داخل و موتور خودرو. این
        مجموعه در سال 1389 فعالیت خود را آغاز نموده و هم اکنون در حال سرویس دهی
        به هزاران مشتری وفادار که به کارکرد صحیح خودروی خود اهمیت می دهند، می
        باشد. ارمغان همواره تلاش نموده تا برنامه نگهداری و تعمیرات پیشگیرانه
        انواع خودرو را بر اساس ارزش های مد نظر این برند – یعنی راحتی، سرعت و
        کیفیت خدمات- تعریف نماید. ما در ارمغان درک می نماییم که یک سرویس تعویض
        روغن به چه میزان می تواند استرس زا و حساسیت برانگیز باشد. امروز ارمغان
        راه خود را برای کمک به دارندگان خودرو برای رهایی از نگرانی نگهداری از
        خودروی خود، با سرعت بیشتری می پیماید . ارمغان با پشتوانه علم و دانش و
        تجربه 10 ساله خود با انجام سرویس های خود باعث می شود تا خودروی شما مدت
        زمان زیادی را در سلامت کامل طی مسیر نماید، بنابراین شما با مراجعه به این
        مجموعه می توانید نگرانی را پشت سر بگذارید.
      </Typography>
      <Typography className={classes.title} variant="h4" component="h3">
        چرا نام ما ارمغان است؟
      </Typography>
      <Typography>
        زنده یاد غفار حسین پور در سال 1352 اقدام به خریداری مغازه ای در منطقه
        عباس آباد تهران (محل فعلی شعبه 1) و آن را ارمغان (برگرفته از لغب وی)
        نامگذاری نمود. حرفه وی در سال های متمادی فروش لوازم لوکس خانگی نظیر
        لوستر، چینی آلات و بلورجات بود تا اینکه در سال 1388 بر اساس اعتماد و
        احترام به جوانان و علاقمندی های آنان، علی الخصوص نوه ایشان اقای امیرعباس
        عسگری داریان، این محل را در اختیار ما جهت فعالیت های مربوط به خودرو قرار
        داد و امروز ما با افتخار سعی بر ادامه راه پدربزرگ مهربان و دوست داشتنی
        خود در جهت انجام کسب و کار حلال و رضایت مشتریان خود داریم.
      </Typography>
      <Typography>
        زنده یاد مرحوم حسین پور در اردیبهشت ماه 1393 در سن 83 سالگی به دیار باقی
        شتافت و ما را از لطف مصاحبت و نصحیت های بی مثال خود محروم نمود. روحش شاد
        و یادش گرامی باد.
      </Typography>
      <Typography>
        هر چند به واقع این صفحه نمی تواند تمامی علاقه و احساس ما را نسبت به این
        شخص بیان نماید اما در همینجا بر خود واجب می دانیم از او و حمایتی که از
        جوانان دو نسل بعد از خود نمود تشکر و قدردانی نموده و با توسعه این مجموعه
        در جهت افزایش هر چه بیشتر رضایتمندی مشتریان گام برداشته و به آرزوی بزرگ
        ایشان که شناساندن نام ارمغان به همه مردم بود، جامه عمل بپوشانیم.
      </Typography>
      <Typography className={classes.title} variant="h4" component="h3">
        سرویس های نگهداری پیشگیرانه از خودرو
      </Typography>
      <Typography>
        خدمات نگهداری پیشگیرانه و سرویس های دوره ای شامل تعویض روغن موتور،
        فیلترهای روغن، هوا، کابین و سوخت به گونه ای طراحی گردیده است تا وسیله
        نقلیه شما را از تعمیرات پر هزینه و خرابی در میان راه حفظ نماید.
      </Typography>
      <Typography>
        علاوه بر این ارمغان طیف وسیعی از خدمات نگهداری دوره ای و پیشگیرانه برای
        محافظت از خودرو و کارکرد صحیح آن در بخش های مختلف ارائه می نماید. این
        خدمات شامل سیستم سوخت (عیب یابی الکترونیکی یا دیاگ و شستشوی سیستم سوخت
        با مواد و دستگاه مخصوص 9210 BG ساخت کشور آمریکا) موتور خودرو (تعویض روغن
        و شستشوی داخلی موتور با مواد و دستگاه مخصوص 9800 BG)، سیستم خنک کننده،
        سیستم ورودی هوا (تعویض فیلتر هوا و شستشوی دریچه گاز با دستگاه و مواد
        مخصوص BG)، تهویه مطبوع (تعویض فیلتر کابین و شستشوی مجرای ورودی هوای آن)
        گیربکس دستی و اتوماتیک ( شستشوی داخلی گیربکس اتوماتیک تعویض روغن و فیلتر
        آن با مواد و دستگاه پاور فلاش شرکت BG)، سیستم هیدرولیک فرمان ( شستشوی
        داخلی سیستم فرمان و تعویض روغن آن با دستگاه 937 و مواد مخصوص BG) می
        باشند.
      </Typography>
      <Typography className={classes.title} variant="h4" component="h3">
        باشگاه مشتریان ارمغان
      </Typography>
      <Typography>
        ارمغان به شما پیشنهاد می کند تا ضمن استفاده از سرویس های مرسوم مربوط به
        خودروی خود، به باشگاه مشتریان ارمغان بیوندید تا علاوه بر استفاده از
        مزایای انجام سرویس به طور دقیق و استفاده از مواد با کیفیت، از تخفیف های
        ارائه شده حداکثر استفاده را برده و هزینه های نگهداری خودروی خود را کاهش
        دهید.
      </Typography>
      <Typography className={classes.title} variant="h4" component="h3">
        گواهینامه ها
      </Typography>
      <Typography>
        ارمغان در سال 2011 موفق به کسب گواهینامه بهترین فروشنده شرکت کاسترول از
        نماینده ی منطقه ای خاورمیانه گردیده است.
      </Typography>
      <Typography className={classes.title} variant="h4" component="h3">
        پرسنل مجرب و دوره دیده ارمغان
      </Typography>
      <Typography>
        پرسنل ارمغان، از بین بیش از صد ها نفر متقاضی انتخاب می گردند. استاندارد
        های قبولی افراد جهت کار در مجموعه از نوع سخت گیرانه بوده که در آن ضریب
        هوشی، هوش اجتماعی، سوابق تحصیلات مرتبط، سابقه کاری و مهارت متقاضیان مورد
        ارزیابی قرار می گیرد. پرسنل به طور مرتب در دوره های آموزشی برگزار شده در
        ارمغان شرکت می نمایند و در پایان پس از اخذ امتحان، مدرک پایان دوره را از
        ارمغان دریافت می نمایند و در نهایت در صورت پایان کلیه دوره ها موفق به
        اخذ مدرک ACE می گردند.
      </Typography>
      <Typography className={classes.title} variant="h4" component="h3">
        تیم رهبری ارمغان
      </Typography>
      <Typography>
        تیم رهبری ارمغان از آقای امیر عباس عسگری داریان، مدیر عامل و مدیر
        تبلیغات و عضو هیئت مدیره و آقای علیرضا مهرآئین مدیر فروش و توسعه و رئیس
        هیئت مدیره و تشکیل شده است.
      </Typography>
    </Container>
  );
}
