import React, { useState, useMemo, useEffect } from "react";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  InputBase,
  Collapse,
  FormControlLabel,
  Checkbox,
  withStyles,
  Hidden,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import RefreshIcon from "@material-ui/icons/Refresh";
import ColIcon from "@material-ui/icons/ViewColumn";
import FilterIcon from "@material-ui/icons/FilterList";
import { createStyles, fade, makeStyles } from "@material-ui/core/styles";
import FormBuilder from "../form-builder";
import * as Yup from "yup";
import SelectPopover from "../select-popover";
import ButtonPopover from "../button-popover";
import ImportExport from "./import-export";

const LightCheckbox = withStyles({
  root: {
    color: "#fff",
    "&$checked": {
      color: "#fff",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
      margin: theme.spacing(2),
      marginTop: -theme.spacing(2),
      boxShadow: `0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px ${fade(
        theme.palette.primary.main,
        0.4
      )}`,
    },
    _: {
      height: 1,
    },
    title: {
      flex: "1 1",
      minWidth: 0,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },

    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    toolbarFormContainer: {
      padding: theme.spacing(2, 2, 1),
      width: 250,
      "& > *": {
        marginBottom: theme.spacing(1),
      },
    },
  })
);
const TableToolbar = ({
  title,
  actions = [],
  handleOpenModal,
  searchTextChange,
  searchKeys,
  searchKeyChange,
  onRefresh,
  filterForm,
  headCellsChange,
  headCells,
  importOptions,
  exportOptions,
}) => {
  const classes = useStyles();
  const [showSearchKeys, setShowSearchKeys] = useState(false);
  const [searchText, setSearchText] = useState("");

  const hasSearchBox = searchKeys?.length && searchKeyChange;

  const toggleSearchKeys = () => {
    setShowSearchKeys(!showSearchKeys);
  };
  const handleSearchTextChange = ({ target: { value } }) => {
    setSearchText(value);
    searchTextChange(value);
  };

  const SearchBox = (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={"جستجو..."}
        value={searchText}
        onChange={handleSearchTextChange}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
  const ExpandButton = (
    <IconButton onClick={toggleSearchKeys} color="inherit">
      {showSearchKeys ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
  );

  return (
    <>
      <div className={classes._} />
      <div className={classes.root}>
        <Toolbar className="px-2">
          <Typography className={classes.title} variant="h6" component="div">
            {title}
          </Typography>
          {hasSearchBox ? (
            <Hidden xsDown implementation="js">
              {SearchBox}
            </Hidden>
          ) : null}
          {searchKeys?.length > 1 ? (
            ExpandButton
          ) : hasSearchBox ? (
            <Hidden smUp implementation="css">
              {ExpandButton}
            </Hidden>
          ) : null}
          <SelectPopover
            title="ستون ها"
            icon={ColIcon}
            items={headCells}
            itemsChange={headCellsChange}
            multiple
          />
          {Boolean(filterForm) && (
            <ButtonPopover icon={FilterIcon}>
              {({ data, setData, close }) => (
                <ToolbarForm
                  {...filterForm}
                  data={data}
                  setData={setData}
                  close={close}
                />
              )}
            </ButtonPopover>
          )}
          <ImportExport
            importOptions={importOptions}
            exportOptions={exportOptions}
            onRefresh={onRefresh}
          />
          {actions.map((action, idx) => (
            <Tooltip key={idx} title={action.tooltip || ""}>
              <IconButton
                onClick={() => handleOpenModal(action)}
                aria-label={action.tooltip}
                color="inherit"
              >
                {action.icon}
              </IconButton>
            </Tooltip>
          ))}
          {onRefresh && (
            <IconButton onClick={onRefresh} color="inherit">
              <RefreshIcon />
            </IconButton>
          )}
        </Toolbar>
        {hasSearchBox ? (
          <Collapse in={showSearchKeys} timeout="auto">
            <div className="p-2 pt-0 hide-empty">
              <Hidden smUp implementation="js">
                {SearchBox}
              </Hidden>
              {searchKeys && searchKeys?.length > 1
                ? searchKeys.map((key) => (
                    <FormControlLabel
                      key={key.field}
                      control={
                        <LightCheckbox
                          checked={key.active || false}
                          onChange={() => searchKeyChange(key.field)}
                          name="checkedB"
                        />
                      }
                      label={key.title}
                    />
                  ))
                : null}
            </div>
          </Collapse>
        ) : null}
      </div>
    </>
  );
};

function ToolbarForm({
  schema,
  form,
  handleSubmit: _handleSubmit,
  initialValues: _initialValues,
  data,
  setData,
  close,
}) {
  const classes = useStyles();
  const initialValues = useMemo(() => {
    if (data) return data;
    if (_initialValues) return _initialValues;
    const obj = {};
    form.forEach((el) => {
      const val = el._empty || "";
      delete el._empty;
      obj[el.name] = val;
    });
    return obj;
  }, [form, _initialValues, data]);
  useEffect(() => {
    console.log("RENDER");
  }, []);

  const handleSubmit = (values, ...args) => {
    setData(values);
    _handleSubmit(values, ...args);
    close();
  };

  return (
    <FormBuilder
      schema={Yup.object().shape(schema || {})}
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      form={form}
      actionComponent={() => (
        <Button fullWidth type="submit" variant="outlined" color="inherit">
          اعمال
        </Button>
      )}
      formContainer={(props) => (
        // <Grid spacing={2} container alignItems="center" {...props} />
        <div className={classes.toolbarFormContainer} {...props} />
      )}
    />
  );
}

export default TableToolbar;
