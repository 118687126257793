import React, { useState } from "react";
import { IconButton, Popover } from "@material-ui/core";

export default function ButtonPopover({
  icon: Icon,
  children,
  button: DefButton,
  closeOnClick,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState();

  const open = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {DefButton ? (
        <DefButton onClick={open} />
      ) : (
        <IconButton onClick={open}>
          <Icon />
        </IconButton>
      )}
      <Popover
        onClick={closeOnClick ? close : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {typeof children === "function"
          ? children({ data, setData, close })
          : children}
      </Popover>
    </>
  );
}
