import React from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  MenuBook as ItemsInfoIcon,
} from "@material-ui/icons";
import DataTable from "../../../components/data-table";
import FormModalContent from "./form";
import ConfirmModalContent from "../../../components/confirm-modal-content";
import CarItemsInfo from "../../../components/car-items-info";

const headerCells = [
  { field: "title", title: "عنوان", mainField: true },
  {
    field: "brand",
    title: "برند",
    renderer: (val) => val.name,
  },
  {
    field: "carClass",
    title: "مدل",
    renderer: (val) => val.name,
  },
  { field: "year", title: "سال" },
  { field: "code", title: "کد ارمغان" },
  { field: "chassisNumber", title: "شماره شاسی" },
  { field: "mileage", title: "کیلومتر" },
  { field: "color", title: "رنگ" },
];
const defaultKeys = [];

export default function TableView({ deleteItem, data, onRefresh }) {
  return (
    <DataTable
      headCells={headerCells}
      onRefresh={onRefresh}
      orderBy="verified"
      data={data}
      rowActions={[
        {
          icon: <ItemsInfoIcon />,
          modalContent: (props) => <CarItemsInfo {...props} />,
        },
        {
          modalProps: { maxWidth: "sm" },
          icon: <EditIcon />,
          modalContent: (props) => <FormModalContent {...props} />,
        },
        {
          modalProps: { maxWidth: "sm" },
          icon: <DeleteIcon />,
          modalContent: (props) => (
            <ConfirmModalContent
              onConfirm={() => deleteItem(props.data)}
              confirmText={props.data.title}
              close={props.close}
            />
          ),
        },
      ]}
      actions={
        data && data.length < 18
          ? [
              {
                modalProps: { maxWidth: "sm" },
                icon: <AddIcon />,
                tooltip: "تعریف خودرو",
                modalContent: (props) => <FormModalContent {...props} />,
              },
            ]
          : []
      }
      searchKeys={defaultKeys}
      title="خودروهای من"
    />
  );
}
