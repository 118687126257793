import React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import LOGO from "../../assets/images/armaghan-logo.png";
import { Link } from "react-router-dom";
import LocationIcon from "@material-ui/icons/PinDrop";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#b9bdc1",
    position: "relative",

    "&::before": {
      content: "''",
      position: "absolute",
      width: "50%",
      maxHeight: theme.spacing(9),
      height: "10vw",
      backgroundColor: "#b9bdc1",
      bottom: "100%",
      right: "66%",
      transform: "skewX(-40deg) translateX(-8px)",
    },
  },
  top: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  logoContainer: {
    width: 200,
    marginBottom: theme.spacing(4),
  },
  separator: {
    position: "relative",
    display: "flex",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    alignItems: "center",
    "&::before": {
      content: "''",
      width: theme.spacing(2),
      height: theme.spacing(1.25),
      marginLeft: 1,
      marginRight: 1,
      transform: "skewX(-10deg)",
      display: "inline-block",
      position: "relative",
      backgroundColor: "#fff",
    },
    "&::after": {
      content: "''",
      flex: 1,
      marginLeft: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      height: theme.spacing(0.5),
      transform: "skewX(-10deg)",
    },
  },
  list: {
    listStyle: "none",
    marginTop: 5,
    padding: 0,
    "& li": {
      borderBottom: "1px dashed rgba(102,102,102,.3)",
      display: "flex",
      alignItems: "center",
      "& a": {
        flex: 1,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        textDecoration: "none",
        transition: "all 300ms ease-in-out",
        "&::before": {
          content: "''",
          width: theme.spacing(1),
          height: theme.spacing(1),
          border: `2px solid ${theme.palette.text.secondary}`,
          display: "inline-block",
          borderBottom: "none",
          borderLeft: "none",
          transform: "rotate(45deg)",
          marginRight: theme.spacing(1.5),
        },
        "&:hover": {
          color: theme.palette.primary.main,
          paddingLeft: theme.spacing(2),
          "&::before": {
            borderColor: theme.palette.primary.main,
          },
        },
      },
    },
  },
  contact: {
    padding: 0,
    marginTop: 5,
    listStyle: "none",
    "& li": {
      display: "flex",
      paddingTop: theme.spacing(1),
      "& .MuiSvgIcon-root": {
        width: theme.spacing(6),
      },
    },
  },
  copyright: {
    borderTop: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));
export default function Footer({ blogs }) {
  const classes = useStyles();

  const services = React.useMemo(() => {
    if (!blogs) return;
    const res = blogs.find((c) => c.title === "خدمات");
    if (res) return res.subList;
  }, [blogs]);

  return (
    <footer className={classes.root}>
      <div className={classes.top}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <div className={classes.logoContainer}>
                <img src={LOGO} alt="" />
              </div>
              <Typography color="textSecondary" variant="body2">
                <Typography component="strong" variant="body2">
                  ارمغان
                </Typography>{" "}
                مرکز مراقبت خودرو شماست. شعب ارمغان در سراسر شهر تهران آماده
                ارائه خدمات تخصصی خودرویی با بالاترین کیفیت می باشند. شما می
                توانید از طزیق عضویت در پلتفرم نرم افزاری ارمغان و ثبت خودرو یا
                خودروهای خود، از خدمات یادآور زمان سرویس، استعلام قیمت پیش از
                مراجعه و رزرو آنلاین استفاده نمایید .
              </Typography>
            </Grid>
            {services && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Typography component="h4" variant="h5">
                  خدمات
                </Typography>
                <div className={classes.separator} />
                <ul className={classes.list}>
                  {services.map((service) => (
                    <li key={service.title}>
                      <Link to={service.route}>{service.title}</Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography component="h4" variant="h5">
                تماس با ما
              </Typography>
              <div className={classes.separator} />
              <ul className={classes.contact}>
                <li>
                  <LocationIcon />
                  <div>
                    <Typography component="div">شعبه مرکزی</Typography>
                    <Typography component="div" color="textSecondary">
                      تهران، خیابان سهروردی شمالی، خیابان سورنا، نبش صحاف زاده،
                      پلاک 1
                    </Typography>
                  </div>
                </li>
                <li>
                  <PhoneIcon />
                  <div>
                    <Typography component="div">تلفن</Typography>
                    <Typography component="div" color="textSecondary">
                      02188742036-7
                    </Typography>
                  </div>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.copyright}>
        <Container>
          <Typography color="textSecondary">
            © تمامی حقوق مادی و معنوی برای ارمغان محفوظ است.
          </Typography>
        </Container>
      </div>
    </footer>
  );
}
