import React from "react";

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Build as ItemsIcon,
} from "@material-ui/icons";
import DataTable from "../../../../components/data-table";
import FormModalContent from "./form";
import carService from "../../../../services/car.service";
import ConfirmModalContent from "../../../../components/confirm-modal-content";
import Items from "../../carItems/item";

const headerCells = [
  {
    field: "name",
    title: "نام",
    mainField: true,
  },
  {
    field: "brand",
    title: "برند",
    renderer: (brand) => brand.name,
  },
  {
    field: "type",
    title: "تیپ",
  },
];
const defaultKeys = [{ title: "نام", field: "name", active: true }];
const filterForm = {
  form: [
    {
      _type: "autocomplete",
      _empty: undefined,
      name: "brand",
      backend: carService.brandsAC,
      valueSelector: "value",
      labelSelector: "title",
      label: "برند",
    },
  ],
};

export default function CarModelsPage() {
  // const visibilityIcon = (item) => {
  //   return item.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />;
  // };
  // const toggleHide = (item) => {
  //   //  ...toggleHide
  //   // dispatch(toggleHideProduct(item.id));
  // };
  const deleteItem = (item) => {
    return carService.deleteCarClass(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        backend={carService.getCarClassesUrl}
        rowActions={[
          {
            icon: <ItemsIcon />,
            modalProps: { fullScreen: true },
            refreshOnClose: true,
            modalContent: (props) => <Items {...props.data} />,
          },
          {
            modalProps: { maxWidth: "sm" },
            icon: <EditIcon />,
            modalContent: (props) => <FormModalContent {...props} />,
          },
          {
            modalProps: { maxWidth: "sm" },
            icon: <DeleteIcon />,
            modalContent: (props) => (
              <ConfirmModalContent
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.name}
                close={props.close}
              />
            ),
          },
          // {
          //   icon: visibilityIcon,
          //   onClick: toggleHide,
          // },
        ]}
        actions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <AddIcon />,
            tooltip: "ایجاد خودرو",
            modalContent: (props) => <FormModalContent {...props} />,
          },
        ]}
        searchKeys={defaultKeys}
        filterForm={filterForm}
        title="خودرو ها"
      />
    </div>
  );
}
