import React from "react";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Visibility as ViewIcon,
} from "@material-ui/icons";
import DataTable from "../../../components/data-table";
import CreateFormModalContent from "./create-form";
import ReceptionFormModalContent from "./reception-form";
import ClearanceFormModalContent from "./clearance-form";
import RequestInfo from "../../_shared/request-info";
import requestService from "../../../services/request.service";
import ConfirmModalContent from "../../../components/confirm-modal-content";
import moment from "moment-jalaali";
import { Status, StatusMap } from "../../../constants/enums";

const headerCells = [
  { field: "code", title: "کد", mainField: true },
  {
    field: "appointment.time",
    title: "زمان مراجعه",
    renderer: (_, row) =>
      moment(row.appointment.time).format(row.appointment.title),
  },
  {
    field: "status",
    title: "وضعیت",
    renderer: (val) => StatusMap[val],
  },
  { field: "date", title: "تاریخ ایجاد", date: true },
  {
    field: "branch.receptionDate",
    title: "تاریخ پذیرش",
    renderer: (_, row) =>
      row.branch?.receptionDate
        ? moment(row.branch.receptionDate).format("jYYYY/jMM/jDD HH:mm")
        : "-",
  },
  {
    field: "branch.clearanceDate",
    title: "تاریخ ترخیص",
    renderer: (_, row) =>
      row.branch?.receptionDate
        ? moment(row.branch.clearanceDate).format("jYYYY/jMM/jDD HH:mm")
        : "-",
  },
  {
    field: "car",
    title: "خودرو",
    renderer: ({ brand, carClass }) => `${brand.name} ${carClass.name}`,
  },
  {
    field: "customer",
    title: "مشتری",
    renderer: ({ account: { firstName, lastName } }) =>
      `${firstName} ${lastName}`,
  },
  {
    field: "service",
    title: "خدمت",
    renderer: (val, row, extra) => extra.services[val]?.title,
  },
  {
    field: "subServices",
    title: "زیر خدمت",
    renderer: (val, row, extra) =>
      val.map((v) => extra.subServices[v]).join(", "),
  },
];
const defaultKeys = [
  { field: "code", title: "کد" },
  { field: "customer.account.phoneNumber", title: "شماره تلفن" },
];
const filterForm = {
  form: [
    {
      _type: "autocomplete",
      _empty: Status.WaitingForDate,
      name: "status",
      options: [
        {
          title: StatusMap[Status.WaitingForDate],
          value: Status.WaitingForDate,
        },
        { title: StatusMap[Status.Accepted], value: Status.Accepted },
        { title: StatusMap[Status.Done], value: Status.Done },
      ],
      label: "وضعیت",
      disableClearable: true,
      valueSelector: "value",
      labelSelector: "title",
    },
  ],
};

export default function BranchRequests() {
  const deleteItem = (item) => {
    return requestService.expireRequest(item.id);
  };

  return (
    <div className="p-3">
      <DataTable
        headCells={headerCells}
        backend={requestService.getRequestsUrl}
        order={{
          order: "asc",
          orderBy: "appointment.time",
        }}
        rowActions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <EditIcon />,
            hidden: (data) =>
              data.status !== Status.Accepted &&
              data.status !== Status.WaitingForDate,
            modalContent: (props) =>
              props.data.status === Status.Accepted ? (
                <ClearanceFormModalContent {...props} />
              ) : (
                <ReceptionFormModalContent {...props} />
              ),
          },
          {
            modalProps: { maxWidth: "sm" },
            icon: <ViewIcon />,
            modalContent: (props) => <RequestInfo {...props} />,
          },
          {
            modalProps: { maxWidth: "sm" },
            icon: <DeleteIcon />,
            hidden: (row) =>
              row.status !== Status.WaitingForDate ||
              new Date(row.appointment.time) > Date.now(),
            modalContent: (props) => (
              <ConfirmModalContent
                title="آیا میخواهید این درخواست را منقضی کنید؟"
                onConfirm={() => deleteItem(props.data)}
                confirmText={props.data.code}
                close={props.close}
              />
            ),
          },
        ]}
        actions={[
          {
            modalProps: { maxWidth: "sm" },
            icon: <AddIcon />,
            tooltip: "پذیرش",
            modalContent: (props) => <CreateFormModalContent {...props} />,
          },
        ]}
        searchKeys={defaultKeys}
        filterForm={filterForm}
        title="درخواست ها"
      />
    </div>
  );
}
